import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import { makeStyles, Dialog, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useApp } from "../../context/app";


const useStyles = makeStyles(Styles);


const PopupSearch = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('')
    const navigate = useNavigate();
    const { openSearch, setOpenSearch } = useApp()



    return (
        <Dialog
            className={classes.dialog}
            disableBackdropClick={false}
            onClose={() => setOpenSearch(false)}
            open={openSearch}
            aria-labelledby="popup-info-title"
            aria-describedby="popup-info-description"
            fullScreen={true}
            fullWidth={true}
        >
            <div className={classes.root}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder={t('search')}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                navigate({
                                    pathname: '/produtos',
                                    state: { searchQuery: searchQuery }
                                })
                                setSearchQuery('')
                                setOpenSearch(false)
                                ev.preventDefault();
                            }
                        }}
                        value={searchQuery}
                        onChange={(ev) => setSearchQuery(ev.target.value)}
                    />
                </div>
                <div className={classes.closeBox} onClick={() => {
                    setOpenSearch(false)
                }}>
                    <CloseIcon />
                </div>
          </div>
        </Dialog>
    )
}



export default PopupSearch;
