import React from 'react'
import Styles from './styles';
import { makeStyles, Container, Grid } from '@material-ui/core';
import { Helmet } from "react-helmet";
import AccountData from './Components/AccountData';
import PasswordReset from './Components/PasswordReset';

const useStyles = makeStyles(Styles);


const AccountSettings = () => {

  const classes = useStyles();

    return (
      
        <>
          <Helmet>
                <title>All4Health</title>
            </Helmet>
             <Container maxWidth="xl" className={classes.root}>
                <Grid container spacing={4} >
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <AccountData />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <PasswordReset />
                    </Grid>
                </Grid>
            </Container>
        </>
  )
}

export default AccountSettings;
