const Style = theme => ({
    root: {
        display: 'flex',
        marginBottom: '36px',
        maxWidth: theme.breakpoints.values.lg,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '330px',
        [theme.breakpoints.down('md')]: {
            height: '300px',
        },
    },
    bannerContainer: {
        position: 'relative',
        height: '100%',
        width: '100%',
        '& .slick-slider': {
            height: '100%',
            width: '100% !important',
            '& .slick-list': {
                height: '100%',
                width: '100% !important',
                '& .slick-track': {
                    height: '100%',
                    width: '100% !important',
                }
            }
        }
    },
    banner: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0
    },
    bannerItemContainer: {
        height: '100%',
        zIndex: 4,
    },
    bannerInner: {
        zIndex: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        height: '100%',
        paddingLeft: '30px',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.7) 85%, rgba(0, 0, 0, 0.8) 100%)',
    },
    arrowBox: {
        borderRadius: '32px',
        zIndex: 5,
        position: 'absolute',
        top: '40%',
        cursor: 'pointer',
        color: '#fff',
        display: 'flex !important',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        height: '32px',
        width: '32px',
        fontSize: '18px',
        '& svg': {
            color: theme.palette.secondary.main,
            height: '14px',
            width: '14px',
            marginLeft: '2px'
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)'
        }
    },
    backgroundBox: {
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        '& img': {
            width: '100%',
            objectFit: 'cover',
            webkitTransition: 'all  1s ease-in-out',
            mozTransition: 'all  1s ease-in-out',
            oTransition: 'all  1s ease-in-out',
            transition: 'all  1s ease-in-out'
        }
    }
})

export default Style;