const Styles = theme => ({
    root: {
        zIndex: 2010,
        marginTop: '0px',
        borderRadius: '60px',
    },
    pooper: {
        '& .MuiPaper-root': {
            maxHeight: '650px',
            marginTop: '-40px',
            backgroundColor: 'transparent',
            borderRadius: '0px',
            display: 'flex',
            boxShadow: '0px 15px 10px -15px  rgba(0,0,0,0.8)',
        }
    },
    menuListParent: {
        paddingTop: '0px',
        width: '250px',
        marginTop: '50px',
        backgroundColor: '#FFF',
        borderRadius: '4px',
        '& li': {
            cursor: 'pointer',
            textTransform: 'uppercase',
            '& .MuiListItemIcon-root': {
                minWidth: '10px'
            },
            '&:hover': {
                backgroundColor: '#F7F9FC',
                color: theme.palette.secondary.main,
                borderLeft: `5px solid ${theme.palette.secondary.main}`,
                '& .MuiSvgIcon-root': {
                    display: 'none',
                    color: theme.palette.secondary.main
                }
            },
        }
    },
    containerChildrenParent: {
        marginTop: '50px',
        minWidth: '600px',
        maxWidth: '600px',
        backgroundColor: '#F7F9FC',
    },

    allCategoryItem: {
        backgroundColor: theme.palette.secondary.main,
        padding: '13px 10px',
        fontWeight: 800,
        color: 'white',
        '& li': {
            cursor: 'pointer',
            textTransform: 'uppercase',
            color: `${theme.palette.white} !important`,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: `${theme.palette.white} !important`,
                borderLeft: 'none !important',
            },
            '& span': {
                color: `${theme.palette.white} !important`,
            }
        }
    }
})

export default Styles;