import React, { useEffect, useState} from 'react'
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as ApiService from './../../service/api';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles, Grid, TextField, FormControl, Card, CardContent, CardActions } from '@material-ui/core'
import SubmitButton from '../../components/SubmitButton';
import { useApp } from "../../context/app";
import Styles from './styles';

const useStyles = makeStyles(Styles);


const ResetPassword = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { showNotifications, setAppLoading } = useApp()
  const [token, setToken] = useState(null)



  useEffect(() => {
    let _token = null;
    let params = queryString.parse(location.search)
    _token = params?.token
    const checkAndValidateToken = async () => {
      setAppLoading(true)
      const isValid = await sessionIsExpired(_token)
      if (!isValid) {
        navigate('/')
        const notf = { type: 'error', title: t('reset password'), message: t('reset password token invalid') }
        showNotifications(notf)
      }
      setToken(_token)
      setAppLoading(false)
    }
    if (_token) {
      checkAndValidateToken()
    }
  }, [])



  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .required(t('required field'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/,
          t('password regex')   
      ),
      confirmNewPassword: Yup.string()
        .required(t('required field'))
        .oneOf([Yup.ref('newPassword'), null], t('passwords does not match'))
    }),
    onSubmit: async (values) => {
      const postData = {
        password: values.newPassword,
        token: token
      }
      try {
        await ApiService.confirmResetPassword(postData);
        formik.resetForm()
        navigate('/')
        const notf = { type: 'success', title: t('reset password'), message: t('success on confirm reset password') }
        showNotifications(notf)
      } catch (error) {
        const notf = { type: 'error', title: t('reset password'), message: t('error on confirm reset password') }
        showNotifications(notf)
        navigate('/')
      }
    }
  })



  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit} >
        <Card className={classes.card}>
          <CardContent>
            <h3>{t('reset password')}</h3>
            <Grid container direction="column" spacing={3}>
              <Grid item xs>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    id="newPassword"
                    type="password"
                    placeholder={`${t('new password')} *`}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    id="confirmNewPassword"
                    type="password"
                    placeholder={`${t('confirm password')} *`}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.cardAction}>
            <Grid item >
              <Grid item xs>
                <SubmitButton
                  className={classes.buttonSubmit}
                  type="submit"
                  variant="contained"
                  color="primary"
                  isSubmiting={formik.isSubmitting}>
                  {t('reset password')}
                </SubmitButton>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </form>
    </div>
  )
}

const sessionIsExpired = async (token) => {
  try {
    if (!token) {
      return null
    }
    const tokenData = await jwt_decode(token);
    const { exp } = tokenData;
    return (exp * 1000) < Date.now() ? null : tokenData;
  } catch (error) {
    return null
  }

}



export default ResetPassword