const Styles = theme => ({
    submitBox: {
        padding: '20px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            width: 'auto',
            margin: '0 10px'
        }

    },
    paymentFormContent: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }
})

export default Styles;