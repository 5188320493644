import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Styles from './style';
import { makeStyles, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox, Slider  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useApp } from "../../../context/app";



const useStyles = makeStyles(Styles);

const FilterCategories = ({
    variant,
    onSelectCategories,
    onSelectPrice
}) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { variants } = useApp()
    const [variantsSelected, setVariantsSelected] = useState([]);
    const [priceRange, setPriceRange] = useState([1, 1500]);

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        if (variant) {
            setVariantsSelected([variant])
        }
        setLoading(false)
    }, [variant])






    const checkVariantHanlder = (checked, id) => {
        if (checked) {
            const items = [...variantsSelected, id]
            setVariantsSelected(items)
            onSelectCategories(items)
        } else {
            const items = variantsSelected.filter(it => it !== id)
            setVariantsSelected(items)
            onSelectCategories(items)
        }
    };

    const handleChangePriceRange = (event, newValue) => {
        setPriceRange(newValue);
    };

    const handleChangeCommitted = () => {
        onSelectPrice(priceRange)
    }
 

 


    if (loading) return null

    return (
        <div className={classes.root}>
            <div className={classes.variantList} >
                        <div className={classes.filterTitleBox}>
                            <h3>{t('filters')}</h3>
                </div>
                <div className={classes.priceBox}>
                    <div className={classes.priceLabelBox}>
                        <span>{priceRange[0]}&euro;</span>
                        <span>{priceRange[1]}&euro;</span>
                    </div>
                    <Slider className={classes.sliderPrice}
                        value={priceRange}
                        onChange={handleChangePriceRange}
                        onChangeCommitted={handleChangeCommitted}
                        valueLabelDisplay="auto"
                        max={500}
                        min={1}
                        aria-labelledby="range-slider"
                    />
                </div>
                        
                        <Accordion className={classes.accordion} key={`accordion-category}`} defaultExpanded>
                            <AccordionSummary className={classes.accordionSummary}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls=""
                                id=""
                            >
                                <div className={classes.accordionSummaryBoxTitle}>
                                    <h4>{t('categories')}</h4>
                                </div>
                            </AccordionSummary>

                            <AccordionDetails className={classes.accordionDetailBox}>
                                {variants.map((variant, index) => (
                                    <FormControlLabel
                                        key={variant._id}
                                        control={<Checkbox
                                            checked={variantsSelected?.includes(variant._id)}
                                            name="checkedCategory"
                                            onChange={event => checkVariantHanlder(event.target.checked, variant._id)}
                                        />}
                                        label={variant[`title_${i18n.language}`]}
                                    />
                                    
                                 ))}
                            </AccordionDetails>

                        </Accordion>
                

                {variants.map((variantParent, index) => {
                    return (
                        <Accordion className={clsx(classes.accordion, { [classes.hidden]: !variantParent?.children && !variantParent?.children?.length })} key={`accordion-sub-category-${variantParent?._id}}`}>
                                <AccordionSummary className={classes.accordionSummary}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls=""
                                    id=""
                                >
                                    <div className={classes.accordionSummaryBoxTitle}>
                                        <h5>{variantParent[`title_${i18n.language}`]}</h5>
                                    </div>
                            </AccordionSummary>

                            <AccordionDetails className={classes.accordionDetailBox}>
                                {variantParent?.children?.map((variantChildren) => (
                                    <FormControlLabel
                                        key={variantChildren._id}
                                        control={<Checkbox
                                            checked={variantsSelected?.includes(variantChildren._id)}
                                            name="checkedSubcategory"
                                            onChange={event => checkVariantHanlder(event.target.checked, variantChildren._id)}
                                        />}
                                        label={variantChildren[`title_${i18n.language}`]}
                                    />
                                ))}
                            </AccordionDetails>

                        </Accordion>
                        )
                    })
                }
    
            </div>
        </div>

    )
}


export default FilterCategories



/*

  <Accordion className={classes.accordion} key={`accordion-category}`} defaultExpanded>
                        <AccordionSummary className={classes.accordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls=""
                            id=""
                        >
                            <div className={classes.accordionSummaryBoxTitle}>
                                <h4>{variant[`title_${i18n.language}`]}</h4>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails className={classes.accordionDetailBox}>
                                         variant?.children?.map((variantChildren, index2) => (
                            <FormControlLabel control={<Checkbox name="checkedC" />} label={variantChildren[`title_${i18n.language}`]} />
                            ))
                        </AccordionDetails>
                    </Accordion>

                    */