import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Styles from './style';
import { makeStyles, Container, Card, Grid, CardContent, Breadcrumbs, Link, Button, InputLabel, ButtonGroup, TextField} from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LayersIcon from '@material-ui/icons/Layers';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as ApiService from '../../service/api';
import * as UtilService from '../../service/utils';
import ProductInfoAcordion from './ProductInfoAcordion';
import ProductCard from '../../components/ProductCard';
import { useApp } from '../../context/app';


const API_URL = process.env.REACT_APP_DOMAIN;


const useStyles = makeStyles(Styles);

const ProductDetail = () => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [amount, setAmount] = useState(1);
    const { addToCart, setAppLoading } = useApp();




    const onClickVariantHandler = variant => {
        navigate({
            pathname: '/product',
            state: { variant: variant }
        })
    }

    const addToChartHandler = () => {
        addToCart(product, amount)
    }




    useEffect(() => {
        const fetchProduct = async () => {
            try {
                setAppLoading(true)
                const resp = await ApiService.getProduct(id);
                let _product = resp.data.content;
                let _relatedProducts = _product?.relatedProducts
                _product = UtilService.processProducts([_product])[0]
                _relatedProducts = UtilService.processProducts(_relatedProducts)
                setProduct(_product);
                setRelatedProducts(_relatedProducts?.slice(0, 8))
                setAppLoading(false)
            } catch (error) {
                setAppLoading(false)
            }
        }

        fetchProduct()
    }, [id])


   
    if (!product)  return null
    
   

    return (
        <>
            <Helmet>
                <title>{product[`title_${i18n.language}`]}</title>
            </Helmet>

            <Container className={classes.root} maxWidth="xl" >
                <Card className={classes.card}>
                    <div className={classes.cardHeader}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/" >
                                All4Health
                            </Link>
                            {product?.variants?.map(variant =>
                                <Link color="inherit" className={classes.link} onClick={() => onClickVariantHandler(variant)}>
                                    {variant[`title_${i18n.language}`]}
                                </Link>
                            )}
                            <Link color="inherit">
                                {product[`title_${i18n.language}`]}
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <CardContent>
                        <Grid container spacing={1}  >
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <div className={classes.imageBox}>
                                    {<img src={`${API_URL}/warehouse/${product.thumbnail?.filename}`} alt="thumb" />}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <div className={classes.infoWrapper}>
                                    <div className={classes.titleBox}>
                                        <h2>{product[`title_${i18n.language}`]}</h2>
                                    </div>
                                    <div className={classes.descriptionBox}>
                                        <div dangerouslySetInnerHTML={{ __html: product[`smallDescription_${i18n.language}`] }} style={{ whiteSpace: 'pre-wrap', fontSize: '17px' }} />
                                    </div>
                                    <div className={classes.priceBox}>
                                        {product?.hasDiscount ? (
                                            <div className={classes.priceDiscountContainer}>
                                                <h3>{product?.oldPrice}&euro;</h3>
                                                <h2>{product?.finalPrice}&euro;</h2>
                                            </div>
                                        ) : <h2>{product?.finalPrice}&euro;</h2>
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                        <div className={classes.buttonsContainer}>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                className={classes.buttonStock}
                                                startIcon={<LayersIcon />}
                                            >
                                                {product.hasStock ? t('available in stock') : t('no available in stock')}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                className={classes.buttonCompare}
                                                startIcon={<CompareArrowsIcon />}
                                            >
                                                {t('compare')}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                className={classes.buttonFavorite}
                                                startIcon={<FavoriteIcon />}
                                            >
                                                {t('favorites')}
                                            </Button>
                                        </div>

                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className={classes.gridBoxAcordion}>
                                <ProductInfoAcordion product={product} />
                            </Grid>
                            <Grid item xs={12}>
                                <form className={classes.form}>
                                    <div className={classes.inputBox}>
                                        <InputLabel shrink>{t('amount')}</InputLabel>
                                        <ButtonGroup>
                                            <TextField
                                                className={classes.amount}
                                                disabled={true}
                                                id="standard-number"
                                                type="text"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                                value={amount}
                                            />
                                            <Button
                                                className={clsx(classes.buttonQuantity, classes.buttonBorderLeft)}
                                                disableElevation
                                                disabled={amount === 1}
                                                variant="contained"
                                                onClick={() => {
                                                    setAmount(previous => previous - 1);
                                                }}
                                            >
                                                <RemoveIcon />
                                            </Button>
                                            <Button
                                                className={classes.buttonQuantity}
                                                disableElevation
                                                variant="contained"
                                                onClick={() => {
                                                    setAmount(previous => previous + 1);
                                                }}
                                            >
                                                <AddIcon />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                    <Button
                                        className={classes.submitButton}
                                        fullWidth
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => addToChartHandler()}
                                        endIcon={<ShoppingCartIcon />}
                                    >
                                        {t('add to chart')}
                                    </Button>
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.relatedProductsWrapper}>
                                    <h3 className={classes.relatedProductsTitle} >{t('products you may also like')}</h3>
                                    <div className={classes.containerRelatedProducts}>
                                        {relatedProducts.map(product => (
                                            <ProductCard key={product._id} product={product} />
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                        
                </Card>
                <div className={classes.boxInfoFooter}>
                    <Grid container className={classes.containerBoxInfoFooter} spacing={5}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className={classes.boxInfoContainerItem1}>
                                <LocalShippingIcon />
                                <h1>{t('quaranteed delivery title')}</h1>
                                <h4>{t('quaranteed delivery info')}</h4>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className={classes.boxInfoContainerItem2}>
                                <VerifiedUserIcon />
                                <h1>{t('exchanges and returns title')}</h1>
                                <h4>{t('exchanges and returns info')}</h4>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className={classes.boxInfoContainerItem3}>
                                <FavoriteIcon />
                                <h1>{t('trust title')}</h1>
                                <h4>{t('trust info')}</h4>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                </Container>
            </>
    )
}

export default ProductDetail

