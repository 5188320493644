import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Styles from './styles';
import { makeStyles,  FormControl,  TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SubmitButton from './../../../../components/SubmitButton';
import * as ApiService from './../../../../service/api';

const useStyles = makeStyles(Styles);


const Newsletter = () => {

    const classes = useStyles();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email(t('invalid email')),
            firstName: Yup.string(),
            lastName: Yup.string()
        }),
        onSubmit: async (values) => {
            try {
                const postData = { email: values.email }
                await ApiService.addNewsLetter(postData)
                formik.resetForm()
            } catch (error) {
                formik.setErrors({ newsLetter: t('newsletter submit error') });
            }
        }
    })

  

  return (
      <div className={classes.root}>
          <h3>{t('subscribe title 1')}</h3>
          <h5>{t('subscribe title 2')}</h5>
          <form onSubmit={formik.handleSubmit} className={classes.form}>
              {!!formik.errors.newsLetter && (
                  <Alert severity="error">{formik.errors.newsLetter}</Alert>
              )}
              <FormControl fullWidth>
                  <TextField
                      id="email"
                      placeholder={t('email')}
                      variant="outlined"
                      margin= "dense"
                      fullWidth
                      autoComplete="off"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.email && formik.touched.email}
                      helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                      required
                  />
              </FormControl>
              <FormControl fullWidth>
                  <TextField
                      id="firstName"
                      placeholder={t('first name')}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      autoComplete="off"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.firstName && formik.touched.firstName}
                      helperText={(formik.errors.firstName && formik.touched.firstName) && formik.errors.firstName}
                      required
                  />
              </FormControl>
              <FormControl fullWidth >
                  <TextField
                      id="lastName"
                      placeholder={t('last name')}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      autoComplete="off"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.lastName && formik.touched.lastName}
                      helperText={(formik.errors.lastName && formik.touched.lastName) && formik.errors.lastName}
                      required
                  />
              </FormControl>

              <SubmitButton
                  className={classes.buttonSubmit}
                  type="submit"
                  variant="contained"
                  color="primary"
                  isSubmiting={formik.isSubmitting}
                >
                  {t('subscribe')}
              </SubmitButton>

          </form>
      </div>
  )
}



export default Newsletter;
