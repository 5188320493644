import React, { useState } from "react";
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme, makeStyles, AppBar, Toolbar, InputBase, IconButton, Badge } from "@material-ui/core";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SearchIcon from '@material-ui/icons/Search';
import CompareIcon from '@material-ui/icons/Compare';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuCategoryIcon  from '@material-ui/icons/Menu';
import logo from '../../assets/images/all4health_logo.png';
import logoIcon from '../../assets/icons/all4Health-logo-icon.png';
import { useApp } from "../../context/app";
import { useAuth } from "../../context/auth";
import MenuUser from '../MenuUser';
import MenuCategories from '../MenuCategories';
import Styles from "./styles";

const useStyles = makeStyles(Styles);


const Header = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElCategorues, setAnchorElCategories] = useState(null);
  const { cart, totalInCart, setOpenLogin, setOpenSignup, setOpenSearch, setOpenMenuDrawer, setOpenCategoriesDrawer, setOpenCartDrawer} = useApp()
  const { signed } = useAuth()
  const [searchQuery, setSearchQuery] = useState('')






  const onClickLogoHandler = () => {
    navigate('/');
  }

  const handleOpenMenuUser = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenMenuCategories = (event) => {
    setAnchorElCategories(event.currentTarget)
  };


  const onChangeLanguageHandle = (lang) => {
    i18n.changeLanguage(lang);
  }




  const renderSearch = () => {
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder={`${t('search')}...`}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
                navigate('/produtos', {replace: true, state: {query: searchQuery} })
                event.preventDefault();
            }
          }}
          onChange={event => {
            setSearchQuery(event.target.value)
          }}
        />
      </div>
    )
  }


  const renderCartButton = () => (
    <IconButton
      onClick={() => setOpenCartDrawer(true)}
      className={classes.cartButton}
      color="inherit">
      {totalInCart > 0 ? (
        <Badge
          badgeContent={totalInCart}
          color="secondary">
          <ShoppingCartIcon />
        </Badge>
      ) : (
          <ShoppingCartIcon />
      )}
    </IconButton>
  )

  const renderCompareButton = () => (
    <IconButton
      className={classes.compareButton}
      color="inherit">
      {false && (
        <Badge
          badgeContent={1}
          color="secondary">
          <CompareIcon />
        </Badge>
      )}
      <CompareIcon />
    </IconButton>
  )

  const renderUserButton = () => {
    if(!signed) return null 
    return (
      <IconButton
        id="user-menu"
        onClick={handleOpenMenuUser}
        className={classes.userButton}
        color="inherit">
        <AccountCircle />
      </IconButton>
    )
  }

  const renderMenuUser = () => {
    return (
      <MenuUser
        anchorEl={anchorElUser}
        close={() => setAnchorElUser(null)} />
    )
  }

  const renderMenuCategories = () => {
    return (
      <MenuCategories
        anchorEl={anchorElCategorues}
        close={() => setAnchorElCategories(null)}/>
    )
  }
  
  const onSearchMobileHandler = () => {
    setOpenSearch(true)
  }
    
   
  




  return (
    <AppBar  className={classes.appBar}>
      <Toolbar className={classes.toolbar}>

        {!isMobile && (
          <IconButton
            id="menu-hamburger-categories"
            onMouseEnter={handleOpenMenuCategories}
            className={classes.menuHamburger}
            color="inherit">
            <MenuCategoryIcon />
          </IconButton>
        )}

        {isMobile && (
          <IconButton
            id="menu-hamburger-drawer"
            onClick={() => setOpenMenuDrawer(true)}
            className={classes.menuHamburger}
            color="inherit">
            <MenuCategoryIcon />
          </IconButton>
        )}

       
          <div className={classes.logoContainer} onClick={onClickLogoHandler}>
            <img src={isMobile ? logoIcon : logo} alt="" />
          </div>
        

        {!isMobile && renderSearch()}

        {!isMobile && (
          <ul className={classes.menu}>
            <li onClick={() => navigate('/ajuda')}>{t('help')}</li>
            {!signed && <li onClick={() => setOpenLogin(true)}> {t('login')} </li>}
            {!signed && <div className={classes.bar} />}
            {!signed && <li onClick={() => setOpenSignup(true)}>{t('create account')}</li>}
          </ul>
        )}

        {isMobile && (
          <div className={classes.categoriesBoxM} onClick={() => setOpenCategoriesDrawer(true)}>
            <h3>{t('products')}</h3>
          </div>
        )}

       
        {!isMobile &&  renderUserButton()}

        {!isMobile &&  renderCartButton()}
        
        {!isMobile &&  renderCompareButton()}

        {!isMobile &&  renderMenuUser()}

        {renderMenuCategories()}

        {false && (
          <div className={classes.languages}>
            <span className={clsx({ [classes.active]: i18n.language === 'pt' })} onClick={() => onChangeLanguageHandle('pt')}>pt</span>
            <div>|</div>
            <span className={clsx({ [classes.active]: i18n.language === 'en' })} onClick={() => onChangeLanguageHandle('en')}>en</span>
          </div>
        )}
       

        {isMobile && (
          <IconButton
            onClick={() => onSearchMobileHandler()}
            className={classes.searchMobile}
            color="inherit">
            <SearchIcon />
          </IconButton>
        )}

        {isMobile && renderCartButton()}


      </Toolbar>
    </AppBar>
  );
}


export default Header

