const Styles = theme => ({
    drawer: {
        zIndex: 2001,
        '& .MuiDrawer-paper': {
            width: '463px',
            borderRadius: '0px !important',
            padding: 0,
            height: '100%',
            overflow: 'auto',
            boxShadow: 'none',
            backgroundColor: theme.palette.white,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },  
    },
    cartWrapper: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    closeBox: {
        position: 'absolute',
        top: 15,
        right: 10,
        '& span': {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    header: {
        padding: '20px 30px 15px',
        '& h2': {
            marginBottom: 15,
        },
        '& h4': {
            margin: 0,
            textTransform: 'uppercase',
            '& span': {
                fontSize: '16px'
            }
        },
        '& h5': {
            color: theme.palette.secondary.main,
            fontSize: '18px',
            margin: 0
        }
    },
    content: {
        borderTop: '1px solid rgba(0,0,0,0.1)',
        margin: '0px 30px 20px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'auto'
    },
    footer: {
        padding: '0 30px 30px'
    }
})

export default Styles;