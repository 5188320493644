const Styles = theme => ({
    root: {
        '& .MuiPaper-root': {
            boxShadow: 'none',
            padding: '10px 30px'
       }
    },
    imageBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '120px',
        height: '120px',
        minWidth: '120px',
        minHeight: '120px',
        overflow: 'hidden',
        '& img': {
            height: '100%',
            objectFit: 'contain',
        },
    },
    finalPrice: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
     inputBox: {
        marginRight: '5px',
        '& .MuiInputLabel-root': {
            marginBottom: '10px'
        }
    },
    amount: {
        maxWidth: '80px',
        margin: '0',
        height: '50px',
        '& .MuiInputBase-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        },
        '& input': {
            fontWeight: 700,
            fontSize: '14px'
        }
    },
    button: {
        width: '245px',
    },
    buttonQuantity: {
        height: '50px',
        background: 'linear-gradient(60deg, #66bb6a, #43a047) !important',
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4) !important',
        color: 'white',
        width: '30px'
    },
    buttonBorderLeft: {
        height: '50px',
        borderLeft: '1px solid #eeeeee'
    },
})

export default Styles;