import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Styles from './styles';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt';
import clsx from 'clsx';
import {makeStyles, Breadcrumbs, Container, Grid, Paper, Link } from '@material-ui/core';
import SlideShow from './components/SlideShow';
import Newsletter from './components/Newsletter';
import * as ApiService from './../../service/api';
import * as UtilService from './../../service/utils';
import HighlightsProductsCard from '../../components/HighlightsProductsCard';
import Loading from '../../components/Loading';
import { useApp } from '../../context/app';


const API_URL = process.env.REACT_APP_DOMAIN;

const filterLastBlogPost = {
  sort: 'createdAt',
  order: 'desc',
  limit: 5
}

const filterMostBlogPost = {
  sort: 'createdAt',
  order: 'desc',
  limit: 5
}

const useStyles = makeStyles(Styles);


const BologDetail = () => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState(null);
  const [lastPosts, setLastPosts] = useState([]);
  const [mostReadPosts, setMostReadPosts] = useState([]);
  const [products, setProducts] = useState([]) 
  const { setAppLoading } = useApp();



  useEffect(() => {


    const slug = decodeURI(id);

    const getPost = async (id) => {
       return  ApiService.getBlogPost(id);
    }

    const getLastPosts = async () => {
        return ApiService.getBlogPosts(filterLastBlogPost);
    }

    const getMostPosts = async () => {
        return ApiService.getBlogPosts(filterMostBlogPost);
    }

    const getProducts = async () => {
      const filter = { page: 1, perPage: 3 };
      return ApiService.getAllProducts(filter);
    }
      
 
    const getData = async () => {
      try {
        setLoading(true)
        const [res1, res2, res3] = await Promise.all([getPost(slug), getLastPosts(), getMostPosts()]);
        setPost(res1.data.content);
        let _products = res1.data.content?.products;
        _products = UtilService.processProducts(_products)
        setProducts(_products)

        setLastPosts(res2.data.content.results.slice(0,3));
        setMostReadPosts(res3.data.content.results.slice(0, 3));
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
  getData()
   
  }, [id])

  const clickPostHandler = (_post) => {


    const getPost = async (id) => {
      return await ApiService.getBlogPost(id);
    }

    const getLastPosts = async () => {
      return ApiService.getBlogPosts(filterLastBlogPost);
    }

    const getMostPosts = async () => {
      return ApiService.getBlogPosts(filterMostBlogPost);
    }

    const getProducts = async () => {
      const filter = { page: 1, perPage: 3 };
      return ApiService.getAllProducts(filter);
    }

    const getData = async () => {
      try {
        setAppLoading(true)
        const [res1, res2, res3, res4] = await Promise.all([getPost(_post?._id), getLastPosts(), getMostPosts(), getProducts()]);
        setPost(res1.data.content);
        //setProducts(res4.data.content.results)
        //setLastPosts(res2.data.content.results.slice(0, 3));
        //setMostReadPosts(res3.data.content.results.slice(0, 3));
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setAppLoading(false)
      } catch (error) {
        setAppLoading(false)
      }
    }
    //getData()
    navigate(`/blog/post/${_post?.slug}`, { replace: true});

  }


  const clickVarianthandler = variant => {
    navigate({
      pathname: '/blog',
      state: { variant: variant }
    })
  }


  if (loading) return <Loading />

  if (!post) return null



  return (
    <>
      <Helmet>
        <title>{post[`title_${i18n.language}`]}</title>
      </Helmet>
      <Container className={classes.root} maxWidth="lg">  
        <div className={classes.header}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" >
              All4Health
            </Link>
            <Link color="inherit" className={classes.link} onClick={() => clickVarianthandler( post.category)}>
                {post.category[`title_${i18n.language}`]}
              </Link>
            <Link color="inherit">
              {post[`title_${i18n.language}`]}
            </Link>
          </Breadcrumbs>
        </div>
        <Grid container
          spacing={4}
          justify="center">
          <Grid xs={12} sm={12} md={12} lg={8} xl={8} item>
            <Paper className ={classes.paper} square elevation={0} >
              <SlideShow images={post.images}/>
              <div className={classes.blogPostBody}>
                <div dangerouslySetInnerHTML={{ __html: post[`text_${i18n.language}`]}} />
                <div className={classes.boxProducts}>
                  <Grid container spacing={4} justify="space-around">
                    {products.map(item => (
                      <HighlightsProductsCard product={item}/>
                    ))}
                  </Grid>
                   
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
            <Paper square elevation={0} >
              <div className={classes.sideBar}>
                <div className={clsx(classes.sideBarTtle, classes.sideBarTtleLast)}>{t('last posts')}</div>
                {lastPosts.map(pst =>
                    <div className={classes.post} key={pst._id}>
                    <div className={classes.postThumbnail} onClick={() => clickPostHandler(pst._id)}>
                      {pst.thumbnail ? (
                        <img src={`${API_URL}/warehouse/${pst.thumbnail.filename}`} alt="" />
                      ) : null}
                      </div>
                      <div className={classes.postData}>
                      <p onClick={() => clickPostHandler(pst)}>
                        {pst[`title_${i18n.language}`]}
                      </p>
                      <span>{moment(new Date(pst.createdAt)).format("LLLL")}</span>
                      </div>
                    </div>
                  )}
              </div>
              <div className={classes.sideBar}>
                <div className={clsx(classes.sideBarTtle, classes.sideBarTtleMostRead)}>{t('most read')}</div>
                {mostReadPosts.map(pst =>
                    <div className={classes.post} key={pst._id}>
                    <div className={classes.postThumbnail} onClick={() => clickPostHandler(pst._id)}>
                      {pst.thumbnail ? (
                        <img src={`${API_URL}/warehouse/${pst.thumbnail.filename}`} alt="" />
                      ) : null}
                    </div>
                    <div className={classes.postData}>
                      <p onClick={() => clickPostHandler(pst)}>
                        {pst[`title_${i18n.language}`]}
                      </p>
                      <span>{moment(new Date(pst.createdAt)).format("LLLL")}</span>
                    </div>
                    </div>
                )} 
              </div>
              <Newsletter />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}


export default BologDetail;


