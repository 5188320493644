
const Styles =  theme => ({
    root: {
        marginTop: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    content: {
        padding: '30px',
        paddingLeft: '60px',
        maxWidth: '600px',
        zIndex: 1,
        '& h1': {
            color: '#FFF',
            marginTop: '30px',
            minHeight: '32px',
            fontWeight: 700,
            marginBottom: '25px',
            fontSize: '2.6rem! important'
        },
        '& P': {
            color: '#FFF',
            fontSize: '1.125rem',
            lineHeight: '1.5em',
            marginTop: '10px',
            marginBottom: '10px'
        },
        [theme.breakpoints.down('sm')]: {
            '& h1': {
                fontSize: '1.6rem! important'
            },
            '& p': {
                fontSize: '1rem',
                lineHeight: '1.1em',
            }
        },
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    button: {
        color: 'white',
        backgroundColor: '#e91e63',
        margin: '10px 5px 0',
        paddingTop: '11px',
        paddingBottom: '11px',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)',
            backgroundColor: '#e91e63',
        },
        '& span.MuiButton-label': {
            textTransform: 'uppercase !important',
            fontSize: '13px',
        }
    },
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        borderRadius: '6px',
        padding: '10px 20px 5px',
        overflow: 'unset',
        margin: '10px',
        width: '420px',
        [theme.breakpoints.down('sm')]: {
            width: '220px'
        }
    },
    cardHeader: {
        textAlign: 'center',
        padding: '20px',
        background: '#c34b1f',
        boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4)',
        margin: '-20px 15px 0',
        marginTop: '-40px',
        marginBottom: '20px',
        '& h2': {
            fontSize: '1.4rem !important',
            marginTop: '10px',
            marginBottom: '10px',
            fontWeight: 700,
            color: '#FFFFFF'
        },
        [theme.breakpoints.down('sm')]: {
            '& h2': {
                fontSize: '1.1rem !important',
            }
        }
    },
    cardAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '30px'
    },
    buttonSubmit: {
        color: 'white',
        boxShadow: '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)',
        backgroundColor: '#85256d',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)',
            backgroundColor: '#9c27b0'
        }
    }
})

export default Styles;