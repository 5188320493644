import React, {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Styles from './style';
import { makeStyles, Popper, Paper, Grow, ClickAwayListener, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-i18next';
import ListChildren from './ListChildren';
import { useApp } from "../../context/app";



const useStyles = makeStyles(Styles);

const MenuCategories = ({ anchorEl, close }) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [categoryParentSelected, setCategoryParentSelected] = useState(null);
    const [blogSelected, setBlogSelected] = useState(false);
    const { variants, categoriesBlog, categoriesBlogWithChildren } = useApp()





    const open = Boolean(anchorEl);


    const handleClose = () => {
        close()
    }

    

    const onMouseEnterParentHandler = (variant) => {
        setBlogSelected(false)
        setCategoryParentSelected(variant)
    }

    const onMouseEnterBlog = () => {
        setBlogSelected(true)
        const cat = { children: categoriesBlog }
        setCategoryParentSelected(cat)
    }

    const blogClickHandler = () => {
        navigate( '/blog')
        close()
    }

      const onMouseEnterFreeAdvise = () => {
        setBlogSelected(false)
        setCategoryParentSelected(null)
    }

    const freeAdviseClickHandler = () => {
        navigate({
            pathname: '/aconselhamento-gratuito',
            search: `?type=aconselhamento-gratuito`
        })
        close()
    }


    const categoryClickHandler = (item) => {
        navigate('/produtos', { state: { variant: item } })
        close()
    }

    const allCategoryClickHandler = () => {
        navigate({ pathname: '/produtos'})
        close()
    }



 

    return (
        <div className={classes.root}>
            <Popper
                anchorEl={anchorEl}
                placement="bottom-start"
                open={open}
                className={classes.pooper}
                transition
                disablePortal
                onMouseLeave={() => close()}
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
                        <ClickAwayListener onClickAway={() => close()}>
                            <Paper elevation={2} placement={'bottom-start'}>
                                <List id="menu-list-grow" autoFocus={false} autoFocusItem={false} className={classes.menuListParent}>
                                    <ListItem onMouseEnter={() => onMouseEnterParentHandler(null)} onClick={() => allCategoryClickHandler()} className={classes.allCategoryItem}>
                                        <span>{t('all variants')}</span>
                                    </ListItem>
                                    {variants?.map(variant => (
                                        <ListItem onMouseEnter={() => onMouseEnterParentHandler(variant)} onClick={() => categoryClickHandler(variant)}>
                                            <ListItemText primary={variant[`title_${i18n.language}`]} />
                                            <ListItemIcon>
                                                <NavigateNextIcon />
                                            </ListItemIcon>
                                        </ListItem>
                                    ))}
                                      <ListItem onClick={() => blogClickHandler()} onMouseEnter={() => onMouseEnterBlog()}>
                                            <ListItemText primary={t('blogs')} />
                                            <ListItemIcon>
                                                <NavigateNextIcon />
                                            </ListItemIcon>
                                        </ListItem>
                                        <ListItem onClick={() => freeAdviseClickHandler()} onMouseEnter={() => onMouseEnterFreeAdvise()}>
                                            <ListItemText primary={t('free advice')} />
                                            <ListItemIcon>
                                                <NavigateNextIcon />
                                            </ListItemIcon>
                                        </ListItem>
                                    </List>
                                    
                                {categoryParentSelected && (
                                    <div className={classes.containerChildrenParent}>
                                        <ListChildren
                                            isBlog={blogSelected}
                                            close={() => close(false)}
                                            depth={0}
                                            parent={categoryParentSelected}
                                            variants={categoryParentSelected?.children}
                                        />
                                    </div>
                                )}
                            </Paper>
                        </ClickAwayListener>
                    </Grow>
                )}
            </Popper>
        </div>
        
    )
}


export default MenuCategories



