import React, { createContext, useState, useEffect, useContext } from 'react';
import * as Localforage from 'localforage';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import {AxiosInstance} from './../service/axios';
import * as ApiService from './../service/api';



const AuthContext = createContext()

const AuthProvider = ({ children }) => {

    const { t } = useTranslation();
    const [user, setUser] = useState(null);
    const [signed, setSigned] = useState(false);
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        async function loadStorageData() {
            const storagedUser = await Localforage.getItem('@ALL4HealthAuth:user');
            const storagedToken = await Localforage.getItem('@ALL4HealthAuth:token');
            const isSessionIsExpired = await sessionIsExpired(storagedToken)

            if (storagedUser && storagedToken && isSessionIsExpired) {
                AxiosInstance.defaults.headers.Authorization = `Bearer ${storagedToken}`;
                setUser(storagedUser);
                setSigned(true)
            } else {
                setSigned(false)
            }
            setLoading(false);
        }
        loadStorageData();
    }, []);


    async function signIn(data) {
        try {
            const response = await ApiService.login(data)
            const content = response.data.content
            const token = content.access_token
            AxiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
            const user = await jwt_decode(token);
            await Localforage.setItem('@ALL4HealthAuth:user', user);
            await Localforage.setItem('@ALL4HealthAuth:token', token);
            setUser(user);
            setSigned(true)
            setLoading(false);
        } catch (error) {
            let errorMsg = t('auth error generic')
            throw new Error(errorMsg)
        }
    }

    async function signUp(data) {
        try {
            await ApiService.signup(data)
            // Popup info
        } catch (error) {
            const errorMsg = t(error.response.data.response.toLowerCase())
            throw new Error(errorMsg)
        }
    }

    async function signInFacebook(data) {
        try {
            const response = await ApiService.loginFacebook(data)
             const content = response.data.content
            const token = content.access_token
            AxiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
            const user = await jwt_decode(token);
            await Localforage.setItem('@ALL4HealthAuth:user', user);
            await Localforage.setItem('@ALL4HealthAuth:token', token);
            setUser(user);
            setSigned(true)
            setLoading(false);
        } catch (error) {
           let errorMsg = t('auth error generic')
            throw new Error(errorMsg)
        }
    }

    


    async function forgotPassword(data) {
        try {
            await ApiService.resetPasswordRequest(data)

        } catch (error) {
            let errorMsg = '';
            switch (error.response.status) {
                case 404:
                    errorMsg = t('') 
                    throw new Error(errorMsg)
                case 401:
                    errorMsg = t('')
                    throw new Error(errorMsg)
                default:
                    errorMsg = t('')
                    throw new Error(errorMsg)
            }
        }
    }

    async function signOut() {
        await Localforage.clear();
        setUser(null);
        setSigned(false)
    }

    const sessionIsExpired = async (token) => {
        if (!token) {
            return null
        }
        const tokenData = await jwt_decode(token);
        const { exp } = tokenData;
        return (exp * 1000) < Date.now() ? null : tokenData;
    }


    return (
        <AuthContext.Provider
            value={{ signed: signed, user, loading, signIn, signOut, signUp, signInFacebook, forgotPassword }}>
            {children}
        </AuthContext.Provider>
    );

}

function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider.');
    }
    return context;
}

export { AuthProvider, useAuth };

