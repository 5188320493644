import React from 'react';
import Styles from './style';
import { makeStyles, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';
import { useApp } from "../../../context/app";



const useStyles = makeStyles(Styles);

const API_URL = process.env.REACT_APP_DOMAIN;


const CardItem = ({
    item
}) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const confirm = useConfirm();
    const { removeCart } = useApp()



    const onRemoveItemHandler = () => {
        confirm({ title: t('confirm'), description: t('delete item cart info') })
            .then(async () => {
                await removeCart(item?.product)
            })
            .catch();
    }


    return (
        <div className={classes.root}>
            <div className={classes.imageBox}>
                <img src={`${API_URL}/warehouse/${item?.product?.thumbnail?.filename}`} alt="thumb" />
            </div>
            <div className={classes.infoBox}>
                <h5>{item?.product[`title_${i18n.language}`]}</h5>
                <h6>{t('quantity')}:&nbsp;{item?.quantity}</h6>
                <h6>{t('price')}:&nbsp;{item?.product?.finalPrice}&euro;</h6>
                <div className={classes.buttonBox}>
                    <IconButton aria-label="delete" size="small" disableFocusRipple={true} disableRipple={true} onClick={() => onRemoveItemHandler()} >
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}




export default CardItem

