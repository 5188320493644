import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Styles from './styles';
import { makeStyles } from '@material-ui/core';
import BlogDividerBackground from './../../../assets/images/blog-divider-background.jpg';


const API_URL = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles(Styles);


const BlogDivider = ({ data }) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleClickCard = item => {
        navigate(`/product/${item._id}`)
    }


    if (!data) {
        return null
    }


    return (
       <div className={classes.root}>
            <div className={classes.content} >
                {data.map(item => (
                    <div className={classes.productCard} key={item._id} onClick={() => handleClickCard(item)}>
                        <div className={classes.productCardBoxImage}>
                            <img src={`${API_URL}/warehouse/${item.thumbnail.filename}`} alt="" />
                        </div>
                        <div className={classes.productCardBoxInfo}>
                            <div className={classes.productCardBoxTitle}>
                                <h3>{item[`title_${i18n.language}`]}</h3>
                            </div>
                            <div className={classes.productCardBoxCategory}>
                                <h5>{item.variants[0] ? item.variants[0][`title_${i18n.language}`] : null }</h5>
                            </div>
                            <div className={classes.productCardBoxDescription}>
                                <p>{item[`smallDescription_${i18n.language}`]}</p>
                            </div>
                        </div>
                    </div>
                ))}
           </div>
           <div className={classes.boxImage} >
                <img src={BlogDividerBackground} alt="" />
           </div>
       </div>
    )
}


export default BlogDivider;
