import React from 'react';
import clsx from 'clsx';
import Styles from './style';
import { makeStyles, Button, CircularProgress } from '@material-ui/core';


const useStyles = makeStyles(Styles);


const SubmitButton = ({
    styles,
    classesOverwrite,
    children,
    isSubmiting,
    ...rest
}) => {

    const classes = useStyles();



    const renderButtonContent = () => {
        if (isSubmiting) {
            return (
                <CircularProgress size={24} color={'inherit'}/>
            )
        }
        return children
    }

    return (
        <Button className={clsx(classes.root, classesOverwrite)}
            size="large"
            variant="contained"
            color="primary"
            type="submit" {...rest}>
            {renderButtonContent()}
        </Button>
    )
};

export default SubmitButton;


