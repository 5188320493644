import React, { useEffect, useState } from 'react';
import Styles from './style';
import { makeStyles, Container, Card, Button, FormControl, TextField  } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useConfirm } from 'material-ui-confirm';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useTranslation } from 'react-i18next';
import { useApp } from "../../context/app";
import { useAuth } from "../../context/auth";
import paymentMethods from '../../assets/images/payments-methods.svg';
import Table from './Table';
import * as ApiService from '../../service/api';
import SubmitButton from '../../components/SubmitButton';




const useStyles = makeStyles(Styles);

const Cart = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const { cart, clearCart, totalPriceInCart, freeShip, settings, setOpenLogin, setAppLoading } = useApp()
    const { signed } = useAuth()

    const [dataCart, setDataCart] = useState(null)
    const [promoCode, setPromoCode] = useState(null)


    const calculateTotal = () => {
        let total = Number(totalPriceInCart)
        if (!freeShip) {
            const shippingCost = Number(settings?.shippingCost)
            total = Number(total + shippingCost).toFixed(2)
        }
        if (promoCode) {
            const promoCodevalue = Number(promoCode.value)
            const discount = Number(Number(total) * Number(promoCodevalue / 100))
            total = Number(total - discount).toFixed(2)
        }
        return total;
    }


    const formik = useFormik({
        initialValues: {
            code: '',
            promoCode: t('promo code info msg')
        },
        onSubmit: async (values, { setValues, resetForm }) => {
            try {
                const resp = await ApiService.validatePromo(values.code)
                const value = resp.data.content
                setPromoCode(value)
                resetForm()
                setValues({ promoCode: t('promo code success'), code: '' });
            } catch (error) {
                setPromoCode(null)
                resetForm()
                setValues({ promoCode: t('promo code error') });
            }
        }
    })



  
    const clearCartHandler = (product) => {
        confirm({ title: t('confirm'), description: t('clear cart info confirm') })
            .then(async () => {
                clearCart()
            })
            .catch();
    }


    const checkoutHandler = () => {
        if (signed) {
            createOrder()
        } else {
            confirm({ title: t('confirm'), description: t('login to checkout info') })
                .then(async () => {
                    setOpenLogin(true)
                })
                .catch();
        }
    }

    const onBackHandler = () => {
        navigate('/produtos')
    }


    const createOrder = async () => {
        try {
            setAppLoading(true)
            const postData = {
                promoCode: promoCode ? promoCode._id : null,
                orderItemsData: cart?.map(item => {
                    return {
                        quantity: item.quantity,
                        product: item.product?._id
                    }
                })
             }
            const resp = await ApiService.getOrderIdClient(postData)
            const orderId = resp.data?.content?._id
            setAppLoading(false)
            navigate('/checkout', { replace: true, state: { orderId: orderId } })
        } catch (error) {
            setAppLoading(false)
        }
    }


    useEffect(() => {
       setDataCart(cart)
    }, [cart])


   
    if (!dataCart) return null

    return (
        <Container className={classes.root} maxWidth="xl" >
                <Card className={classes.card}>
                    <div className={classes.header}>
                        <ShoppingCartIcon />
                    </div>
                    <div className={classes.container}>
                        {(dataCart?.length) > 0 ? (
                            <div>
                                <div className={classes.itemsWrapper}>
                                    <Table cart={cart} />
                                    <div className={classes.infoTotalWrapper}>
                                        <div className={classes.infoTotalWrapperInner}>
                                            <div className={classes.subTotalBox}>
                                                <h3>{t('subtotal')}:&nbsp;</h3>
                                                <h3>{totalPriceInCart}&euro;</h3>
                                            </div>
                                            <div className={classes.shipBox}>
                                                <h6>{t('shipping')}:&nbsp;</h6>
                                                {freeShip ? <h6>{t('free')}</h6> : <h6>{settings?.shippingCost}&euro;</h6>}
                                            </div>
                                            {promoCode && (
                                            <div className={classes.promoCodeBox}>
                                                <h6>{t('promo code discount')}:&nbsp;</h6>
                                                <h6>{promoCode?.value}%</h6>
                                            </div>
                                            )}
                                            <div className={classes.totalBox}>
                                                <h2>{t('total')}:&nbsp;</h2>
                                                {!freeShip ? (
                                                <h2>{calculateTotal()}&euro;</h2>
                                                ) : (
                                                    <h2>{calculateTotal()}&euro;</h2>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className={classes.areaPromoCode}>
                                    <FormControl>
                                        <TextField
                                            style={{ marginTop: '0', marginBottom: '0' }}
                                            id="code"
                                            label={t('promo code')}
                                            variant="outlined"
                                            margin="normal"
                                            autoComplete="off"
                                            value={formik.values.code}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.code && formik.touched.code}
                                            helperText={formik.values.promoCode}

                                        />
                                    </FormControl>
                                    <SubmitButton className={classes.buttonSubmit}
                                        isSubmitting={formik.isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        {t('apply')}
                                    </SubmitButton>
                                </div>
                            </form>
                                <div className={classes.actionsWrapper}>
                                            <Button variant="contained" onClick={() => onBackHandler()} className={classes.buttonBackStore}>
                                                {t('back to store')}
                                            </Button>
                                            <Button variant="contained" onClick={() => clearCartHandler()} className={classes.buttonClearCart}>
                                                {t('clear cart')}
                                            </Button>
                                            <Button variant="contained" onClick={() => checkoutHandler()} className={classes.buttonBuy}>
                                                {t('buy')}
                                            </Button>
                                </div>
                                <div className={classes.paymentMethods}>
                                    <h4>{t('our payment methods')}</h4>
                                    <div>
                                        <img src={paymentMethods} alt="" />
                                    </div>
                                </div>
                            </div>
                            
                        ) : (
                                <div className={classes.boxEmptyCart}>
                                    <ShoppingCartIcon className="icon-shopping-cart" />
                                    <h3>{t('your cart is empty')}!</h3>
                                    <Button variant="contained" color="primary" className={classes.buttonViewProducts} onClick={() => navigate('/produtos')}>
                                        {t('view products')}
                                    </Button>
                                </div>
                        )}

                    </div>
                </Card>
        </Container>
    )
}

export default Cart

