
const Styles = theme => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        background: 'radial-gradient(ellipse at center, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.7) 100%)',
        zIndex: 1,
        position: 'relative',
        padding: '30px 0'
    },
    productCard: {
        cursor: 'pointer',
        margin: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    productCardBoxImage: {
        margin: '10px',
        width: '150px',
        height: '170px',
        borderRadius: '6px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            borderRadius: '6px'
        }
    },
    productCardBoxInfo: {
        width: '280px',
        margin: '10px',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '220px',
        }
    },
    productCardBoxTitle: {
        '& h3': {
            fontWeight: 700,
            marginBottom: '0.75rem',
            textDecoration: 'none',
            color: '#FFF !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.15rem'
            }
        }
    },
    productCardBoxCategory: {
        '& h5': {
            color: theme.palette.secondary.main,
            fontSize: '0.75rem',
            textTransform: 'uppercase',
            fontWeight: 700,
            marginTop: '10px'
        }
    },
    productCardBoxDescription: {
        '& p': {
            display: 'box',
            lineClamp: 3,
            boxOrient: 'vertical',
            overflow: 'hidden',
            color: 'rgba(255, 255, 255, 0.76)',
            fontSize: '14px',
            margin: '10px 0 10px'
        }
    },
    boxImage: {
        top: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 0,
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    }
})

export default Styles;