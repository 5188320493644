const Styles = theme => ({
    root: {
        zIndex: 2010,
        marginTop: '0px',
        borderRadius: '60px',
        boxShadow: '0 16px 24px 2px rgba(0,0,0, 0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2)'
    },
    pooper: {
        width: 'auto',
        '& .MuiPaper-root': {
            marginTop: '12px'
        }
    },
    menuList: {
        cursor: 'pointer',
        minWidth: "170px",
        '& li': {
            '&:hover': {
                backgroundColor: '#9c27b0',
                '& span': {
                    color: '#FFF !important',
                },
                '& svg': {
                    color: '#FFF !important',
                }
            }
        }
    }
})

export default Styles;