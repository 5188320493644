import React from 'react';
import clsx from 'clsx';
import Routes from './../routes';
import { makeStyles } from '@material-ui/core/styles';
import Styles from './style';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PopupLogin from '../components/PopupLogin';
import PopupForgotPassword from '../components/PopupForgotPassword';
import PopupSignup from '../components/PopupSignup';
import NewsLetter from '../components/NewsLetter';
import Contact from '../components/Contact';
import { useApp } from '../context/app';
import { Container } from '@material-ui/core';
import PopupSearch from '../components/PopupSearch';
import DrawerMenu from '../components/DrawerMenu';
import DrawerCategories from '../components/DrawerCategories';
import Notification from '../components/Notification';
import DrawerCart from '../components/DrawerCart';



const useStyles = makeStyles(Styles);


const PublicLayout = () => {

    const classes = useStyles();

    const { openLogin, openSignup, openSearch, openMenuDrawer, openCategoriesDrawer, openCartDrawer, openRecoverPassword } = useApp();




    return (
        <div className={classes.publicRoot}>
            <React.Fragment>
                <Notification />
                <Header />
                <Container className={clsx(classes.content)}>
                    <Routes />
                </Container>
                <NewsLetter />
                <Contact />
                <Footer />
                {openLogin && <PopupLogin />}
                {openSignup && <PopupSignup />}
                {openRecoverPassword && <PopupForgotPassword />}
                {openSearch && <PopupSearch />}
                {openMenuDrawer && <DrawerMenu />}
                {openCategoriesDrawer && <DrawerCategories />}
                {openCartDrawer && <DrawerCart />}
            </React.Fragment>
        </div>
    )
}

export default PublicLayout;