import palette from '../palette';

const MuiDialog = {
    paper: {
        '& .MuiDialogTitle-root': {
                backgroundColor: palette.primary.dark,
                color: palette.white,
                '& h2': {
                    color: palette.white,
                 }
        },
        '& .MuiDialogContent-root': {
            padding: 20
        }
    }
};

export default MuiDialog;
