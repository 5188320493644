
const Styles =  theme => ({
    root: {},
    boxContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '30px 0 0',
        flexWrap: 'wrap'
    },
    boxCategoryItem: {
        cursor: 'pointer',
        margin: '5px 15px',
        padding: '10px 30px',
        '& h5': {
            margin: 0
        },
        '&:hover': {
            color: '#FFF',
            boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)',
            backgroundColor: '#e91e63',
            borderRadius: '25px'
        }
    },
    boxCategoryItemSeleted: {
        color: '#FFF',
        boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)',
        backgroundColor: '#e91e63',
        borderRadius: '25px'
    }
})
export default Styles;