import jwt_decode from 'jwt-decode';
const {
    getItem,
    setItem,
    removeItem
} = require('localforage');

export const decodeToken = token => jwt_decode(token);

export const setToken = async token => await setItem('@aLL4HealthAuth:token', token);

export const getToken = async () => await getItem('@aLL4HealthAuth:token');

export const deleteToken = async () => await removeItem('@aLL4HealthAuth:token');

export const setUserData = async (userData) => await setItem('@aLL4HealthAuth:user', userData);

export const getUserData = async () => await getItem('@aLL4HealthAuth:user');

export const sessionIsExpired = async () => {
    const token = await getToken();
    if (!token) {
        return null
    }
    const tokenData = await decodeToken(token);
    const { exp } = tokenData;
    return (exp*1000) < Date.now() ? null : tokenData;
}


export const setItemsCart = async cart =>  setItem('cart', cart);

export const getItemscart = async () =>  await getItem('cart');

export const setTotalItemsCart = async totalCart => setItem('totalCart', totalCart);

export const getTotalItemscart = async () => await getItem('totalCart');


export const setOrderId = async orderId => setItem('orderID', orderId);

export const getOrderId = async () => await getItem('orderID');
