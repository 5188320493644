
  
const Styles = theme => ({
    root: {
        margin: '0',
        position: 'relative',
        width: '100%',
        height: '320px',
        flex: '0 0 auto',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    overlayImage: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        "& .lazy-load-image-background": {
            width: '100%',
            height: '100%',
            '&  img': {
                height: '100%',
                width: '100%',
                objectFit: 'cover'
            }
        }
       
    },
    postDetail: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        width: '100%',
        height: '320px',
        zIndex: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }
    },
    tagArea: {
        marginBottom: '10px',
        paddingLeft: '20px'
    },
    tag: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        padding: '1px 15px 1px 10px',
        backgroundColor: '#fff',
        border: '1px solid grey',
        color: 'black',
        marginBottom: '5px',
        marginRight: '10px',
        wordBreak: 'break-word',
        minHeight: '28px',
        lineHeight: '15px',
        borderRadius: '30px',
        width: 'max-content',
        fontSize: '12px',
        fontWeight: 'bold',
        textTransform: 'lowercase',
        '& span:first-of-type': {
            display: 'inline-block',
            width: '14px',
            height: '14px',
            position: 'relative',
            borderRadius: '100%',
            marginRight: '10px',
            flexShrink: 0
        },
        '& span:last-of-type': {}
    },
    titleArea: {
        marginBottom: '30px',
        paddingLeft: '20px',
        color: 'white'
    }
})

export default Styles;


