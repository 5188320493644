import React, { useMemo, useState }  from 'react'
import Styles from './styles';
import { Field, Formik } from 'formik';
import FacebookLogin from 'react-facebook-login';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RadioGroup } from 'formik-material-ui';
import * as Yup from 'yup';
import { makeStyles, InputLabel, Grid, Dialog, DialogContent, DialogTitle, FormControl, TextField, Paper, Container, FormControlLabel, Radio, Checkbox } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SubmitButton from '../SubmitButton';
import { useApp } from "../../context/app";
import { useAuth } from "../../context/auth";



const FE_URL = process.env.REACT_APP_FE_DOMAIN;
const APP_ID_FACEBOOK = process.env.REACT_APP_ID_FACEBOOK;

const defaultInitialValues = {
    firstName: '',
    lastName: '',
    gender: 'male',
    birthDate: null,
    nif: '',
    email: '',
    password: '',
    confirmPassword: ''
}


const useStyles = makeStyles(Styles);



const PopupSignup = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [acceptTermsAndPolicies, setAcceptTermsAndPolicies] = useState(false)
    const [initialValues] = useState(defaultInitialValues)
    const { openSignup, setOpenSignup, showNotifications } = useApp()
    const { signUp, signUpFacebook } = useAuth()



    const validationSchema = useMemo(() => Yup.object().shape({
        email: Yup.string()
            .email('invalid email'),
        password: Yup.string("")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/,
                t('password regex')
            ),
        confirmPassword: Yup.string("enter your password")
            .oneOf([Yup.ref("password")], t('password does not match'))
    }), [t]);



    const handleChangeAcceptTermsAndPolicies = event => {
        setAcceptTermsAndPolicies(event.target.checked)
    }

    const goToTermsAndPolicies = () => {
        navigate({
            pathname: '/politica-de-privacidade',
            search: `?type=privacidade`
        })
    }

    const clickContactUsHandler = () => {
       
    }


    const onSubmitForm = async (values, { resetForm}) => {
        try {
            const postData = {
                user: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email?.toLowerCase(),
                    password: values.password,
                    nif: parseInt(values.nif),
                    gender: values.gender,
                    birthDate: values.birthDate ? new Date(values.birthDate) : null
                },
                redirectUrl: `${FE_URL}/conta/verificar-email`
            }
            await signUp(postData);
            const notf = { type: 'success', title: t('success signup title'), message: t('success signup description') }
            showNotifications(notf)
            setOpenSignup(false)
            resetForm()
        } catch (error) {
            const notf = { type: 'error', title: t('register'), message: t(error) }
            showNotifications(notf)
        }
    }

    const responseFacebook = async (response) => {
        try {
            const postData = {
                userID: response.userID
            };
            await signUpFacebook(postData);
        } catch (error) {
            //actions.setErrors({ signup: t('auth error on signup default') });
        }
    }






    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitForm}
        >
            {props => (
                <Dialog
                    maxWidth="md"
                    disableBackdropClick={false}
                    onClose={() => setOpenSignup(false)}
                    open={openSignup}
                    aria-labelledby="popup-info-title"
                    aria-describedby="popup-info-description"
                >
                    <DialogTitle id="popup-info-title" className={classes.title}>
                        {t('register')}
                    </DialogTitle>
                    <DialogContent>
                        <Container className={classes.root} maxWidth="sm">
                            <form onSubmit={props.handleSubmit}>
                                <Grid container direction="column" spacing={0} >
                                    <Grid item xs={12}>
                                        {!!props.errors.signup && (
                                            <Alert severity="error">{props.errors.signup}</Alert>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="firstName"
                                                        placeholder={`${t('first name')} *`}
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        autoComplete="off"
                                                        value={props.values.firstName}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.errors.firstName && props.touched.firstName}
                                                        helperText={(props.errors.firstName && props.touched.firstName) && props.errors.firstName}
                                                        required
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="lastName"
                                                        placeholder={`${t('last name')} *`}
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        autoComplete="off"
                                                        value={props.values.lastName}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.errors.lastName && props.touched.lastName}
                                                        helperText={(props.errors.lastName && props.touched.lastName) && props.errors.lastName}
                                                        required
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <FormControl margin='normal' fullWidth >
                                            <Field
                                                style={{ justifyContent: 'flex-end' }}
                                                row={true}
                                                component={RadioGroup}
                                                value={props.values.gender}
                                                name="gender"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur('gender')}
                                            >
                                                <FormControlLabel
                                                    key="M"
                                                    value="male"
                                                    control={<Radio />}
                                                    label={t('male')}
                                                />
                                                <FormControlLabel
                                                    key="F"
                                                    value="female"
                                                    control={<Radio />}
                                                    label={t('female')}
                                                />
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid conatiner spacing={4}>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel shrink >{`${t('date of birth')} *`}</InputLabel>
                                                <TextField
                                                    style={{ marginTop: '20px' }}
                                                    id="birthday"
                                                    type="date"
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    autoComplete="off"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.birthday && props.touched.birthday}
                                                    helperText={(props.errors.birthday && props.touched.birthday) && props.errors.birthday}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <TextField
                                                    className={classes.nifInput}
                                                    id="nif"
                                                    placeholder={t('nif')}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    autoComplete="off"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.nif && props.touched.nif}
                                                    helperText={(props.errors.nif && props.touched.nif) && props.errors.nif}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="email"
                                                placeholder={`${t('email')} *`}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                autoComplete="off"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.email && props.touched.email}
                                                helperText={(props.errors.email && props.touched.email) && props.errors.email}
                                                required
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container
                                        spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="password"
                                                    type="password"
                                                    placeholder={`${t('password')} *`}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    autoComplete="off"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.password && props.touched.password}
                                                    helperText={(props.errors.password && props.touched.password) && props.errors.password}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="confirmPassword"
                                                    type="password"
                                                    placeholder={`${t('confirm password')} *`}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    autoComplete="off"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.confirmPassword && props.touched.confirmPassword}
                                                    helperText={(props.errors.confirmPassword && props.touched.confirmPassword) && props.errors.confirmPassword}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Paper className={classes.termsPaper} elevation={0}>
                                            <span onClick={goToTermsAndPolicies}>{t('i have read and accept the terms and conditions and the privacy policy')}</span>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={acceptTermsAndPolicies} onChange={handleChangeAcceptTermsAndPolicies} name="notRobot" />}

                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs>
                                        <Paper className={classes.submitPaper} elevation={0}>
                                            <SubmitButton className={classes.buttonSubmit}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                isSubmiting={props.isSubmitting}
                                                disabled={!acceptTermsAndPolicies}
                                                color="primary">
                                                {t('create account')}
                                            </SubmitButton>
                                            <span className="requiredInfo">
                                                {`* ${t('required fields')}`}
                                            </span>
                                        </Paper>
                                        <Paper className={classes.facebookPaper}>
                                            <FacebookLogin
                                                appId={APP_ID_FACEBOOK}
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                callback={responseFacebook}
                                                isDisabled={!acceptTermsAndPolicies}
                                                textButton={t('sign up with facebook')} />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs>
                                        <Paper className={classes.paperOtherInfo}
                                            elevation={0}>
                                            <div className="spliter"></div>
                                            <div className="info">
                                                {t('create account info footer 1')}&nbsp;
                                                <span onClick={clickContactUsHandler}>{t('contact-us-footer')}</span>
                                                {'.\n'}
                                                {t('create account info footer 2')}
                                            </div>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </form >
                        </Container>
                    </DialogContent>
                </Dialog>
            )}
        </Formik>
    )
}


export default PopupSignup;
 