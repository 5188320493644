import { AxiosInstance, AxiosInstanceAuth} from '../axios';

const AUTH = 'auth';
const HELP = 'help';
const VARIANTS = 'variants';
const PRODUCTS = 'products';
const FAVORITES = 'favorites';
const SOCIAL = 'social';
const BLOG = 'blog';
const ORDERS = 'orders';
const CLIENT = 'client';
const PAYMENT = 'payments';
const NEWSLETTER = 'newsletter-emails'
const SETTINGS = 'settings'
const PAGES = 'pages'
const CONTACTS = 'contacts'
const BANNERS = 'banners'
const SEO = 'seo'
const CONTACT_INFO = 'contact-info'
const CATEGORIES = 'blog-categories'
const USERS = 'users'
const CONFIRM = 'confirm'
const METHOD = 'method'
const PROMO = 'promo-codes'




/*************************************************** USERS ************************************************/
export const signup = async (postData) =>
    await AxiosInstanceAuth.post(`${AUTH}/signup`, postData);

export const signupfacebook = async (postData) =>
    await AxiosInstanceAuth.post(`${AUTH}/login-facebook`, postData);

export const login = async (postData) =>
    await AxiosInstanceAuth.post(`${AUTH}/login`, postData);

export const resetPasswordRequest = async (postData) =>
    await AxiosInstanceAuth.post(`${AUTH}/request-reset-password`, postData);

export const changePassword = async (postData) =>
    await AxiosInstance.post(`${USERS}/change-password`, postData);

export const verifyEmail = async (postData) => await AxiosInstance.post(`${AUTH}/verify-user`, postData);

export const setUserData = async (id, postData) =>
    await AxiosInstance.put(`${USERS}/${id}`, postData);

export const loginFacebook = async (postData) =>
    await AxiosInstanceAuth.post(`${AUTH}/login-facebook`, postData);

export const getUser = async (id) =>
    await AxiosInstance.get(`${USERS}/${id}`)

export const confirmResetPassword = async (postData) =>
    await AxiosInstance.post(`${AUTH}/confirm-reset-password`, postData);


/************************************************** BANNERS *************************************************/
export const getBanners = async (params) =>
    await AxiosInstance.get(`${BANNERS}`, { params: params })


export const getBlogPosts = async (params) =>
    await AxiosInstance.get(`${BLOG}`, { params: params });

export const getBlogPost = async (id) =>
    await AxiosInstance.get(`${BLOG}/${id}`)

export const getAllCategoriesBlog = async () =>
    await AxiosInstance.get(`${CATEGORIES}`)


/************************************************ PRODUCTS ***********************************************/
export const getAllProducts = async (filter) =>
    await AxiosInstance.get(`${PRODUCTS}`, { params: filter })

export const getProdutsFiltered = async (filter, params) =>
    await AxiosInstance.get(`${PRODUCTS}?${filter}=true`, { params: params })

export const getProduct = async (id) =>
    await AxiosInstance.get(`${PRODUCTS}/${id}`)


/********************************************** NEWSLETTER ************************************************** */
export const addNewsLetter = async (postData) =>
    await AxiosInstance.post(`${NEWSLETTER}`, postData);


/********************************************** CONTACTS ************************************************** */
export const updateContacts = async (postData) =>
    await AxiosInstance.post(`${CONTACTS}`, postData)

export const getContactInfo = async () =>
    await AxiosInstance.get(`${CONTACT_INFO}`)

/************************************************ SOCIAL *********************************************** */
export const getSocial = async () =>
    await AxiosInstanceAuth.get(`${SOCIAL}`)


/********************************************** PAGES ************************************************** */
export const getPage = async (type) =>
    await AxiosInstance.get(`${PAGES}/${type}`)

/************************************************ VARIANTS ***********************************************/
export const getAllVariants = async () =>
    await AxiosInstance.get(`${VARIANTS}`)

/********************************************** SETTINGS ************************************************** */
export const getSettings = async () =>
    await AxiosInstance.get(`${SETTINGS}`)

/********************************************** ORDERS ************************************************** */
export const getOrderIdClient = async (postData) =>
    await AxiosInstance.post(`${ORDERS}`, postData);

export const getOrdersClient = async () =>
    await AxiosInstance.get(`${ORDERS}/${CLIENT}`);


/********************************************** PAYMENT ************************************************** */
export const paymentIntent = async (postData) =>
    await AxiosInstance.post(`${PAYMENT}`, postData);


export const paymentMethod = async (postData) =>
    await AxiosInstance.post(`${PAYMENT}/${METHOD}`, postData);


export const paymentConfirm = async (postData) =>
    await AxiosInstance.post(`${PAYMENT}/${CONFIRM}`, postData);

/********************************************** PROMO ************************************************** */
export const validatePromo = async (code) =>
    await AxiosInstance.get(`${PROMO}/${code}`)

/************************************************** HELP *************************************************/
export const getHelps = async () =>
    await AxiosInstance.get(`${HELP}`)

