import { fade } from '@material-ui/core/styles';


const Styles = theme => ({
  appBar: {
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
  },

  toolbar: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.breakpoints.values.lg,
    padding: '0 20px !important',
    boxShadow: 'none !important',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginLeft: '0px',
      marginRight: '0px',
      padding: '0 10px !important',
    }
  },

  search: {
    marginLeft: '20px',
    marginRight: '20px',
    flexGrow: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      marginRight: '10px',
    }
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputRoot: {
    color: 'inherit',
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%'
  },

  menu: {
    cursor: 'pointer',
    listStyle: 'none',
    display: 'flex',
    padding: 0,
    '& li': {
      margin: '0px 10px',
      '&:hover': {
        color: theme.palette.secondary.dark,
      }
    }
  },
  
  bar: {
    width: '2px',
    backgroundColor: theme.palette.white,
    height: 'auto'
  },

  cartButton: {
    width: '41px',
    height: '41px',
    boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px - 5px rgba(0, 188, 212, 0.46)',
    backgroundColor: '#00acc1',
    borderRadius: '30px',
    margin: '0 5px',
    '&:hover': {
      boxShadow: '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
      backgroundColor: '#00acc1',
    },
    '& .MuiBadge-badge': {
      background: 'linear-gradient(60deg, #ef5350, #e53935)',
      color: 'white'
    },
    [theme.breakpoints.down('sm')]: {
      width: '31px',
      height: '31px',
    }
  },

  compareButton: {
    width: '41px',
    height: '41px',
    boxShadow: '0px 2px 2px 0px rgba(233, 30, 99, 0.14), 0px 3px 1px 2px rgba(0, 0, 0, 0.44), 0px 1px 5px 0 rgba(111, 79, 90, 0.12) !important',
    backgroundColor: '#e91e63',
    borderRadius: '30px',
    margin: '0 5px',
    '&:hover': {
      boxShadow: '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
      backgroundColor: '#e91e63'
    },
    '& .MuiBadge-badge': {
      background: 'linear-gradient(60deg, #00acc1, #04bccc)',
      color: 'white'
    },
    [theme.breakpoints.down('sm')]: {
      width: '31px',
      height: '31px',
    }
  },

  userButton: {
    width: '41px',
    height: '41px',
    boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px - 5px rgba(156, 39, 176, 0.46)',
    backgroundColor: '#9c27b0',
    borderRadius: '30px',
    margin: '0 5px',
    '&:hover': {
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px - 5px rgba(156, 39, 176, 0.46)',
      backgroundColor: '#9c27b0'
    },
    [theme.breakpoints.down('sm')]: {
      width: '31px',
      height: '31px',
    }
  },

  menuHamburger: {
    marginRight: '20px',
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '5px',
    }
  },

  logoContainer: {
    display: 'flex',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1
    }
  },

  languages: {
    margin: 'auto 20px',
    display: 'inline-flex',
    '& span': {
      margin: 'auto 5px',
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      opacity: 0.5
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto 3px'
    }
  },

  active: {
    opacity: '1 !important'
  },

  searchMobile: {
    marginRight: '20px',
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '5px',
    }
  },

  categoriesBoxM: {
    marginRight: '10px'
  }

})

export default Styles;