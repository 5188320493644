import React, { useEffect, useState } from 'react';
import Styles from './style';
import { makeStyles, Grid, FormControl, TextField, InputLabel, Select, Button, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import countries from "i18n-iso-countries";
import { useFormik } from 'formik';


const initialValues = {
    email: "",
    name: "",
    phone: "",
    line1: "",
    postal_code: "",
    city: "",
    country: "",
    nif: ''
}


const useStyles = makeStyles(Styles);

const DeliveryForm = ({
    userInfo,
    data,
    onSbumit,
    onBack
}) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [useUserData, setUseUserData] = useState(false)

    const [allCountries, setAllCountries] = useState([])

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (values) => {
            onSbumit(values)
        }
    })



    const onChangeUseDataHanlder = (checked) => {
        if (checked) {
            const _address = userInfo?.shippingAddress
            if (_address) {
                const dataForm = {
                    email: userInfo?.email,
                    name: `${userInfo.firstName} ${userInfo.lastName}`,
                    phone: userInfo?.shippingPhone,
                    line1: userInfo?.shippingAddress?.line1,
                    postal_code: userInfo?.shippingAddress?.cep,
                    city: userInfo?.shippingAddress?.city,
                    country: userInfo?.shippingAddress?.country,
                }
                formik.setValues(dataForm)
                setUseUserData(true)
            }
        } else {
            formik.resetForm()
            setUseUserData(false)
        }
    }



    useEffect(() => {
        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
        let _countries = Object.entries(countries.getNames(i18n.language))
        _countries = _countries.filter(it => it[0] === 'PT')
        setAllCountries(_countries)
    }, [i18n])

    
    useEffect(() => {
        if (data) formik.setValues(data)
    }, [])


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            id="name"
                            required
                            label={t('name')}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.name && formik.touched.name}
                            helperText={(formik.errors.name && formik.touched.name) && formik.errors.name}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            id="line1"
                            required
                            label={t('address')}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            value={formik.values.line1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.line1 && formik.touched.line1}
                            helperText={(formik.errors.line1 && formik.touched.line1) && formik.errors.line1}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <FormControl fullWidth>
                        <TextField
                            id="postal_code"
                            required
                            label={t('cep')}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            value={formik.values.postal_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.postal_code && formik.touched.postal_code}
                            helperText={(formik.errors.postal_code && formik.touched.postal_code) && formik.errors.postal_code}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <FormControl fullWidth>
                        <TextField
                            id="city"
                            required
                            label={t('city')}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.city && formik.touched.city}
                            helperText={(formik.errors.city && formik.touched.city) && formik.errors.city}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <FormControl fullWidth>
                        <TextField
                            id="phone"
                            required
                            label={t('phone')}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.phone && formik.touched.phone}
                            helperText={(formik.errors.phone && formik.touched.phone) && formik.errors.phone}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" >
                        <InputLabel>{t('country')}</InputLabel>
                        <Select
                            native
                            value={formik.values.country}
                            onChange={formik.handleChange('country')}
                            onBlur={formik.handleBlur('country')}
                            error={formik.errors.country && formik.touched.country}
                            label={t('country')}
                            inputProps={{
                                name: t('country'),
                                id: 'country',
                            }}
                        >
                            <option value={null} />
                            {allCountries.map(country =>
                                <option value={country[1]}>{country[1]}</option>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox checked={useUserData}
                                    onChange={ev => onChangeUseDataHanlder(ev.target.checked)}
                                    name="checkedA"
                                />}
                            label={t('use user data  address')}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <div className={classes.submitBox}>
                <Button
                    onClick={() => onBack()}
                    variant="contained"
                    color="secondary">
                    {t('previous')}
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary">
                    {t('next')}
                </Button>
            </div>
        </form>
    )
}




export default DeliveryForm

