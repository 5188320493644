import React from 'react';
import Styles from './style';
import { makeStyles, Popper, Paper, Grow, ClickAwayListener, List, ListItem, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles(Styles);

const OrderFilter = ({ anchorEl, orders, close, onSelect }) => {

    const classes = useStyles();
    const { t } = useTranslation();


    const open = Boolean(anchorEl);

    
 
    return (
        <div className={classes.root}>
            <Popper
                anchorEl={anchorEl}
                placement="left-start"
                open={open}
                className={classes.pooper}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }}>
                        <Paper elevation={0}>
                            <ClickAwayListener onClickAway={() => close()}>
                                <List id="menu-list-grow" autoFocus={false} autoFocusItem={false} className={classes.menuList}>
                                    {orders.map(item => (
                                        <ListItem onClick={() => {
                                            close()
                                            onSelect(item)
                                        }}>
                                            <ListItemText primary={t(item.label)} />
                                        </ListItem>
                                    ))}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
        
    )
}




export default OrderFilter

