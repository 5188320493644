import React, { useEffect, useState } from 'react';
import Styles from './style';
import { makeStyles, Container, Card, Grid } from '@material-ui/core';
import { useNavigate, useLocation} from 'react-router-dom';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useTranslation } from 'react-i18next';
import { useApp } from "../../context/app";
import Table from './Table';
import MultibancoIcon from './../../assets/icons/multibanco.svg';



const useStyles = makeStyles(Styles);


const CheckoutResume = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()
    
    const [data, setData] = useState(null)


 
    


    useEffect(() => {
        if (location?.state) {
            setData(location?.state)
        } else {
            navigate('/')
        }
      
      
    }, [location?.state])
    

    const renderPaymentInfo = () => {
        switch (data.paymentMethod) {
            case 'multibanco':
                return (
                    <div>
                        <div className={classes.boxMultibanco}>
                            <div>
                                <div className={classes.boxImageMultibanco}>
                                    <img src={MultibancoIcon} alt="" />
                                    <table className={classes.tableRefMultibanco}>
                                        <tbody><tr>
                                            <th>{t('entity')}:&nbsp;</th>
                                            <td>{data?.multibancoData.entity}</td>
                                        </tr>
                                            <tr>
                                                <th>{t('reference')}:&nbsp;</th>
                                                <td>{data?.multibancoData.reference}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('amount')}:&nbsp;</th>
                                                <td>{Number(data?.multibancoData.amount / 100).toFixed(2)}</td>
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                )
            
            case 'credit_card':

                return <h5>{t('credit card')}</h5>
        
            default:
                break;
        }
    }

    
    if(!data) return null


    return (
        <Container className={classes.root} maxWidth="xl" >
                <Card className={classes.card}>
                    <div className={classes.container}>
                        <h2 className={classes.title}>
                            {t('here are your order resume')}
                        </h2>
                    
                        <Table cart={data.cart} />
                    
                        <div className={classes.infoTotalWrapper}>
                            <div className={classes.infoTotalWrapperInner}>
                                <div className={classes.subTotalBox}>
                                    <h3>{t('subtotal')}:&nbsp;</h3>
                                    <h3>{data?.totalPriceInCart}&euro;</h3>
                                </div>
                                <div className={classes.shipBox}>
                                <h6>{t('shipping')}:&nbsp;</h6>
                                    {data?.freeShip ? <h6>{t('free')}</h6> : <h6>{data?.settings?.shippingCost}&euro;</h6>}
                                </div>
                                <div className={classes.totalBox}>
                                    <h2>{t('total')}:&nbsp;</h2>
                                    {!data?.freeShip ? (
                                        <h2>{(Number(data?.totalPriceInCart) + Number(data?.settings?.shippingCost)).toFixed(2)}&euro;</h2>
                                    ) : (
                                        <h2>{Number(data?.totalPriceInCart)}&euro;</h2>
                                    )}

                                </div>
                            </div>
                        </div>
                    
                        <div className={classes.infoPaymentAndAdresses}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4} lg={4} XL={4}>
                                    <div className={classes.addressWrapper}>
                                        <h3>{t('billing')}</h3>
                                        <div className={classes.addressContent}>
                                            <h5>{data?.billingAddress.name}</h5>
                                            <h5>{data?.billingAddress.line1}</h5>
                                            <h5>{data?.billingAddress.cep},&nbsp;{data?.billingAddress.city},&nbsp;{data?.billingAddress.country}</h5>
                                            <h5>{t('phone')}:&nbsp;{data?.billingAddress.phone}</h5>
                                            <h5>{t('nif')}:&nbsp;{data?.billingAddress.nif}</h5>
                                        </div>
                                    </div>
                                        
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} XL={4}>
                                <div className={classes.addressWrapper}>
                                    <h3>{t('address shipping')}</h3>
                                    <div className={classes.addressContent}>
                                        <h5>{data?.deliveryAddress.name}</h5>
                                        <h5>{data?.deliveryAddress.line1}</h5>
                                        <h5>{data?.deliveryAddress.postal_code},&nbsp;{data?.deliveryAddress.city},&nbsp;{data?.deliveryAddress.country}</h5>
                                        <h5>{t('phone')}:&nbsp;{data?.deliveryAddress.phone}</h5>
                                    </div>
                                </div>
                                </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} XL={4}>
                                    <h3>{t('payment method')}</h3>
                                    <div className={classes.paymentInfoWrapper}>
                                        {renderPaymentInfo()}
                                    </div>
                                </Grid>
                            </Grid>
                        
                        </div>

                    </div>
                </Card>
        </Container>
    )
}

export default CheckoutResume

