import Palette from '../palette';

const MuiButton = {
    root: {
        width: '100%',
        height: '100%',
        borderRadius: 6,
        textTransform: 'none',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.07)',
        backgroundColor: Palette.white
    },
    label: {
        fontSize: '16px',
        lineHeight: 1.38
    }
};

export default MuiButton;
