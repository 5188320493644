import React from 'react';

import { useAuth } from '../context/auth';

import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';

const Routes = () => {


    const { signed, loading } = useAuth();


    if (loading) {
        return <div>Loading....</div>
    }

    return signed ? <AppRoutes /> : <AuthRoutes />;
};

export default Routes;