import React from 'react';
import { useNavigate } from 'react-router-dom';
import Styles from './style';
import { makeStyles, Drawer, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CardItem from './CardItem';
import { useTranslation } from 'react-i18next';
import { useApp } from "../../context/app";



const useStyles = makeStyles(Styles);

const DrawerCart = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setOpenCartDrawer, cart, totalInCart, totalPriceInCart, freeShip, remainingFreeShip } = useApp()



    const onCartClickHandler = () => {
        setOpenCartDrawer(false)
        navigate('/carinho')
    }



    return (
        <div className={classes.root}>
            <Drawer anchor={'right'} open={true} className={classes.drawer} >
                
                <div className={classes.cartWrapper}>
                    <div className={classes.closeBox} onClick={() => setOpenCartDrawer(false)}>
                        <IconButton aria-label="delete" size="small" disableFocusRipple={true} disableRipple={true} >
                            <CloseIcon />
                        </IconButton>
                    </div>
                   
                    <div className={classes.header}>
                        <h2>{t('cart')}</h2>
                        <h4>
                            {t('total')}&nbsp;{`( ${totalInCart} ${t('item')} )`}&nbsp;
                            <span>{totalPriceInCart}&euro;</span>
                        </h4>
                        {totalInCart > 0 && (
                            <h5>
                                {freeShip ? t('free ship') : `${t('remaining')} ${remainingFreeShip}€ ${t('more for free ship')}`}

                            </h5>
                        )}
                       
                    </div>
                    <div className={classes.content}>
                        {cart?.map(item => (
                            <CardItem item={item} />
                        ))}
                    </div>
                    {totalInCart > 0 && (
                        <div className={classes.footer}>
                            <Button onClick={() => onCartClickHandler()}
                                className={classes.buttonSubmit}
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary">
                                {t('see and edit cart')}
                            </Button>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>

    )
}


export default DrawerCart



