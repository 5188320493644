import React, { useEffect, useState } from 'react';
import Styles from './style';
import { makeStyles, Container, Card, Grid, IconButton } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import * as ApiService from '../../service/api';
import * as UtilService from '../../service/utils';

import ProductCard from '../../components/ProductCard';
import Loading from '../../components/Loading';

import OrderFilter from './OrderFilter'
import CategoriesFilter from './CategoriesFilter';
import { useApp } from '../../context/app';
import { useLocation, useNavigate } from 'react-router';




const useStyles = makeStyles(Styles);

const Products = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate()
    const [anchorElOrder, setAnchorElOrder] = useState(null);
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([]);
    const [bestSellersProducts, setBesSellersProducts] = useState([])
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [order, setOrder] = useState(orders[0]);
    const [variantsSelected, setVariantsSelected] = useState([]);
    const [rangePriceSelect, setRangePriceSelected] = useState([0, 1500]);

    const { setAppLoading} = useApp()




    const handleOpenMenuOrder = (event) => {
        setAnchorElOrder(event.currentTarget);
    }

    const onSelectCategoriesHandler = (data) => {
        setVariantsSelected(data)
    }

    const onSelectRangePriceHandler = (data) => {
        setRangePriceSelected(data)
    }



    useEffect(() => {
        const fetchProducts = async () => {
            setAppLoading(true)
            const locationState = location?.state
            const searchQuery = locationState?.query
            const _variant = locationState?.variant
            const _reference = locationState?.reference
            try {
                let params = {
                    perPage: 18,
                    page: currentPage,
                    sort: order?.key,
                    priceLow: rangePriceSelect[0],
                    priceHigh: rangePriceSelect[1]
                };
                if (variantsSelected?.length) {
                    params['categories'] = variantsSelected.join(',')
                }

                if (searchQuery) {
                    params['query'] = searchQuery
                }

                if (_variant) {
                     const variants = [_variant?._id]
                      params['categories'] = variants.join(',')
                }

                if (_reference) {
                    const variants = [_reference]
                    params['categories'] = variants.join(',')
                }


                const resp = await ApiService.getAllProducts(params);
                let _products = resp.data.content.results;
                let _currentPage = resp.data.content?.currentPage;
                let _totalPages = resp.data.content?.totalPages;
                let _totalProducts = resp.data.content?.numberOfResults;
                _products = UtilService.processProducts(_products)
                setProducts(_products)
                setTotalProducts(_totalProducts)
                setTotalPage(_totalPages)
                setCurrentPage(_currentPage)
                setAppLoading(false)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            } catch (error) {
                setAppLoading(false)

            }
        }

        fetchProducts()
    }, [order, variantsSelected, rangePriceSelect, currentPage, location?.state])


    useEffect(() => {
        const getBestSellersProducts = async () => {
            try {
                const params = { sort: 'bought', order: -1, limit: 3, page: 0, perPage: 8 };
                const resp = await ApiService.getAllProducts(params);
                let _products = resp.data.content.results;
                _products = UtilService.processProducts(_products)
                setBesSellersProducts(_products);
            } catch (error) {
                //
            }
        }

        getBestSellersProducts()
    }, [])

   

    return (
        <Container className={classes.root} maxWidth="xl" >
            <div className={classes.header}>
                <div className={classes.boxTitle}>
                    <h1>{t('all4health products')}</h1>
                    <h2>{t('all4health products subtitle')}</h2>
                </div>
            </div>
            {loading ? (
                <Card className={classes.card}>
                    <Grid container spacing={1}  >
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className={classes.categoriesBox} >
                                
                                <CategoriesFilter
                                    variant={location?.state?.variant?._id}
                                    onSelectCategories={onSelectCategoriesHandler}
                                    onSelectPrice={onSelectRangePriceHandler}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                            <div className={classes.productsHeader} >
                                <div className={classes.orderBox} >
                                    <h5>{t(order?.label)}</h5>
                                    <IconButton
                                        id="user-menu"
                                        onClick={handleOpenMenuOrder}
                                        className={classes.userButton}
                                        color="inherit">
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={classes.titleInfoBox}>
                                <h2>{t('all products')}</h2>
                                <h4>
                                    {totalProducts}&nbsp;
                                    <span>{t('products')}</span>
                                </h4>        
                            </div>
                            
                            <div className={classes.containerProducts}>
                                {products.map(product => (
                                    <ProductCard key={product._id} product={product} />
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.paginationBox}>
                                <Pagination
                                    className={classes.pagination}
                                    count={totalPage}
                                    onChange={(event, page) => setCurrentPage(page)} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.bestSellersWrapper}>
                                <h3 className={classes.bestSellersTitle} >{t('best sellers')}</h3>
                                <div className={classes.containerBestSellers}>
                                    {bestSellersProducts.map(product => (
                                        <ProductCard key={product._id} product={product} />
                                    ))}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            ) : <Loading />}

            <OrderFilter
                orders={orders}
                anchorEl={anchorElOrder}
                onSelect={order => setOrder(order)}
                close={() => setAnchorElOrder(null)} />
            
        </Container>
    )
}

export default Products


const orders = [
    { key: 'createdAt', label: 'more recent' },
    { key: 'featured', label: 'featured' },
    { key: 'views', label: 'popularity' },
    { key: 'bought', label: 'bestsellers' },
    { key: 'price', label: 'price' }
]

