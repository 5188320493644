const Styles = theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        },
    },
    formBox: {
        margin: '0 20px',
        width: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            margin: '40px 20px 5px',
        },
    },
    creditCard: {
        backgroundColor: 'red'
    }
})

export default Styles;