
const Style =  theme => ({
    list: {
        maxHeight: '100%',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    listChildren: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    isBlog: {
        display: 'flex',
        flexDirection: 'column'
    }
})

export default Style;