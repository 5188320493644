
const Styles = theme => ({
    root: {
        width: '100%',
        padding: '10px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px',
        }
    },
    requiredInfo: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    submitPaper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '30px 0',
        '& .MuiButton-contained': {
            width: '70%'
        },
        '& span.requiredInfo': {
            marginTop: '15px'
        },
        '& .forgotButton': {
            fontSize: '12px',
            margin: '15px 0',
            color: '#999',
            cursor: 'pointer',
            '&:hover': {
                color: '#333'
            }
        },
        '& .registerButton': {
            marginTop: '30px',
            '& span': {
                cursor: 'pointer',
                '&:hover': {
                    textDecoration: 'underline'
                }
            }
        }
    },
    dividerArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',        
        width: '100%',
        marginBottom: '20px',
        '& .spliter': {
            width: '100%',
            height: '1px',
            background: '#ddd',
            margin: '15px'
        }
    },
    buttonFacebook: {
        backgroundColor: '#3C63B5',
        color: 'white',
        width: '70%',
    },
    formControl: {
        margin: '15px auto'
    },
    buttonSubmit: {
        backgroundColor: '#e91e63',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)',
            backgroundColor: '#e91e63',
        }
    },
    termsPaper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '24px 0 0',
        flexDirection: 'column',
        '& span': {
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        '& .MuiFormControlLabel-root': {
            margin: 0
        }
    },
    paperOtherInfo: {
        '& .spliter': {
            width: '100%',
            height: '1px',
            background: '#ddd'
        },
        '& .info': {
            whiteSpace: 'pre-line',
            padding: '30px 0',
            textAlign: 'center',
            '& span': {
                cursor: 'pointer',
                fontWeight: '800',
                '&:hover': {
                    textDecoration: 'underline'
                }
            }
        }
    },
    facebookPaper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0'
    },
})

export default Styles;