import React  from 'react'
import Styles from './styles';
import { useFormik } from 'formik';
import FacebookLogin from 'react-facebook-login';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { makeStyles, Grid, Dialog, DialogContent, DialogTitle, FormControl, TextField,  Paper, Container} from '@material-ui/core';
import { useApp } from "../../context/app";
import { useAuth } from "../../context/auth";
import SubmitButton from '../../components/SubmitButton';



const APP_ID_FACEBOOK = "1753619581467093"

const useStyles = makeStyles(Styles);




const PopupLogin = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { openLogin, setOpenLogin, setOpenSignup, showNotifications, setOpenRecoverPassword } = useApp()
    const { signIn, signInFacebook } = useAuth()


    const formik = useFormik({
            initialValues: {
                email: '',
                password: '',
                remember: false
            },
            validationSchema: Yup.object().shape({
                email: Yup.string()
                    .email(t('invalid email')),
                password: Yup.string("")
                    .min(2, t("password must contain at least 2 characters"))
            }),
        onSubmit: async (values, { setErrors }) => {
                    try {
                        const postData = {
                            email: values?.email.toLowerCase(),
                            password: values.password
                        }
                        await signIn(postData)
                        setOpenLogin(false)
                    } catch (error) {
                        const msg = error
                        const notf = { type: 'error', title: t('login'), message: msg }
                        showNotifications(notf)
                        
                    }
                }
            }
    )


    const forgotPasswordhandler = () => {
        setOpenRecoverPassword(true)
        //setOpenLogin(false)
    }

  
    const responseFacebook = async (response) => {
        try {
            const name = response.name.split(' ')
            const postData = {
                token: response.accessToken,
                user: {
                    firstName: name[0],
                    lastName: name[1],
                    email: response.email
                }
            };
            signInFacebook(postData)
            setOpenLogin(false)
        } catch (error) {
            
        }
    }

    const onClickRegisterHandler = () => {
        setOpenLogin(false)
        setOpenSignup(true)
        
    }


    return (

        <Dialog
            maxWidth="md"
            disableBackdropClick={false}
            onClose={() => setOpenLogin(false)}
            open={openLogin}
            aria-labelledby="popup-info-title"
            aria-describedby="popup-info-description"
        >
            <DialogTitle id="popup-info-title" className={classes.title}>
                {t('login')}
            </DialogTitle>
            <DialogContent>
                <Container className={classes.root} maxWidth="sm">
                <form onSubmit={formik.handleSubmit}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="email"
                                placeholder={`${t('email')} *`}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.email && formik.touched.email}
                                helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="password"
                                type="password"
                                placeholder={`${t('password')} *`}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.password && formik.touched.password}
                                helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid container alignItems="center" alignContent="center">
                        <Grid xs>
                            <span className={classes.requiredInfo}>
                                {`* ${t('required fields')}`}
                            </span>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.submitPaper} elevation={0}>
                                <SubmitButton className={classes.buttonSubmit}
                                    isSubmitting={formik.isSubmitting}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary">
                                    {t('sign in')}
                                </SubmitButton>

                            <div className="forgotButton"
                                onClick={() => forgotPasswordhandler()}>
                                {`${t('forgot your password')} ?`}
                            </div>
                                
                            <div className={classes.dividerArea}>
                                <div className="spliter" />
                                <div className="spliterMiddle">
                                    {t('or')}
                                </div>
                                <div className="spliter" />
                            </div>
                    
                            <FacebookLogin
                                style={{ borderRadius: '6px' }}
                                appId={APP_ID_FACEBOOK}
                                autoLoad={false}
                                fields="name,email"
                                callback={responseFacebook}
                                textButton={t('sign in with facebook')}
                                />
                                
                                <div className="registerButton">
                                    {`${t('register here info')}`} &nbsp;
                                    <span onClick={() => onClickRegisterHandler()}>
                                        {t('here')}
                                    </span>
                                </div>
                        </Paper>
                    </Grid>
                </form >
                </Container>
            </DialogContent>
        </Dialog>
       
    )
}


export default  PopupLogin;
 