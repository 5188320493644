import React from 'react';
import Styles from './styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";


const API_URL = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles(Styles);


const PostBlog = ({
    post
}) => {

    const classes = useStyles();

    const { i18n } = useTranslation();
    const navigate = useNavigate();


    

    const onClickPostHandler = () => {
        navigate(`blog/post/${post.slug}`);
    }

    return (
        post ?
            <div className = {classes.root} onClick={() => onClickPostHandler()} >
                <div className={classes.postDetail}>
                    <div className={classes.tagArea}>
                        <div className={classes.tag}>
                            <span style={{ backgroundColor: 'red' }} ></span>
                            <span style={{ textTransform: 'uppercase' }}>{post.category[`title_${i18n.language}`]}</span>
                        </div>
                    </div>
                    <div className={classes.titleArea}>
                        <h2>{post[`title_${i18n.language}`]}</h2>
                    </div>
                </div>
                <div className={classes.overlayImage}>
                    {post.thumbnail ? (
                        <LazyLoadImage src={`${API_URL}/warehouse/${post.thumbnail?.filename}`} alt=""  effect="blur"/>
                    ) : null}
                    
                </div>
            </div >
        : null
    );
}

export default PostBlog

