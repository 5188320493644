import MuiTextField from './MuiTextField';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiButton from './MuiButton';
import MuiToolbar from './MuiToolbar';
import MuiTab from './MuiTab';
import MuiInput from './MuiInput';
import MuiDivider from './MuiDivider';
import MuiDrawer from './MuiDrawer';
import MuiAppBar from './MuiAppBar';
import MuiDialog from './MuiDialog';


const overrides =  {
    MuiTextField,
    MuiOutlinedInput,
    MuiButton,
    MuiToolbar,
    MuiTab,
    MuiInput,
    MuiDivider,
    MuiDrawer,
    MuiAppBar,
    MuiDialog
}

export default overrides;