import axios from 'axios';
import { getToken } from '../storage';

const API_URL = process.env.REACT_APP_DOMAIN;
//const API_URL = 'https://localhost:8443'


export const AxiosInstance = axios.create({
    baseURL: API_URL
});

export const AxiosInstanceAuth = axios.create({
    baseURL: API_URL
});

AxiosInstance.defaults.timeout = 20000;


/*

AxiosInstance.interceptors.request.use(async request => {
    const token = await getToken();
    if (token) {
        request.headers['Authorization'] = `Bearer ${token}`
    }
    request.url = `${request.baseURL}/${request.url}`
    return request;
},
    (error) => {
        return Promise.reject(error);
    });


AxiosInstance.interceptors.response.use((response) => {
    return response;
},
    (error) => {
        const statusCode = error.statusCode;
        switch (statusCode) {
            case 'REFRESH_TOKEN':
                break;
            default:
                break
        }
        return Promise.reject(error);
    });

    */

