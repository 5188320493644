import React from 'react';
import Slider from "react-slick";
import Styles from './style';
import { makeStyles, Card, CardContent } from '@material-ui/core';
import PostBlog from '../PostBlog';




const useStyles = makeStyles(Styles);


const CarrouselBlog = ({
    posts = []
}) => {

    const classes = useStyles();

    



    const SLIDER_SETTINGS = {
        dots: false,
        infinite: true,
        slidesToShow: posts.length < 4 ? posts.length : 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 10000,
        responsive: [
            {
                breakpoint: 1921,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: posts.length < 4 ? posts.length : 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1900,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: posts.length < 4 ? posts.length : 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1628,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: posts.length < 4 ? posts.length : 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1317,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: posts.length < 4 ? posts.length : 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1217,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: posts.length < 4 ? posts.length : 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: posts.length < 4 ? posts.length : 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: posts.length < 4 ? posts.length : 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 697,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };


    return (
        <Card className={classes.card}>
            {posts.length ?
                <CardContent className={classes.cardContent}>
                    <Slider {...SLIDER_SETTINGS}>
                        {posts.map((post) =>
                            <div className={classes.cardPostContainner}>
                                <PostBlog post={post}/>
                            </div>
                        )}
                    </Slider>
                </CardContent>
                : null}
        </Card>
    )
}




export default CarrouselBlog

