import palette from './palette';





const typography =  {
    h1: {
        fontFamily: 'inherit',
        color: palette.text.primary,
        fontWeight: 700,
        fontSize: '29px',
        letterSpacing: '-0.24px',
        lineHeight: '50px'
    },
    h2: {
        fontFamily: 'inherit',
        color: palette.text.primary,
        fontWeight: 600,
        fontSize: '23px',
        letterSpacing: '-0.24px',
        lineHeight: '42px'
    },
    h3: {
        fontFamily: 'inherit',
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '20px',
        letterSpacing: '-0.06px',
        lineHeight: '32px'
    },
    h4: {
        fontFamily: 'inherit',
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '18px',
        letterSpacing: '-0.06px',
        lineHeight: '24px'
    },
    h5: {
        fontFamily: 'inherit',
        color: palette.text.primary,
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: '-0.05px',
        lineHeight: '20px'
    },
    h6: {
        fontFamily: 'inherit',
        color: palette.darkgray,
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '-0.05px',
        lineHeight: '20px'
    },
    subtitle1: {
        fontFamily: 'inherit',
        color: palette.text.primary,
        fontSize: '14px',
        letterSpacing: '-0.05px',
        lineHeight: '25px'
    },
    subtitle2: {
        fontFamily: 'inherit',
        color: palette.text.secondary,
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: '-0.05px',
        lineHeight: '21px'
    },
    body1: {
        fontFamily: 'inherit',
        color: palette.black,
        fontSize: '12px',
        letterSpacing: '-0.05px',
        lineHeight: '21px'
    },
    body2: {
        fontFamily: 'inherit',
        color: palette.text.secondary,
        fontSize: '11px',
        letterSpacing: '-0.04px',
        lineHeight: '18px'
    },
    button: {
        fontFamily: 'inherit',
        color: palette.text.primary,
        fontSize: '12px'
    },
    caption: {
        fontFamily: 'inherit',
        color: palette.text.secondary,
        fontSize: '11px',
        letterSpacing: '0.33px',
        lineHeight: '13px'
    },
    overline: {
        fontFamily: 'inherit',
        color: palette.text.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase'
    }
};

export default typography;
