const MuiOutlinedInput = {
    root: {
        height: '50px',
        borderRadius: '10px',
    },
    notchedOutline: {
        borderColor: "#EEEEEE",
    },
    input: {
        color: '#474747'
    }
};

export default MuiOutlinedInput;
