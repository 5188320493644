import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const ScrollReset = () => {

    const location = useLocation();
    

    useEffect(() => {
        const timer = setTimeout(() => {
             window.scrollTo({ top: 0})
        }, 50);
        return () => {
            clearTimeout(timer)
        }
    }, [location]);

    return null;
    
};

export default ScrollReset;
