
const Styles = theme => ({
    root: {
        position: 'relative',
        width: '480px',
        height: '340px',
        margin: '20px',
        display: 'flex',
        borderRadius: '6px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('sm')]: {
            width: '250px',
            height: '210px',
        }
    },
    content: {
        zIndex: 4,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '30px 30px 20px',
        borderRadius: '6px',
        justifyContent: 'center'
    },
    boxVariant: {
        zIndex: 4,
        position: 'absolute',
        top: 0,
        marginTop: '42px',
        '& p': {
            color: 'white',
              fontWeight: 800,
              fontSize: '14px',
              fontStyle: 'italic'
        }
    },
    boxTitle: {
        zIndex: 4,
        '& h2': {
            fontWeight: 800,
            lineHeight: '38px !important',
            color: 'white',
            display: 'box',
            lineClamp: 3,
            boxOrient: 'vertical',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem !important',
                lineHeight: '18px !important',
            }
        }
    },
    boxDescription: {
        zIndex: 4,
        padding: '10px 0',
        height: '100px',
        overflow: 'hidden',
        '& p': {
            display: 'box',
            lineClamp: 3,
            boxOrient: 'vertical',
            overflow: 'hidden',
            fontSize: '14px',
            color: 'white'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    boxButton: {
        zIndex: 4,
        position: 'absolute',
        bottom: 0,
        marginBottom: '30px'
    },
    button: {
        backgroundColor: '#f44336',
        color: 'white',
        borderRadius: '20px',
        padding: '10px 30px',
        '& .MuiButton-label': {
            textTransform: 'uppercase'
        },
        '&:hover': {
            backgroundColor: '#f44336',  
            boxShadow: '0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)' 
        }
    },
    boxImage: {
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: '6px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '6px'
        }
    }
})

export default Styles