import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import {
    makeStyles,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    Container,
    Select,
    InputLabel
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Styles from './styles';
import { useTranslation } from 'react-i18next';
import SubmitButton from './../SubmitButton';
import * as ApiService from './../../service/api';


const SUBJECTS = [
    {
        group: "Pedido de Informações",
        options: [
            "Pedido de Informação", "Pedido de informação sobre reembolso"
        ]
    },
    {
        group: "Elogios, sugestões e reclamações",
        options: [
            "Elogio", "Sugestão", "Reclamação"
        ]
    },
    {
        group: "Trocas e Devoluções",
        options: [
            "Troca", "Devolução"
        ]
    }
]


const useStyles = makeStyles(Styles);



const Contact = () => {

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [contactInfo, setContactInfo] = useState({})
    const [notRobotCheck, setNotRobotCheck] = useState(false)

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        },
        onSubmit: async (values) => {
            try {
                const postData = {
                    name: `${values.firstName} ${values.lastName}`,
                    email: values.email,
                    message: values.message,
                    subject: values.subject
                }
                await ApiService.updateContacts(postData)
                formik.resetForm()
            } catch (error) {
                formik.resetForm()
            }
        }
    })


    useEffect(() => {
        const getContactInfo = async () => {
            try {
                const resp = await ApiService.getContactInfo()
                setContactInfo(resp.data.content)
            } catch (error) {}
        }
        getContactInfo()
    }, [])




    const handleChangeNotRobot = event => {
        setNotRobotCheck(event.target.checked )
    }

    if (!contactInfo) return null
    

    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                    <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <div className={classes.gridRootInfo}>
                            <h1>{contactInfo[`title_${i18n.language}`]}</h1>
                            <h3>{contactInfo[`description_${i18n.language}`]}</h3>
                            <div className={classes.locationBox}>
                                <LocationOnIcon />
                                <div className="info">
                                    <h2>{t('where we are located')}</h2>
                                    <div>{contactInfo['address']}</div>
                                </div>
                            </div>
                            <div className={classes.contactBox}>
                                <PhoneIcon />
                                <div className="info">
                                    <h2>{t('give us a ring')}</h2>
                                    <div>{contactInfo['telephone']}</div>
                                </div>
                            </div>
                        </div>
                        </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className={classes.gridRootForm}>
                                <div className={classes.formHeader}>
                                    <h2>{t('contact us')}</h2>
                                </div>
                                <div className={classes.formContainer}>
                                <form className={classes.form} onSubmit={formik.handleSubmit}>
                                    
                                    <FormControl fullWidth>
                                        <TextField
                                            id="firstName"
                                            placeholder={`${t('first name')}`}
                                            margin="dense"
                                            variant= 'outlined'
                                            fullWidth
                                            autoComplete="off"
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="lastName"
                                            placeholder={`${t('last name')}`}
                                            variant='outlined'
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="email"
                                            placeholder={`${t('email')}`}
                                            variant='outlined'
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                        />
                                    </FormControl>

                                    <FormControl
                                        style={{marginTop: '20px'}}
                                        className={classes.formControl}
                                        fullWidth
                                        margin="dense">
                                        <InputLabel htmlFor="subject-select">{t('subject')}</InputLabel>
                                        <Select
                                            id="subject"
                                            fullWidth
                                            native
                                            label={t('subject')}
                                            onChange={formik.handleChange('subject')}
                                            onBlur={formik.handleBlur('subject')}
                                            inputProps={{
                                                name: 'Subject',
                                                id: 'subject-select',
                                            }}
                                            required
                                        >
                                            <option aria-label="None" value="Geral" />
                                            {SUBJECTS.map(subject =>
                                                <optgroup label={subject.group}>
                                                    {subject.options.map(option =>
                                                        <option value={option}>{option}</option>
                                                    )}
                                                </optgroup>
                                            )}
                                        </Select>
                                    </FormControl>
                            
                                    <FormControl fullWidth>
                                        <TextField
                                            id="message"
                                            placeholder={`${t('message')}`}
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            multiline
                                            rows={5}
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                        />
                                    </FormControl>
                                    <Grid container justify="space-between" spacing={4} className={classes.gridContainerSubmit}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox className={classes.checkBox} checked={notRobotCheck} onChange={handleChangeNotRobot} name="notRobot" />}
                                                label={t('not robot')}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <SubmitButton
                                                variant="contained"
                                                className={classes.buttonSubmit}
                                                size="large"
                                                disabled={!notRobotCheck}
                                                isSubmiting={formik.isSubmitting}
                                            >
                                                {t('send message')}
                                            </SubmitButton>

                                        </Grid>
                                    </Grid>
                                    
                                    </form>
                                </div>
                            </div>
                        </Grid>
                </Grid>
            </Container>
        </div>
    )
}




export default Contact
