import backgroundImage from './../../assets/images/prefooter-background.jpg';


const Styles =  theme => ({
    root: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        background: `url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover',
        paddingTop: '40px'
    },
    gridRootInfo: {
        color: 'white',
        '& svg': {
            width: '2.25rem',
            height: '2.25rem',
            fontSize: '2.25rem'
        },
        '& h1': {
            marginTop: '30px',
            minHeight: '32px',
            fontWeight: 700,
            marginBottom: '25px',
            textDecoration: 'none'
        },
        '& h3': {
            color: 'rgba(255, 255, 255, 0.76)',
            marginBottom: '50px'
        },
    },
    contactBox: {
        display: 'flex',
        marginTop: '24px',
        '& .info': {
            marginLeft: '10px',
            fontSize: '13px'
        }
    },
    locationBox: {
        display: 'flex',
        marginTop: '24px',
        '& .info': {
            marginLeft: '10px'
        }
    },
    gridRootForm: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
    },
    formHeader: {
        color: '#FFF',
        background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
        boxShadow: '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
        position: 'absolute',
        margin: '0 15px',
        padding: '1rem 15px',
        marginTop: '0px',
        width: 'fill-available',
        textAlign: 'center',
        borderRadius: '4px',
        '& h2': {
            fontWeight: 700,
            margin: '0.75rem 0' 
        }
    },
    formContainer: {
        backgroundColor: '#FFF',
        width: '100%',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px - 2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
        margin: '30px 0',
        borderRadius: '6px'
    },
    form: {
       padding: '80px 30px 30px'
    },
    gridContainerSubmit: {
        marginTop: '20px',
        padding: '0px 15px 15px'
    },
    buttonSubmit: {
        color: 'white',
        boxShadow: '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)',
        backgroundColor: '#9c27b0',
        '&:hover': {
            boxShadow: '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)',
            backgroundColor: '#9c27b0',
        }
    },
    checkBox: {
            color: '#e91e63 !important',
        
    }
})

export default Styles;