import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import { makeStyles, Container } from '@material-ui/core';
import Loading from '../../components/Loading';
import * as ApiService from './../../service/api';


const useStyles = makeStyles(Styles);


const API_URL = process.env.REACT_APP_DOMAIN;


const FooterPage = () => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const urlParams = new URLSearchParams(useLocation().search);
    const type = urlParams.get('type');


    useEffect(() => {
        const getPage = async (id) => {
            try {
                setLoading(true)
                const resp = await ApiService.getPage(type);
                setData(resp.data.content);
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        getPage()
    }, [ type])

    if (loading) return <Loading />
        
    if(!data) return null


    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <img src={`${API_URL}/warehouse/${data?.image?.filename}`} alt="thumb" />
                <h1>{data[`title_${i18n.language}`]}</h1>
                <div className={classes.backgroundBoxOverlay} />
            </div>
            <Container maxWidth="lg" className={classes.container}>
                <div dangerouslySetInnerHTML={{ __html: data[`text_${i18n.language}`]  }} />
            </Container>
        </div>
    )
}

export default FooterPage;
