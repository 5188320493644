import backgroundImage from './../../assets/images/blog-background.jpg';

const Styles = theme => ({
    root: {
        padding: '0',
        marginTop: '0',
        backgroundColor: '#F7F9FC',
        width: '100%',
        maxWidth: '100%'
    },
    header: {
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '320px',
        background: `url(${backgroundImage}) no-repeat center center`,
        backgroundPosition: '50%',
        backgroundSize: 'cover',
        '& h1': {
            minHeight: '32px',
            fontWeight: 700,
            textShadow: '2px 2px 4px #000000'
        },
        '& h4': {
            minHeight: '32px',
            fontWeight: 700,
            textShadow: '2px 2px 4px #000000'
        },
        [theme.breakpoints.down('sm')]: {
            height: '220px',
            '& h1': {
                
            },
            '& h2': {
                
            },
        },
    },
    card: {
        marginTop: '-30px',
        margin: '0 30px',
        borderRadius: '6px',
        backgroundColor: 'white',
        boxShadow: '0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)',
    },
    cardHeader: {
        paddingTop: '30px'
    },

    lastPostWrapper: {
        padding: '0 5px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        margin: '0px 0',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '30px',
    },
    lastPostContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    allPostWrapper: {
        padding: '0 5px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        margin: '0px 0',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '30px',
    },
    allPostContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    featuredProductContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
    }
})

export default Styles;