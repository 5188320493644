import React from 'react';
import Styles from './styles';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(Styles);


const NutritionalTableDetail = ({
    nutrition
}) => {

    const classes = useStyles();

    if (!nutrition) {
        return null
    }

    return (
        <div
            className={classes.root} 
            >
            <div className={classes.detail}>
                {nutrition.map(it => 
                    <div className={classes.line} key={it.nutrient}>
                        <span>{it.nutrient}</span>
                        <span>{it.dose}</span>
                    </div>
                )}
            </div>
            <div className={classes.rules}>
              
            </div>
        </div>
    )
}
export default NutritionalTableDetail