import React, { useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import { Field, Formik  } from 'formik';
import countries from "i18n-iso-countries";
import SubmitButton from './../../../../components/SubmitButton';
import { makeStyles,  Grid,  TextField,  FormControl,  Card,  CardContent,  CardActions,  FormControlLabel,  Radio,  Paper,  InputLabel,  Select } from '@material-ui/core'
import { RadioGroup } from 'formik-material-ui'
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import * as apiService from '../../../../service/api';
import { useAuth } from '../../../../context/auth';
import { useApp } from '../../../../context/app';


import moment from 'moment';


const useStyles = makeStyles(Styles);


const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    birthDate: null,
    nif: '',
    phone: '',
    shippingPhone: '',
    gender: '',
    address: {
        line1: '',
        line2: '',
        cep: '',
        city: '',
        state: '',
        country: ''
    },
    shippingAddress: {
        line1: '',
        line2: '',
        city: '',
        cep: '',
        state: '',
        country: ''
    }
}

const AccountData = () => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [allCountries, setAllCountries] = useState([])
    const [userInfo, setUserInfo] = useState(null)
    const [userDataForm, setUserDataForm] = useState(null)
    const { user } = useAuth()
    const { showNotifications } = useApp()




    useEffect(() => {   
        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
        let _countries = Object.entries(countries.getNames(i18n.language))
        _countries = _countries.filter(it => it[0] === 'PT')
        setAllCountries(_countries)
        const fetchUserData = async () => {
            try {
                const userId = user?._id
                const resp = await apiService.getUser(userId)
                setUserInfo(resp.data.content)
                setUserDataForm(
                    {
                        ...userDataForm,
                        ...getFormData(resp.data.content),
                        birthDate: resp.data.content?.birthDate ? moment(resp.data.content.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
                        nif: String(resp.data.content.nif ? resp.data.content.nif : '')
                    })
               
           } catch (error) {
               console.log(error)
           }
        }
        fetchUserData()
    }, [])


  

    return (
        <Formik
            enableReinitialize={true}
            initialValues={userDataForm ? { ...userDataForm } : initialValues}
            onSubmit={async (values) => {
                const postData = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password,
                    nif: values.nif,
                    gender: values.gender,
                    shippingAddress: values.shippingAddress,
                    address: values.address,
                    phone: values.phone,
                    shippingPhone: values.shippingPhone,
                    birthDate: values.birthDate

                }
                try {
                    const resp = await apiService.setUserData(userInfo?._id, postData);
                    const userUpdated = resp.data.content
                    setUserInfo(userUpdated)
                    setUserDataForm(
                        {
                            ...userDataForm,
                            ...getFormData(resp.data.content),
                            birthDate: resp.data.content?.birthDate ? moment(resp.data.content.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
                            nif: String(resp.data.content.nif)

                        })
                    const notf = { type: 'success', title: t('set user data'), message: t('success set user data') }
                    showNotifications(notf)
                } catch (error) {
                    const notf = { type: 'error', title: t('set user data'), message: t('error set user data') }
                    showNotifications(notf)
                 }
            }}
        >
            
            {props => {
                return (
                    <div className={classes.root}>
                        <div className={classes.header}>
                            <AccountBoxIcon />
                        </div>
                        <div className={classes.titleBox}>
                            <h3>{t('account')}</h3>
                        </div>
                        <form onSubmit={props.handleSubmit}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Grid container direction="" spacing={1}>
                                        <Grid item xs={12} >
                                            <FormControl fullWidth className={classes.formControl}>
                                                <TextField
                                                    id="email"
                                                    placeholder={`${t('email')} *`}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    disabled
                                                    value={props.values.email}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="phone"
                                                    placeholder={t('phone')}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    autoComplete="off"
                                                    value={props.values.phone}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="firstName"
                                                            placeholder={`${t('first name')} *`}
                                                            variant="outlined"
                                                            margin="dense"
                                                            fullWidth
                                                            autoComplete="off"
                                                            value={props.values.firstName}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.errors.firstName && props.touched.firstName}
                                                            helperText={(props.errors.firstName && props.touched.firstName) && props.errors.firstName}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="lastName"
                                                            placeholder={`${t('last name')} *`}
                                                            variant="outlined"
                                                            margin="dense"
                                                            fullWidth
                                                            autoComplete="off"
                                                            value={props.values.lastName}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.errors.lastName && props.touched.lastName}
                                                            helperText={(props.errors.lastName && props.touched.lastName) && props.errors.lastName}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={2} alignItems="flex-end">
                                                <Grid item  xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <InputLabel>{t('birthday')}</InputLabel>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="birthDate"
                                                            type="date"
                                                            placeholder={`${t('date of birth')} *`}
                                                            variant="outlined"
                                                            margin="dense"
                                                            fullWidth
                                                            autoComplete="off"
                                                            value={props.values.birthDate}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.errors.birthDate && props.touched.birthDate}
                                                            helperText={(props.errors.birthDate && props.touched.birthDate) && props.errors.birthDate}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item  xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="nif"
                                                            placeholder={`${t('nif')} *`}
                                                            variant="outlined"
                                                            margin="dense"
                                                            fullWidth
                                                            autoComplete="off"
                                                            value={props.values.nif}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.errors.nif && props.touched.nif}
                                                            helperText={(props.errors.nif && props.touched.nif) && props.errors.nif}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl margin='dense' fullWidth>
                                                        <Field
                                                            className={classes.genderField}
                                                            row={true}
                                                            component={RadioGroup}
                                                            value={props.values.gender}
                                                            name="gender"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur('gender')}>
                                                            <FormControlLabel
                                                                key="M"
                                                                value="male"
                                                                control={<Radio />}
                                                                label={t('male')}
                                                            />
                                                            <FormControlLabel
                                                                key="F"
                                                                value="female"
                                                                control={<Radio />}
                                                                label={t('female')}
                                                            />
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paperAddressForm}>
                                                <h5>{t('billing address')}</h5>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="address.line1"
                                                                label={t('address')}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={props.values.address.line1}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="address.cep"
                                                                label={t('cep')}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={props.values.address.cep}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="address.city"
                                                                label={t('city')}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={props.values.address.city}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl variant="outlined" >
                                                            <InputLabel>{t('country')}</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.address.country}
                                                                onChange={props.handleChange('address.country')}
                                                                onBlur={props.handleBlur('address.country')}
                                                                label={t('country')}
                                                                inputProps={{
                                                                    name: t('country'),
                                                                    id: 'country',
                                                                }}
                                                            >
                                                                <option value={null} />
                                                                {allCountries.map(country =>
                                                                    <option value={country[1]}>{country[1]}</option>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paperBillingForm}>
                                                <h5>{t('delivery address')}</h5>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="shippingAddress.line1"
                                                                required
                                                                label={t('address')}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={props.values.shippingAddress.line1}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="shippingAddress.cep"
                                                                required
                                                                label={t('cep')}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={props.values.shippingAddress.cep}
                                                                onChange={props.handleChange}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="shippingAddress.city"
                                                                required
                                                                label={t('city')}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={props.values.shippingAddress.city}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="shippingPhone"
                                                                label={t('phone')}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                autoComplete="off"
                                                                value={props.values.shippingPhone}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl variant="outlined" >
                                                            <Select
                                                                native
                                                                value={props.values.shippingAddress.country}
                                                                onChange={props.handleChange('shippingAddress.country')}
                                                                onBlur={props.handleBlur('shippingAddress.country')}
                                                                inputProps={{
                                                                    name: t('country'),
                                                                    id: 'shippingAddress.country',
                                                                }}
                                                            >
                                                                <option value={null}>{t('country')}</option>
                                                                {allCountries.map(country =>
                                                                    <option value={country[1]}>{country[1]}</option>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions className={classes.cardAction}>
                                    <Grid item>
                                        <Grid item xs>
                                            <SubmitButton
                                                className={classes.buttonSubmit}
                                                type="submit"
                                                isSubmiting={props.isSubmitting}
                                                variant="contained"
                                                color="primary">
                                                {t('save changes')}
                                            </SubmitButton>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </form>
                    </div>
                )
            }}
        </Formik>
    )
}


export default AccountData



const getFormData = (data) => {
    let dataForm = {
        ...data
    }

    if (!dataForm.address) {
        dataForm['address'] = {
            line1: '',
            line2: '',
            cep: '',
            city: '',
            state: '',
            country: ''
        }
    }
    if (!dataForm.shippingAddress) {
        dataForm['shippingAddress'] = {
            line1: '',
            line2: '',
            city: '',
            cep: '',
            state: '',
            country: ''
        }
    }

    return dataForm;

}