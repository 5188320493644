import backgroundImage from './../../assets/images/nwsletter-background.jpg';


const Styles = theme => ({
    root: {
        marginTop: '50px',
        padding: '70px 0',
        background: `url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover',
        color: '#ffffff',
        textAlign: 'center'
    },
    form: {
        '& h2': {
            textShadow: '1px 1px 1px #FFF',
        },
        '& p': {
            fontSize: '18px'
        }
    },
    inputEmail: {
        marginBottom: '10px',
        '& .MuiInput-underline.Mui-error': {
            '&:after': {
                borderBottomColor: '#e91e63'
            }
        },
        '& .MuiInput-underline.Mui-focused': {
            '&:after': {
                borderBottomColor: '#e91e63'
            }
        }
    },
    formControl: {
        margin: '0px',
        '& svg': {
            color: '#495057'
        }
    },
    buttonNewsletter: {
        marginLeft: '30px',
        color: 'white',
        boxShadow: '0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px - 2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12)',
        backgroundColor: '#00acc1',
        '&:hover': {
            boxShadow: '0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px - 2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12)',
            backgroundColor: '#00acc1',
        }
    },
    boxInputForm: {
        display: 'flex',
        flexDirection:'column',
        backgroundColor: '#FFF',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        padding: '30px 20px',
        marginTop: '40px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& button': {
                margin: '20px 0px 0px',
            }
        },
    }
})

export default Styles;