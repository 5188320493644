const Styles = theme => ({
    drawer: {
        zIndex: 2001,
        '& .MuiDrawer-paper': {
            borderRadius: '0px !important',
            padding: 0,
            height: '100%',
            overflow: 'auto',
            boxShadow: 'none',
            backgroundColor: 'transparent'
        }
    },
    accordion: {
        '&.MuiAccordion-root.Mui-expanded': {
            margin: '0px 0px'
        },
        '& .MuiAccordionSummary-expandIcon': {
            color: '#e91e63'
        },
        '& .MuiAccordionSummary-content': {
            margin: '0px'
        }
    },
    accordionSummary: {
        maxHeight: '40px',
        margin: 0
    },
    accordionSummaryBoxTitle: {
        height: '100%',
        width: '100%',
        margin: '12px 0',
        '& h6': {
            margin: '5px 0px'
        }
    },
    variantList: {
        marginTop: '56px'
    }
})

export default Styles;