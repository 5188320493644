import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Styles from './style';
import { useApp } from "../../context/app";



const useStyles = makeStyles(Styles);

const Notification = ({}) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { openNotifications, closeNotification, notificationsData } = useApp()



    const onClose = (event, reason) => {
        if (reason === 'clickaway') {
            closeNotification(false)
        }
        closeNotification(false)
    }


    return (
        <Snackbar open={openNotifications} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} onClose={onClose}>
            <Alert severity={notificationsData.type} onClose={onClose} className={classes.root}>
                {notificationsData.title != null ? <AlertTitle><strong>{t(notificationsData.title)}</strong></AlertTitle> : null}
                {t(notificationsData.message)}
            </Alert>
        </Snackbar>
    )
}

export default Notification

