const Styles = theme => ({
    root: {
        zIndex: 0,
        cursor: 'pointer',
        margin: '20px auto',
        padding: '0px 0',
        background: 'rgba(40, 40, 40, 0.02)',
        [theme.breakpoints.down('md')]: {
            background: 'rgba(40, 40, 40, 0.02)',
        },
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        padding: '0',
        maxHeight: '380px',
        overflow: 'hidden',
        '& img': {
            width: '100%',
            objectFit: 'cover'
        }
        
    },
    gridForContent: {
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
    },
    contentMain: {
        margin: '0 20px',
        textAlign: 'right',
        zIndex: 2,
        fontSize: '38px',
        color: '#FFF',
        fontWeight: 500,
        [theme.breakpoints.down('md')]: {
            fontSize: '22px'
        },
    },
    contentSec: {
        zIndex: 2,
        fontSize: '24px',
        color: '#FFF',
        letterSpacing: '4px',
        padding: '10px 0px 30px'
    },
    contentLink: {
        fontSize: '18px',
        fontWeight: 700
    },
    backgroundBoxOverlay: {
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        top: 0
    }
})

export default Styles;



