import React from 'react';
import Styles from './style';
import { makeStyles, Popper, Paper, Grow, ClickAwayListener, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/auth";



const useStyles = makeStyles(Styles);

const Template = ({ anchorEl, close }) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { signOut } = useAuth()


    const open = Boolean(anchorEl);

    
 
    return (
        <div className={classes.root}>
            <Popper
                anchorEl={anchorEl}
                placement="center"
                open={open}
                className={classes.pooper}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }}>
                        <Paper elevation={0}>
                            <ClickAwayListener onClickAway={() => close()}>
                                <List id="menu-list-grow" autoFocus={false} autoFocusItem={false} className={classes.menuList}>
                                    <ListItem onClick={() => {
                                        close()
                                        navigate('/conta/encomendas/historico')

                                    }}>
                                        <ListItemIcon>
                                            <LocalMallIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('order history')} />
                                    </ListItem>

                                    <ListItem onClick={() => {
                                        close()
                                        navigate('/conta/settings')

                                    }}>
                                        <ListItemIcon>
                                            <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('account settings')} />
                                    </ListItem>
                                    
                                    <ListItem onClick={async () => {
                                        close()
                                        await signOut()
                                    }}>
                                        <ListItemIcon>
                                            <ExitToAppIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('logout')} />
                                    </ListItem>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
        
    )
}




export default Template

