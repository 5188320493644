import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles} from '@material-ui/core';
import Styles from './styles';

const useStyles = makeStyles(Styles);


const ItemChildren = ({
    item, 
    depth,
    children,
    isBlog
}) => {

    const classes = useStyles();
    const { i18n } = useTranslation();
    const navigate = useNavigate();



    let paddingLeft = 4;
    if (depth > 0) {
        paddingLeft = 8 + 1 * depth;
    }

    const style = {
        paddingLeft
    };
        
    let render = null;

    const categoryClickHandler = (item) => {
        if (!isBlog) {
            navigate('/produtos', { replace: true, state: { variant: item } })
        } else {
            navigate('/blog', { replace: true, state: { variant: item } } )
        }
    }

    if (children) {
        render = (
            <div className={clsx({ 
                [classes.categorylist]: true,
                [classes.categorylistChildren]: depth > 0
                })}
                style={style}>
                <h4 onClick={() => categoryClickHandler(item)}>
                    {depth > 0 ? '' : ''} {item[`title_${i18n.language}`]}
                </h4>
                {children}
            </div>
        )
          
    } else {
        render = 
            (
            <div className={clsx({
                [classes.categorylist]: true,
                [classes.categorylistChildren]: depth > 0
            })}
                style={style}>
                <h4 onClick={() => categoryClickHandler(item)}>
                    {depth > 0 ? '' : ''} {item[`title_${i18n.language}`]}
                </h4>
            </div>
          
        )
      
    }

    return (
        <>
            {render}
        </>
     )
}



export default ItemChildren;
