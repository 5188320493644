const Styles = theme => ({
    container: {
        position: 'relative',
        zIndex: 1
    },
    root: {
        cursor: 'pointer',
        margin: '20px auto',
        padding: '30px',
        background: 'rgba(40, 40, 40, 0.02)',
        [theme.breakpoints.down('md')]: {
            background: 'rgba(40, 40, 40, 0.02)',
        },
    },
    card: {
        padding: '30px',
        margin: '0 30px',
        boxShadow: '0 16px 24px 2px rgba(0,0,0, 0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2)',
        marginTop: '-30px',
        borderRadius: '6px',
        backgroundColor: 'white',
        [theme.breakpoints.down('sm')]: {
            padding: '5px',
            margin: '0 5px',
        }
    },
    productsWrapper: {
        display: 'flex',
        marginBottom: '40px',
        flexWrap: 'wrap',
        padding: '20px 40px',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            overflowX: 'auto',
            flexWrap: 'nowrap',
        }
        
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1
    },

    buttonSeMore: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '140px',
        color: 'white',
        margin: '0 5px',
        paddingTop: '11px',
        paddingBottom: '11px',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)',
            backgroundColor: '#e91e63',
        },
        '& span.MuiButton-label': {
            textTransform: 'uppercase !important',
            fontSize: '13px',
        }
    },
})

export default Styles;


