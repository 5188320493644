import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Styles from './styles';
import { makeStyles, List, ListItem, ListItemText, ListItemIcon, Drawer } from '@material-ui/core';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { useAuth } from '../../context/auth';
import { useApp } from '../../context/app';


const useStyles = makeStyles(Styles);


const DrawerMenu = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { signed, signOut } = useAuth();
    const { openMenuDrawer, setOpenMenuDrawer, setOpenLogin, setOpenSignup } = useApp();


    const navigate = useNavigate();

    const renderSigned = () => (
        <List id="menu-list-grow" autoFocus={false} autoFocusItem={false} className={classes.menuList}>
            <ListItem onClick={() => {
                setOpenMenuDrawer(false)
                navigate('/conta/encomendas/historico')

            }}>
                <ListItemIcon>
                    <LocalMallIcon />
                </ListItemIcon>
                <ListItemText primary={t('order history')} />
            </ListItem>

            <ListItem onClick={() => {
                setOpenMenuDrawer(false)
                navigate('/conta/settings')

            }}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t('account settings')} />
            </ListItem>

            <ListItem onClick={() => {
                setOpenMenuDrawer(false)
                navigate('/ajuda')

            }}>
                <ListItemIcon>
                    <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={t('help')} />
            </ListItem>

            <ListItem onClick={async () => {
                setOpenMenuDrawer(false)
                await signOut()
            }}>
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={t('logout')} />
            </ListItem>
        </List>
    )

    const renderGuest = () => (
        <List id="menu-list-grow" autoFocus={false} autoFocusItem={false} className={classes.menuList}>
            <ListItem onClick={() => {
                setOpenMenuDrawer(false)
                setOpenLogin(true)
               
            }}>
                <ListItemIcon>
                    <VpnKeyIcon />
                </ListItemIcon>
                <ListItemText primary={t('login')} />
            </ListItem>
            <ListItem onClick={() => {
                setOpenMenuDrawer(false)
                setOpenSignup(true)

            }}>
                <ListItemIcon>
                    <HowToRegIcon />
                </ListItemIcon>
                <ListItemText primary={t('register')} />
            </ListItem>
            <ListItem onClick={() => {
                setOpenMenuDrawer(false)
                navigate('/ajuda')

            }}>
                <ListItemIcon>
                    <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={t('help')} />
            </ListItem>
        </List>
    )



    return (
        <div className={classes.root}>
            <Drawer anchor={'left'} open={openMenuDrawer} onClose={() => { setOpenMenuDrawer(false)}} className={classes.drawer}>
                {signed ? renderSigned() : renderGuest()}
            </Drawer>
        </div>
       
    )
}


export default DrawerMenu;
