import React, { useState, useEffect } from 'react';
import Styles from './styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pt';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './../TabPanel';
import NutritionalTableDetail from './NutritionalTableDetail';



const useStyles = makeStyles(Styles);


const infoField = ["quantity", "dosage", "expiration", "lot", "totalDosage"];


const NutritionalDescription = ({
    product,
    hiddenDescription
}) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);
    const [tab, setTab] = useState(0);
    const [tabs, setTabs] = useState([])


 
 
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };



    useEffect(() => {
        let tbs = []
        if (product.nutritionDose && (product.nutritionDose.length > 0)) {
            tbs.push({ id: 'per intake', data: product.nutritionDose })
        }
        if (product.nutrition100 && (product.nutrition100.length > 0)) {
            tbs.push({ id: 'per 100g', data: product.nutrition100 })
        }
        if (product.nutritionDay && (product.nutritionDay.length > 0)) {
            tbs.push({ id: 'per daily intake', data: product.nutritionDay })
        }
        setTabs(tbs)
        
        return () => {
            
        }
    }, [product])


    
  

    return (
        <Grid container spacing={4} justify="center">
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <Paper
                    elevation={0}
                    className={clsx(classes.paperTableInfo, { [classes.paperTableInfoBorder]: !hiddenDescription })} >
                    <div className={classes.nutriTableInfo}>
                        {infoField.map(info => 
                            info !== "expiration" && info !== "lot" ? 
                                <div
                                    key={info}
                                    className={classes.infoLine}
                                >
                                    <span>{t(info)}:&nbsp;</span>
                                    {info === "expiration" ?
                                        <span>{moment(new Date(product[info])).format("LLLL")}</span>
                                        :
                                        <span>{product[info]}</span>
                                    }
                                </div>
                             : null 
                         )}
                    </div>
                    <div>
                        <CustomTabs
                            value={tab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeTab}
                            aria-label=""
                        >
                            {tabs.map( (it, index) => (
                                <CustomTab key={it.id} label={t(it.id)}{...a11yProps(index)} />
                            ))}
                        </CustomTabs>
                        {
                            tabs.map((it, index) => (
                                <TabPanel
                                    value={tab}
                                    index={index}
                                    className={classes.tabPanel}
                                >
                                    <NutritionalTableDetail nutrition={it.data} />
                                </TabPanel>
                            ))
                        }   
                    </div>
                </Paper>
            </Grid>
                {true ? null : 
                    <Grid xs={6} sm={6} md={12} lg={6} xl={6} item>
                        <Paper elevation={0} className={classes.paperNutriValues}>
                            <div dangerouslySetInnerHTML={{ __html: product[`smallDescription_${i18n.language}`] }} />
                        </Paper>
                    </Grid>
                }
            </Grid>
    );
}

export default NutritionalDescription;


function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


const CustomTabs = withStyles({
    root: {},
    indicator: {
      display: 'none'
    }
  })(Tabs);

  const CustomTab = withStyles((theme) => ({
      root: {
      padding: '0 20px',
      marginRight: '0',
      minWidth: '33%',
      textTransform: 'none',
      border: '1px solid',
      borderColor: '#00acc1',
      '&:hover': {
      },
      '&$selected': {
        textDecoration: 'underline', 
          backgroundColor: '#00acc1',
          color: 'white'
      },
      '&:focus': {
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);