const Styles = theme => ({
    root: {
        display: 'flex',
        marginBottom: '36px',
        maxWidth: theme.breakpoints.values.lg,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '330px',
        [theme.breakpoints.down('md')]: {
            height: '300px',
        },
    },
    bannerContainer: {
        position: 'relative',
        height: '100%',
        width: '100%',
        '& .slick-slider': {
            height: '100%',
            width: '100% !important',
            '& .slick-list': {
                height: '100%',
                width: '100% !important',
                '& .slick-track': {
                    height: '100%',
                    width: '100% !important',
                }
            }
        }
    },
    banner: {
        cursor: 'pointer',
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0
    },
    bannerItemContainer: {
        height: '100%',
        zIndex: 4,
    },
    bannerInner: {
        zIndex: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        height: '100%',
        paddingLeft: '30px',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.7) 85%, rgba(0, 0, 0, 0.8) 100%)',
    },
    arrowBox: {
        borderRadius: '32px',
        zIndex: 5,
        position: 'absolute',
        top: '40%',
        cursor: 'pointer',
        color: '#fff',
        display: 'flex !important',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        height: '32px',
        width: '32px',
        fontSize: '18px',
        '& svg': {
            color: theme.palette.secondary.main,
            height: '14px',
            width: '14px',
            marginLeft: '2px'
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)'
        }
    },
    backgroundBox: {
        zIndex: -1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
         "& .lazy-load-image-background": {
            width: '100%',
            height: '100%',
            '& img': {
                height: '100%',
                objectFit: 'cover',
                webkitTransition: 'all  1s ease-in-out',
                mozTransition: 'all  1s ease-in-out',
                oTransition: 'all  1s ease-in-out',
                transition: 'all  1s ease-in-out',
                [theme.breakpoints.down('sm')]: {
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                },
            }
         }
    },
    subtitle: {
        textTransform: 'uppercase',
        paddingLeft: '15px',
        borderLeft: `3px solid ${theme.palette.secondary.main}`,
        animationName: `$fadeInLeft`,
        animationDelay: '0.2s',
        animationDuration: '1s',
        '& h3': {
            color: theme.palette.gray,
            lineHeight: '22px',
            margin: '3px'
        },
        [theme.breakpoints.down('sm')]: {
            '& h3': {
                fontSize: '14px'
            },
        },
    },
    title: {
        marginBottom: '50px',
        maxWidth: '80%',
        '& h1': {
            marginTop: '10px',
            color: theme.palette.white,
            lineHeight: '22px',
            margin: '3px'
        },
        [theme.breakpoints.down('sm')]: {
            '& h1': {
               fontSize: '18px'
            },
        },
    },
    slidesPage: {
        position: 'absolute',
        right: '30px',
        bottom: '50px',
        '& span': {
            fontWeight: 400
        },
        '& span:first-of-type': {
            fontSize: '24px',
            color: '#FFFFFF'
        },
        '& span:last-of-type': {
            fontSize: '18px',
            color: '#FFFFFF',
            verticalAlign: 'bottom',
            paddingLeft: '5px',
            lineHeight: '34px'
        },
        [theme.breakpoints.down('sm')]: {
            '& span:first-of-type': {
                fontSize: '20px'
            },
            '& span:last-of-type': {
                fontSize: '16px',
            },
        },
    },
    



    "@keyframes slide": {
        "100%": {
            left: 0
        }
    },
    "@keyframes fadeInLeft": {
        from: {
            opacity: 0,
            transform: 'translate3d(-10%, 0, 0)'
        },
        to: {
            opacity: 1,
            transform: 'translate3d(0, 0, 0)'
        }
    },
    "@keyframes fadeInRight ": {
        from: {
            opacity: 0,
            transform: 'translate3d(100%, 0, 0)'
        },
        to: {
            opacity: 1,
            transform: 'translate3d(0, 0, 0)'
        }
    },
    hidden: {
        display: 'none'
    },
    "@keyframes fadeOut": {
        from: {
            opacity: 1,
        },
        to: {
            opacity: 0,
        }
    }
})

export default Styles;