import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useConfirm } from 'material-ui-confirm';
import Styles from './style';
import { makeStyles, TextField, ButtonGroup, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import MaterialTable  from 'material-table';
import { useTranslation } from 'react-i18next';
import { useApp } from '../../../context/app';


const API_URL = process.env.REACT_APP_DOMAIN;


const useStyles = makeStyles(Styles);

const Table = ({ cart }) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const confirm = useConfirm();
    const [dataTable, setDataTable] = useState([])
    const { addToCart, removeCart } = useApp();




    const updateChartHandler = (product, amount) => {
        addToCart(product, amount)
    }

    const onRemoveItemHandler = (product) => {
        confirm({ title: t('confirm'), description: t('delete item cart info') })
            .then(async () => {
                await removeCart(product)
            })
            .catch();
    }




    useEffect(() => {
        const data = cart.map(item => {
            return {
                product: item.product,
                imageUrl: `${API_URL}/warehouse/${item?.product?.thumbnail?.filename}`,
                description: item?.product[`title_${i18n.language}`],
                price: `${item?.product?.finalPrice}€`,
                iva: `${item?.product?.iva}%`,
                totalPrice: `${Number(item?.product?.finalPrice * item.quantity).toFixed(2)}€`,
                quantity: item.quantity
            }
        })
        setDataTable(data)
            
       
    }, [cart, i18n])

    return (
        <div className={classes.root}>
            <MaterialTable
                columns={[
                    { title: '', field: 'product', hidden: true},
                    {
                        title: t('item'), field: 'imageUrl', render: rowData => (
                            <div className={classes.imageBox}>
                                <img src={rowData.imageUrl} alt="thumb" />
                            </div>
                    ) },
                    {
                        title: t('description'), field: 'description', render: rowData => (
                            <h4 className={classes.description}>{rowData.description}</h4>
                    ) },
                    {
                        title: t('price'), field: 'price', render: rowData => (
                            <h3 className={classes.price}>{rowData.price}</h3>
                    )},
                    { title: t('iva'), field: 'iva' },
                    {
                        title: t('quantity'), field: 'quantity', render: rowData => (
                            <div className={classes.inputBox}>
                                <ButtonGroup>
                                    <TextField
                                        className={classes.amount}
                                        disabled={true}
                                        id="standard-number"
                                        type="text"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: false,
                                        }}
                                        value={rowData.quantity}
                                    />
                                    <Button
                                        className={clsx(classes.buttonQuantity, classes.buttonBorderLeft)}
                                        disableElevation
                                        disabled={rowData.quantity === 1}
                                        variant="contained"
                                        onClick={() => {
                                            updateChartHandler(rowData.product, (rowData.quantity - 1))
                                        }}
                                    >
                                        <RemoveIcon />
                                    </Button>
                                    <Button
                                        className={classes.buttonQuantity}
                                        disableElevation
                                        variant="contained"
                                        onClick={() => {
                                            updateChartHandler(rowData.product, rowData.quantity + 1)
                                        }}
                                    >
                                        <AddIcon />
                                    </Button>
                                </ButtonGroup>
                            </div>
                    ) },
                    {
                        title: t('total price'), field: 'totalPrice', render: rowData => (
                            <h3 className={classes.finalPrice}>{rowData.totalPrice}</h3>
                    )},
                    
                ]}
                data={dataTable}
                actions={[
                    {
                        icon: 'clear',
                        tooltip: t('clear'),
                        onClick: (event, rowData) => { onRemoveItemHandler(rowData.product) }
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    paging: false,
                    search: false,
                    toolbar: false,
                    showTitle: false,
                    rowStyle: {
                        borderTop: '2px solid rgba(0,0,0,0.05)',
                    },
                    headerStyle: {
                        fontSize: '16px',
                        fontWeight: 700
                    },
                }}
                localization={{
                    header: {
                        actions: null
                    }
                }}
            />
        </div>
    )
}




export default Table

