
const Styles = theme => ({
    root: {
        position: 'relative',
        width: '340px',
        height: '440px',
        margin: '20px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '250px',
            height: '280px',
        }
    },
    boxImage: {
        zIndex: 1,
        width: '100%',
        height: '50%',
        borderRadius: '6px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '6px'
        }
    },
    boxContent: {
        textAlign: 'left',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column'
    },
    boxVariant: {
        '& p': {
            lineHeight: '1.5em',
            color: '#f44336',
            display: 'inline-block',
            fontSize: '0.75rem',
            textTransform: 'uppercase',
            fontWeight: '500',
            marginTop: '10px',
            marginBottom: '10px' 
        }
    },
    boxTitle: {
        '& h2': {
            lineHeight: '38px !important',
            fontSize: '16px !important',
            color: '#3C4858',
            marginTop: '0.625rem',
            minHeight: 'auto',
            fontWeight: 700,
            marginBottom: '0.75rem',
            textDecoration: 'none' 
        }
    },
    boxDescription: {
        padding: '10px 0',
        '& p': {
            display: 'box',
            lineClamp: 3,
            boxOrient: 'vertical',
            overflow: 'hidden',
            fontSize: '14px',
            color: '#999'
        },
        '& span': {
            color: '#9c27b0',
            cursor: 'pointer',
            fontSize: '14px',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
})

export default Styles;