
const Styles = theme => ({
    dialog: {
        '& .MuiPaper-root': {
            backgroundColor: 'transparent'
        }
    },
    root: {
        backgroundColor: '#FFF',
        marginTop: '60px',
        position: 'relative',
        padding: '40px 40px !important',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 20px !important',
        },
    },
    closeBox: {
        position: 'absolute',
        top: 10,
        right: 10
    }
})

export default Styles;