import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Styles from './style';
import { withStyles, makeStyles, Container, Card, Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';
import { useNavigate, useLocation } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';
import { useApp } from "../../context/app";
import { useAuth } from '../../context/auth';
import BillingForm from './BillingForm';
import DeliveryForm from './DeliveryForm';
import PaymentForm from './PaymentForm';
import * as ApiService from '../../service/api';
import Loading from '../../components/Loading';

const FE_URL = process.env.REACT_APP_FE_DOMAIN;


const useStyles = makeStyles(Styles);

const Checkout = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const confirm = useConfirm();
    const { cart, totalPriceInCart, freeShip, settings, clearCart,  setAppLoading  } = useApp()
    const { user } = useAuth()
    const [loading, setloading] = useState(true)
    const [activeStep, setActiveStep] = useState(0);
    const [billingAddress, setBillingAddress] = useState(null)
    const [deliveryAddress, setDeliveryAddress] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [orderId, setOrderId] = useState(null)


    const steps = [
        t('billing'), t('address shipping'), t('payment')
    ]


    const submitBillingFormHandler = (data) => {
        setActiveStep(1)
        setBillingAddress(data)
    }

    const submitDeliveryFormHandler = (data) => {
        setDeliveryAddress(data)
        setActiveStep(2)
    }

    const submitFinalizeHandler = (data) => {
        confirm({ title: t('confirm'), description: t('submit payment info confirm') })
            .then(async () => {
                paymentIntentAndConfirm(data)
            })
            .catch();
    }



    const paymentIntentAndConfirm = (data) => {
        setAppLoading(true)
        const paymentIntentData = {
            orderId: orderId,
            type: data.method
        }

        ApiService.paymentIntent(paymentIntentData).then( async resp1 => {
            if (data.method === 'credit_card') {
                let expire = data?.data.expiry?.split('/')
                const postDataMethod = {
                    billingDetails: {
                        address: {
                            city: billingAddress?.name,
                            country: billingAddress?.country,
                            line1: billingAddress?.line1,
                            line2: '',
                            postal_code: billingAddress?.postal_code,
                            state: billingAddress?.country
                        },
                        email: billingAddress?.email,
                        name: billingAddress?.name,
                        phone: billingAddress?.phone,
                    },
                    cardDetails: {
                        number: data.data.number,
                        exp_month: expire[0],
                        exp_year: expire[1],
                        cvc: data.data.cvc
                    }
                }

                try {
                    const resp2 = await ApiService.paymentMethod(postDataMethod)
                    const paymentMethodId = resp2.data.content.id
                    const paymentIntentId = resp1.data.content.id

                    const postData = {
                        paymentIntentId: paymentIntentId,
                        paymentMethodId: paymentMethodId,
                        redirectUrl: FE_URL
                    }

                    await ApiService.paymentConfirm(postData)
                    navigate('/checkout/resumo', {
                        replace: true, state: {
                            paymentMethod: 'credit_card',
                            cart: cart,
                            totalPriceInCart: totalPriceInCart,
                            freeShip: freeShip,
                            settings: settings,
                            billingAddress: billingAddress,
                            deliveryAddress: deliveryAddress
                        }
                    })
                    clearCart()
                    setAppLoading(false)

                } catch (error) {
                    throw new Error('error payment method and confirm')
                }
                

            } else if (data.method === 'multibanco') {
                const _multibancoData = {
                    entity: resp1.data.content?.multibanco?.entity,
                    reference: resp1.data.content?.multibanco?.reference,
                    amount: resp1.data.content?.amount
                }
                navigate('/checkout/resumo', {
                    replace: true, state: {
                        paymentMethod: 'multibanco',
                        cart: cart,
                        totalPriceInCart: totalPriceInCart,
                        freeShip: freeShip,
                        settings: settings,
                        multibancoData: _multibancoData,
                        billingAddress: billingAddress,
                        deliveryAddress: deliveryAddress
                    } })

                clearCart()
                setAppLoading(false)
            } else {

            }
        }).catch(error => {
            setAppLoading(false)
        })
    }


    
    function getStepContent(step) {
        switch (step) {
            case 0:
                return <BillingForm
                            userInfo={userInfo}
                            data={billingAddress}
                            onSbumit={submitBillingFormHandler}
                        />
            case 1: 
                return <DeliveryForm
                            userInfo={userInfo}
                            data={deliveryAddress}
                            onSbumit={submitDeliveryFormHandler}
                            onBack={() => setActiveStep(0)}
                        />
            case 2:
                return <PaymentForm
                            onSbumit={submitFinalizeHandler}
                            onBack={() => setActiveStep(1)}
                        />
            
            default:
                return null;
        }
    }


    useEffect(() => {
        setOrderId(location?.state.orderId)

        const fetchUser = async (id) => {
            try {
                setloading(true)
                const userId = user._id
                const resp = await ApiService.getUser(userId);
                setUserInfo(resp.data.content)
                setloading(false)
            } catch (error) {
                setloading(false)
            }
        }
        fetchUser()
    }, [location?.state?.orderId, user._id])


    if (loading) return <Loading />

    if (!cart) return null

    return (
        <Container className={classes.root} maxWidth="xl" >
            <Card className={classes.card}>
                <div className={classes.cardHeader}>
                        <h1>{t('order')}</h1>
                        <h3>{t('your order')}</h3>
                    </div>
                    <div className={classes.container}>
                        <div className={classes.stepperHeader}>
                            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        <div className={classes.stepperContent}>
                            {getStepContent(activeStep)}
                        </div>
                    </div>
                </Card>
        </Container>
    )
}

export default Checkout








function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <ReceiptIcon />,
        2: <HomeIcon />,
        3: <PaymentIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#CCC',
        zIndex: 1,
        color: '#FFF',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

