const Styles =  theme => ({
    root: {
        padding: '40px 0',
        position: 'relative'
    },
    card: {
        padding: '80px 40px 40px',
        boxshadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        borderRadius: '6px'
    },
    header: {
        backgroundColor: 'red',
        color: 'white',
        fontWeight: 'bold',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-20px',
        borderRadius: '4px',
        margin: '0 15px',
        width: '72px',
        height: '72px',
        background: 'linear-gradient(60deg, #ef5350, #e53935)',
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px - 5px rgba(244, 67, 54, 0.4)',
        '& svg': {
            width: '36px',
            height: '36px'
        }
    },
    titleBox: {
        position: 'absolute',
        right: 0,
        color: '#999',
        marginRight: '56px',
        paddingTop: '12px',
        borderBottom: '1px solid #eee'
    },
    buttonSubmit: {
        color: 'white',
        boxShadow: '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)',
        backgroundColor: '#9c27b0',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)',
            backgroundColor: '#9c27b0'
        }
    },
    cardAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '30px'
    }
})

export default Styles;