import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Styles from './styles';
import { makeStyles, Button } from '@material-ui/core';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

const API_URL = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles(Styles);


const BlogPostCard = ({ data }) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();


    const onClickReadBlogHandler = () => {
        navigate(`/blog/post/${data.slug}`);

    }

    if (!data) {
        return 
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.boxVariant}>
                    <p>{data.category[`title_${i18n.language}`]}</p>
                </div>
                <div className={classes.boxTitle}>
                    <h2>{data[`title_${i18n.language}`]}</h2>
                </div>
                <div className={classes.boxDescription}>
                    <div dangerouslySetInnerHTML={{ __html: data[`smallDescription_${i18n.language}`] }} />
                </div>
                <div className={classes.boxButton}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<ViewHeadlineIcon />}
                        onClick={onClickReadBlogHandler} 
                    >
                        {t('read article')}
                 </Button>
                </div>
            </div>
            <div className={classes.boxImage}>
                {data.thumbnail ? (
                    <img src={`${API_URL}/warehouse/${data.thumbnail.filename}`} alt="" />
                ) : null}
            </div>
        </div>
    )
}



export default BlogPostCard
