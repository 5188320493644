const Styles = theme => ({
    root: {
        cursor: 'pointer',
        marginTop: '50px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '211px',
        height: '290px',
        minWidth: '211px',
        minHeight: '290px',
        marginRight: '16px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    boxImage: {
        position: 'absolute',
        top: -20,
        width: '140px',
        height: '140px',
        backgroundColor: theme.palette.white,
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            borderRadius: '6px'
        },
    },
    infoWaraper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        padding: '0 20px'
    },
    titleBox: {
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        '& h4': {
            wordBreak: 'break-word',
            display: 'box',
            lineClamp: 2,
            boxOrient: 'vertical',
            overflow: 'hidden',
        }
    },
    priceBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& h3': {
            color: '#178896',
            fontWeight: 'bold'
        },
        '& h4': {
            textDecoration: 'line-through',
            fontSize: '16px',
            color: '#9a9a9a',
            marginRight: '10px'
        },
        [theme.breakpoints.down('md')]: {
            '& h3': {
                fontSize: '14px'
            },
            '& h4': {
                fontSize: '12px'
            },
        }
    },
    priceDiscountContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    shoppingCartIcon: {
        color: 'rgba(0, 0, 0, 0.4)',
        '&:hover': {
            backgroundColor: '#00acc1',
            color: 'rgba(255, 255, 255, 1)',
        }
    },
   
    boxPromo: {
        display: 'flex',
        backgroundColor: '#9c27b0 ',
        borderColor: '#9c27b0 ',
        boxShadow: '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)',
        color: 'white',
        border: '1px solid #eee',
        borderRadius: '3px',
        minHeight: '33px',
        lineHeight: '19px',
        fontSize: '12px',
        paddingLeft: '15px',
        paddingRight: '15px',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            textTransform: 'uppercase'
        }
    }
})

export default Styles;



