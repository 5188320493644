import React from 'react'
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles,   Grid,  TextField,  FormControl,  Card,  CardContent,  CardActions } from '@material-ui/core'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import * as apiService from '../../../../service/api';
import SubmitButton from '../../../../components/SubmitButton';
import Alert from '@material-ui/lab/Alert';
import { useApp } from '../../../../context/app';


const useStyles = makeStyles(Styles);


const PasswordReset = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { showNotifications } = useApp()


    const formik = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            confirmNewPassword: ''
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/,
                    t('password regex')
                ),
            confirmNewPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], t('passwords does not match'))

        }),
        onSubmit: async (values) => {
            const postData = {
                currentPassword: values.password,
                newPassword: values.newPassword
            }
            try {
                await apiService.changePassword(postData);
                formik.resetForm()
                const notf = { type: 'success', title: t('set user password'), message: t('success set user password') }
                showNotifications(notf)
            } catch (error) {
                const respMessage = error.response.data.response;
                formik.setErrors({ changePassword: t(respMessage.toLowerCase()) });
                
            }
        }
    })


    return (
        <form onSubmit={formik.handleSubmit} >
            <div className={classes.root}>
                <div className={classes.header}>
                    <VerifiedUserIcon />
                </div>
                <div className={classes.titleBox}>
                    <h3>{t('password')}</h3>
                </div>
                <Card className={classes.card}>
                    {!!formik.errors.changePassword && (
                        <Alert severity="error">{formik.errors.changePassword}</Alert>
                    )}
                        <CardContent>
                            <Grid container direction="column" spacing={1}>
                                <Grid item xs>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField
                                            id="password"
                                            type="password"
                                            placeholder={`${t('password')} *`}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.password && formik.touched.password}
                                            helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField
                                            id="newPassword"
                                            type="password"
                                            placeholder={`${t('new password')} *`}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.newPassword && formik.touched.newPassword}
                                            helperText={(formik.errors.newPassword && formik.touched.newPassword) && formik.errors.newPassword}
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField
                                            id="confirmNewPassword"
                                            type="password"
                                            placeholder={`${t('confirm password')} *`}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.confirmNewPassword && formik.touched.confirmNewPassword}
                                            helperText={(formik.errors.confirmNewPassword && formik.touched.confirmNewPassword) && formik.errors.confirmNewPassword}
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                    </CardContent>
                    <CardActions className={classes.cardAction}>
                        <Grid item >
                            <Grid item xs>
                                <SubmitButton
                                    className={classes.buttonSubmit}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    isSubmiting={formik.isSubmitting}>
                                    {t('change password')}
                                </SubmitButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                    </Card> 
                </div>
        </form>
    )
}


export default PasswordReset;
