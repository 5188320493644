
const Styles = theme => ({
    root: {
        padding: '0',
        marginTop: '60px',
        backgroundColor: '#F7F9FC',
        width: '100%',
        maxWidth: '100%'
    },

    card: {
        padding: '30px 10px',
        margin: '30px 20px',
        borderRadius: '6px',
        backgroundColor: 'white',
        boxShadow: '0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)',
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0px 20px',
        '& h3': {
            margin: 0
        }
    },

    container: {
        padding: '20px 0px 10px',
    },
    stepperContent: {
        padding: '10px 50px 10px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 2px 5px'
        },
    }
})

export default Styles;