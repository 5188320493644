import React, { createContext, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as ApiService from '../service/api';
import * as Localforage from 'localforage';



const AppContext = createContext()

const AppProvider = ({ children }) => {

    const { t, i18n } = useTranslation();

    const [isSidebarOpened, setIsSidebarOpened] = useState(true);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [notificationsData, setNotificationsData] = useState({});
    const [cart, setCart] = useState([]);
    const [totalInCart, setTotalInCart] = useState(0);
    const [totalPriceInCart, setTotalPriceInCart] = useState(0);
    const [freeShip, setFreeShip] = useState(false);
    const [remainingFreeShip, setRemainingFreeShip] = useState(0);
    const [openLogin, setOpenLogin] = useState(false)
    const [openSignup, setOpenSignup] = useState(false)
    const [openRecoverPassword, setOpenRecoverPassword] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)
    const [openMenuDrawer, setOpenMenuDrawer] = useState(false)
    const [openCategoriesDrawer, setOpenCategoriesDrawer] = useState(false)
    const [variants, setVariants] = useState([]);
    const [categoriesBlog, setCategoriesBlog] = useState([]);
    const [categoriesBlogWithChildren, setCategoriesBlogWithChildren] = useState(null);
    const [openCartDrawer, setOpenCartDrawer] = useState(false)
    const [settings, setSettings] = useState(null)
    const [appLoading, setAppLoading] = useState(false)


    const sortByName = (list) => {
        const listSorted = list.sort(function (a, b) {
            if (a[`title_${i18n.language}`] < b[`title_${i18n.language}`]) { return -1; }
            if (a[`title_${i18n.language}`] > b[`title_${i18n.language}`]) { return 1; }
            return 0;
        })
        return listSorted
    }


    
    const showNotifications = (data) => {
        setOpenNotifications(true)
        setNotificationsData(data)
    }

    const closeNotification = () => {
        setOpenNotifications(false)
    }


    const addToCart = async (product, quantity) => {
        let _quantity = 0;
        let _totalPrice = 0;
        const index = cart.findIndex(it => it.product._id === product._id)
        if (index > -1) {
            let _cart = []
            if (quantity === 1) {
                 _cart = [...cart]
                _cart[index] = {
                    ..._cart[index],
                    quantity: _cart[index]?.quantity + 1
                }
            } else {
                _cart = [...cart]
                _cart[index] = {
                    ..._cart[index],
                    quantity: quantity
                }
            }
            _quantity = calculateTotalInCart(_cart)
            _totalPrice = calculatePriceTotalInCart(_cart)
            setCart(_cart)
            setTotalInCart(_quantity)
            setTotalPriceInCart(_totalPrice)
            setFreeShip(_totalPrice >= settings?.freeShippingCostMinimum)
            setRemainingFreeShip(Number(settings?.freeShippingCostMinimum - _totalPrice).toFixed(2))
            const notf = { type: 'success', title: t('cart'), message: t('item updated in cart') }
            showNotifications(notf)
            await Localforage.setItem('@ALL4Health:cart', JSON.stringify( _cart));

            return
        }
        const item = {
            product: product,
            quantity: quantity
        }
        _quantity = calculateTotalInCart([...cart, item])
        _totalPrice = calculatePriceTotalInCart([...cart, item])

        setCart([...cart, item])
        setTotalInCart(_quantity)
        setTotalPriceInCart(_totalPrice)
        setFreeShip(_totalPrice >= settings?.freeShippingCostMinimum)
        setRemainingFreeShip(Number(settings?.freeShippingCostMinimum - _totalPrice).toFixed(2) )
        const notf = { type: 'success', title: t('cart'), message: t('item added to cart') }
        showNotifications(notf)
        await Localforage.setItem('@ALL4Health:cart', JSON.stringify([...cart, item]));
    }

    const removeCart = async (product) => {
        const _cart = cart.filter(it => it.product._id !== product._id)
        const _quantity = calculateTotalInCart(_cart)
        const _totalPrice = calculatePriceTotalInCart(_cart)
        setCart(_cart)
        setTotalInCart(_quantity)
        setTotalPriceInCart(_totalPrice)
        setFreeShip(_totalPrice >= settings?.freeShippingCostMinimum)
        setRemainingFreeShip(Number(settings?.freeShippingCostMinimum - _totalPrice).toFixed(2) )
        await Localforage.setItem('@ALL4Health:cart', JSON.stringify(_cart));
    }
    
    const clearCart = async () => {
        setCart([])
        setTotalInCart(0)
        setTotalPriceInCart(0)
        setFreeShip(0)
        setRemainingFreeShip(0)
        await Localforage.removeItem('@ALL4Health:cart');
    }

    const calculateTotalInCart = (cart) => {
        let quantity = 0
        cart?.forEach(it => {
            quantity = quantity + it?.quantity
        });
        return quantity
    }

    const calculatePriceTotalInCart = (cart) => {
        let price = 0
        cart?.forEach(it => {
            price = price + (it?.quantity * it?.product?.finalPrice)
        });
        return Number(price).toFixed(2)
    }



    useEffect(() => {
        
        const getProductsVariants = async () => {
            return ApiService.getAllVariants();
        }

        const getCategoriesBlogs = async () => {
            return ApiService.getAllCategoriesBlog();
        }

        const preocessVariants = (variants) => {
            for (var i = 0; i < variants.length; i++) {
                if (variants[i].parent !== '/') {
                    const variant = variants[i]
                    for (var j = 0; j < variants.length; j++) {
                        if (variants[j]?.category === variant?.parent) {
                            if (variants[j].hasOwnProperty('children')) {
                                variants[j]['children'] = [...variants[j]['children'], variant];
                            } else {
                                variants[j]['children'] = [variant];
                            }
                        }
                    }
                }
            }
            const variantsCopy = variants.filter(item => item.parent === '/')
            const brand = variantsCopy.filter(item => item.title_pt.toLowerCase() === 'marcas')
            let rest = variantsCopy.filter(item => item.title_pt.toLowerCase() !== 'marcas')
            rest = [...rest, ...brand]
            setVariants(rest);
        }

        const getSettings = async () => {
            return  await ApiService.getSettings();
        }


        const getData = async () => {
            try {
                const [res1, res2, res3] = await Promise.all([getProductsVariants(), getCategoriesBlogs(), getSettings()]);
                let list = res1.data.content;
                let _settings = res3.data.content
                list = sortByName(list)
                preocessVariants(list)
                const cat = { children: res2.data.content }
                setCategoriesBlog(res2.data.content)
                setCategoriesBlogWithChildren(cat)

                const _settings_ = {
                    iva: Number(_settings?.IVA),
                    freeShippingCostMinimum: Number(_settings?.freeShippingCostMinimum),
                    shippingCost: Number(_settings?.shippingCost)
                }
                setSettings(_settings_)
                loadDataStorage(_settings_)
            } catch (error) {}
        }

        const loadDataStorage = async (_settings) => {
            let _cart = await Localforage.getItem('@ALL4Health:cart');
             _cart = JSON.parse(_cart)
            const _quantity = calculateTotalInCart(_cart)
            const _totalPrice = calculatePriceTotalInCart(_cart)
             if(!_cart) _cart = []
            setCart(_cart)
            setTotalInCart(_quantity)
            setTotalPriceInCart(_totalPrice)
            setFreeShip(_totalPrice >= _settings?.freeShippingCostMinimum)
            setRemainingFreeShip(Number(_settings?.freeShippingCostMinimum - _totalPrice).toFixed(2))
        }

        getData()
    }, [])


 

    return (
        <AppContext.Provider
            value={{
                appLoading, setAppLoading,
                isSidebarOpened, setIsSidebarOpened,
                openNotifications, setOpenNotifications,
                notificationsData, showNotifications, closeNotification,
                openLogin, setOpenLogin,
                openSignup, setOpenSignup,
                openRecoverPassword, setOpenRecoverPassword,
                cart, addToCart, removeCart, totalInCart, totalPriceInCart, freeShip, remainingFreeShip, clearCart,
                openSearch, setOpenSearch,
                openMenuDrawer, setOpenMenuDrawer,
                openCategoriesDrawer, setOpenCategoriesDrawer,
                variants, categoriesBlog, categoriesBlogWithChildren,
                openCartDrawer, setOpenCartDrawer, settings
            }}>
            {children}
        </AppContext.Provider>
    );

}

function useApp() {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useApp must be used within an AppProvider.');
    }
    return context;
}


export { AppProvider, useApp };
