import React from 'react';
import Styles from './styles';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles(Styles);


const BlogArticleCard = ({ data }) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const onClickReadBlogHandler = () => {
        navigate(`/blog/post/${data._id}`);
    }


    if (!data) {
        return 
    }

    return (
        <div className={classes.root}>
            <div className={classes.boxImage}>
                {data.thumbnail ? (
                    <img src={`${API_URL}/warehouse/${data.thumbnail.filename}`} alt="" />
                ) : null}
            </div>
            <div className={classes.boxContent}>
                <div className={classes.boxVariant}>
                    <p>{data.category[`title_${i18n.language}`]}</p>
                </div>
                <div className={classes.boxTitle}>
                    <h2>{data[`title_${i18n.language}`]}</h2>
                </div>
                <div className={classes.boxDescription}>
                    {/*<div dangerouslySetInnerHTML={{ __html: data[`smallDescription_${i18n.language}`] }} />*/}
                    <span onClick={onClickReadBlogHandler} >{t('know more')}</span>
                </div>
            </div>
        </div>
    )
}

export default BlogArticleCard;
