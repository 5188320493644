import React, { useState, useEffect} from 'react';
import Styles from './styles';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { makeStyles,  Typography,  Button } from '@material-ui/core';


const useStyles = makeStyles(Styles);


const CookieBar = () => {

    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();


    const clickAcceptHandle = () => {
        Cookies.set('consent', true);
        setShow(false);
    }

    useEffect(() => {
        const consent = Cookies.get('consent');
        if (!consent) {
           setShow(true);
        }
    }, []);


    const handlePricacypolicyClick = () => {
        navigate({ pathname: '/politica-de-privacidade', search: `?type=privacidade` })
    }

    if (!show) {
        return null;
    }

    return (
        <section id="cookies" className={classes.root}>
            <div className={classes.content}>
                <h4 align='center' className={classes.typography}>
                    {t('cookie 1')}
                    <span onClick={handlePricacypolicyClick}>&nbsp;{t('cookie 2')}</span >
                </h4>
                <Button className={classes.buttonAccept}
                    color="primary"
                    variant="outlined"
                    onClick={clickAcceptHandle}
                    >
                    {t('accept')}
                </Button>
            </div>
        </section>
    );
}

export default CookieBar;
