import Palette from '../palette';

const MuiDivider = {
    root: {
        backgroundColor: Palette.lightgray,
        flexGrow: 1
    },
    middle: {
        marginLeft: 20,
        marginRight: 20
    }
};

export default MuiDivider;
