import React, { useEffect, useState } from 'react';
import Styles from './style';
import { makeStyles, Grid, RadioGroup, FormControlLabel, Radio, Button} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CreditCard from '../CreditCard';




const useStyles = makeStyles(Styles);

const PaymentForm = ({
    onSbumit,
    onBack
}) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const [paymentOption, setPaymentOption] = useState('credit_card');

    const [creditCardData, setCreditCardData] = useState(null)


    const handleChange = (event) => {
        setPaymentOption(event.target.value);
    };


    function getPaymentContent(method) {
        switch (method) {
            case 'credit_card':
                return <CreditCard onChange={data => setCreditCardData(data)}/>
            
            case 'multibanco':
                return <p style={{textAlign: 'center'}}>{t('info reference multibanco info')}</p>
            
            case 'mbway':
                return 
            default:
                return null;
        }
    }






    
    useEffect(() => {
    }, [])


    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <h4>{t('select your payment method')}</h4>
                    <RadioGroup aria-label="gender" name="gender1" value={paymentOption} onChange={handleChange}>
                        <FormControlLabel value="credit_card" control={<Radio />} label={t('credit card')} />
                        <FormControlLabel value="multibanco" control={<Radio />} label={t('multibanco')} />
                        <FormControlLabel value="mbway" control={<Radio />} label={t('mbway')} />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <div className={classes.paymentFormContent}>
                        {getPaymentContent(paymentOption)}
                    </div>
                </Grid>
            </Grid>
            <div className={classes.submitBox}>
                <Button
                    onClick={() => onBack()}
                    variant="contained"
                    color="secondary">
                    {t('previous')}
                </Button>
                <Button
                    onClick={() => onSbumit({ method: paymentOption, data: creditCardData})}
                    type="submit"
                    variant="contained"
                    color="primary">
                    {t('finalize')}
                </Button>
            </div>
        </div>
    )
}




export default PaymentForm

