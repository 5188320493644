
const Styles = theme => ({
    root: {
        padding: '0',
        marginTop: '0',
        backgroundColor: '#F7F9FC',
        width: '100%',
        maxWidth: '100%'
    },
 
    card: {
        padding: '30px 10px',
        margin: '30px 20px',
        borderRadius: '6px',
        backgroundColor: 'white',
        boxShadow: '0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)',
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        marginBottom: '30px',
        justifyContent: 'flex-end',
        '& a': {
            fontStyle: 'italic',
            cursor: 'pointer'
        }
    },
    imageBox: {
        height: '100%',
        width: '100%',
        display: 'flex',
        maxHeight: '320px',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        '& img': {
            height: '100%',
            objectFit: 'cover'
        }
    },
    infoWrapper: {
        padding: '0 30px',
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column'
    },
    titleBox: {

    },
    descriptionBox: {
        textAlign: 'right'
    },
    priceBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: 'auto',
        '& h2': {
            color: '#178896',
            fontWeight: 'bold'
        },
        '& h3': {
            textDecoration: 'line-through',
            fontSize: '18px',
            color: '#f44336',
            marginRight: '10px'
        }
    },
    priceDiscountContainer: {
        display: 'flex',
        alignItems: 'center'
    },

    boxInfoFooter: {
        margin: '70px 0 100px',
        minHeight: '70px',
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        padding: '0 20px',
        textAlign: 'center'
    },
    gridContainerBoxInfoFooter: {
        maxWidth: '1410px',
        textAlign: 'center'
    },
    boxInfoContainerItem1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h1': {
            color: '#3c4858',
            fontWeight: 800,
            margin: '1.75rem 0.875rem',
            fontSize: '1.6rem !important'
        },
        '& h4': {
            color: '#999',
        },
        '& svg': {
            color: '#00bcd4',
            width: '2.5em',
            height: '2.5em'
        }
    },
    boxInfoContainerItem2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h1': {
            color: '#3c4858',
            fontWeight: 800,
            margin: '1.75rem 0.875rem',
            fontSize: '1.6rem !important'
        },
        '& h4': {
            color: '#999',
        },
        '& svg': {
            color: '#4caf50',
            width: '2.5em',
            height: '2.5em'
        }
    },
    boxInfoContainerItem3: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h1': {
            color: '#3c4858',
            fontWeight: 800,
            margin: '1.75rem 0.875rem',
            fontSize: '1.6rem !important'
        },
        '& h4': {
            color: '#999',
        },
        '& svg': {
            color: '#e91e63',
            width: '2.5em',
            height: '2.5em'
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        '& button': {
            margin: '12px'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    buttonStock: {
        cursor: 'default',
        color: '#FFF',
        boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4)',
        backgroundColor: '#9c27b0',
        '& svg': {
            fontSize: '32px !important'
        },
        '&:hover': {
            boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4)',
            backgroundColor: '#9c27b0',
        },
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                fontSize: '22px !important'
            },
        },
    },
    buttonCompare: {
        color: '#FFF',
        boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)',
        backgroundColor: '#00acc1',
        '& svg': {
            fontSize: '32px !important'
        },
        '&:hover': {
            boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)',
            backgroundColor: '#00acc1',
        },
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                fontSize: '22px !important'
            },
        },
    },
    buttonFavorite: {
        color: '#FFF',
        boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)',
        backgroundColor: '#e91e63',
        '& svg': {
            fontSize: '32px !important'
        },
        '&:hover': {
            boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)',
            backgroundColor: '#e91e63',
        },
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                fontSize: '22px !important'
            },
        },
    },
    gridBoxAcordion: {
        marginLeft: 'auto'
    },

    form: {
        padding: '0px 20px',
        height: 'auto',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
    },
    inputBox: {
        marginRight: '20px',
        '& .MuiInputLabel-root': {
            marginBottom: '10px'
        }
    },
    amount: {
        maxWidth: '80px',
        margin: '0',
        height: '50px',
        '& .MuiInputBase-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }
    },
    button: {
        maxWidth: '245px',
    },
    buttonQuantity: {
        height: '50px',
        background: 'linear-gradient(60deg, #66bb6a, #43a047) !important',
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4) !important',
        color: 'white'
    },
    buttonBorderLeft: {
        height: '50px',
        borderLeft: '1px solid #eeeeee'
    },
    submitButton: {
        maxWidth: '135px',
        boxShadow: '0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)',
        backgroundColor: '#e91e63',
        '&:hover': {
            boxShadow: '0 18px 30px -6px rgba(233, 30, 99, 0.42), 0 6px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 20px -5px rgba(233, 30, 99, 0.2)',
            backgroundColor: '#e91e63',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginTop: '20px'
        },
    },
    relatedProductsWrapper: {
        marginTop: '40px',
        padding: '0 0px'
    },
   relatedProductsTitle: {
        marginLeft: '30px'
    },
    containerRelatedProducts: {
        padding: '0 0px',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0px 0',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
})

export default Styles;