const Styles =  theme => ({
    root: {},
    categorylist: {
        margin: '0 30px',
        padding: '5px 0',
        '& h4': {
            margin: '0px !important',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem'
            },
            '&:hover': {
                color: theme.palette.secondary.main
            }
        }
    },
    categorylistChildren: {
        margin: '0 0px',
        padding: '0',
        border: 'none !important',
        '& h4': {
            margin: '0px !important',
            cursor: 'pointer',
            color: 'black',
            fontWeight: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem'
            },
            '&:hover': {
                color: theme.palette.secondary.main
            }
        }
    }
})

export default Styles;