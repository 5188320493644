import React, { Suspense }  from 'react';
import { BrowserRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import Layout from './layout';
import theme from './theme';

import ScrollReset from './components/ScrollReset';
import CookieBar from './components/CookieBar';




import { AuthProvider } from './context/auth';
import { AppProvider } from './context/app';

const App = () => {

  const classes = useStyles();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <AuthProvider>
          <AppProvider>
              <ThemeProvider theme={theme}>
              <CookieBar />
              <ConfirmProvider defaultOptions={{
                confirmationButtonProps: { autoFocus: true, className: classes.buttonConfirm },
                cancellationButtonProps: { className: classes.buttonCancel },
                dialogProps: { className: classes.dialog }
              }}>
                    <Layout />
                </ConfirmProvider>
            </ThemeProvider>
            <ScrollReset />
          </AppProvider>
        </AuthProvider>
        </BrowserRouter>
    </Suspense>
  )
}

export default App



const useStyles = makeStyles(() => ({
  buttonConfirm: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.white,
    }
  },
  buttonCancel: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.white,
    }
  },
  dialog: {
    '& .MuiDialogContent-root': {
      textAlign: 'center',
      padding: '30px',
      '& p': {
        fontSize: '16px',
        whiteSpace: 'pre-line'
      }
    }
  }
}));


