const Styles = theme => ({
    root: {
        padding: '60px',
        backgroundColor: '#EEE',
        [theme.breakpoints.down('sm')]: {
            padding: '20px'
        },
    },
    checkoutCartHeader: {
        padding: '0 40px',
        '& h3': {
            '& i': {
                color: 'red',
                marginRight: '15px'
            }
        }
    }
  
})

export default Styles;