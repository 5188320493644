import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Styles from './style';
import { makeStyles, Drawer, Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import * as ApiService from './../../service/api';
import ListChildren from './ListChildren';
import { useApp } from "../../context/app";



const useStyles = makeStyles(Styles);

const MenuCategories = () => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [categoryParentSelected] = useState(null);
    const { setOpenCategoriesDrawer, variants, categoriesBlogWithChildren } = useApp()



 
    const blogClickHandler = () => {
        navigate({ pathname: '/blog' })
        setOpenCategoriesDrawer(false)

    }

    const categoryClickHandler = (item) => {
        navigate('/produtos', { replace: true, state: { variant: item } })
        
        setOpenCategoriesDrawer(false)

    }


    const freeAdviseClickHandler = () => {
        navigate({
            pathname: '/aconselhamento-gratuito',
            search: `?type=aconselhamento-gratuito`
        })
        setOpenCategoriesDrawer(false)
    }

 





    return (
        <div className={classes.root}>
            <Drawer anchor={'left'} open={true} onClose={() => { setOpenCategoriesDrawer(false) }} className={classes.drawer}>
                <div className={classes.variantList} >
                    {variants.map((variant, index) => (
                        <Accordion className={classes.accordion} key={`accordion-${index}`}>
                            <AccordionSummary className={classes.accordionSummary}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls=""
                                id=""
                            >
                                <div className={classes.accordionSummaryBoxTitle}
                                    onClick={(event) => { event.stopPropagation(); categoryClickHandler(variant) }}
                                    onFocus={(event) => event.stopPropagation()}>
                                    <h6>
                                        {variant[`title_${i18n.language}`]}
                                    </h6>
                                </div>

                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.variantListContent}>
                                    <ListChildren
                                        depth={0}
                                        variants={variant?.children}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Accordion className={classes.accordion} key={`accordion-blog`}>
                        <AccordionSummary className={classes.accordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={classes.accordionSummaryBoxTitle}
                                onClick={(event) => { event.stopPropagation(); blogClickHandler() }}
                                onFocus={(event) => event.stopPropagation()}>
                                <h6>
                                    {t('blogs')}
                                </h6>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.variantListContent}>
                                {categoriesBlogWithChildren ? (
                                    <ListChildren
                                        isBlog={true}
                                        close={() => setOpenCategoriesDrawer(false)}
                                        depth={0}
                                        parent={categoryParentSelected}
                                        variants={categoriesBlogWithChildren?.children}
                                    />
                                ) : null}

                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion} key={`free-advice`} >
                        <AccordionSummary className={classes.accordionSummary}
                            expandIcon={null}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={classes.accordionSummaryBoxTitle}
                                onClick={(event) => { event.stopPropagation(); freeAdviseClickHandler()}}
                                onFocus={(event) => event.stopPropagation()}>
                                <h6>
                                    {t('free advice')}
                                </h6>
                            </div>
                        </AccordionSummary>
                    </Accordion>

                </div>
            </Drawer>
        </div>

    )
}


export default MenuCategories



