const Style =  theme => ({
    root: {
        position: 'relative',
        padding: '40px'
    },
    header: {
        width: '100%',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        marginBottom: '25px',
        '& a': {
            fontStyle: 'italic'
        }
    },
    paper: {
        position: 'relative'
    },
    blogPostBody: {
        padding: '30px 20px'

    },
    sideBar: {
        marginBottom: '10px'
    },
    sideBarTtle: {
        borderRadius: '6px',
        color: '#fff',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 800,
        letterSpacing: '0.8px',
        fontSize: '14px',
        padding: '15px 0',
        margin: '0 0 25px'
    },
    sideBarTtleLast: {
        background: 'linear-gradient(60deg, #8b47bc, #8e24aa)',
        boxShadow: '0 12px 20px - 10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px - 5px rgba(156, 39, 176, 0.2)'
    },
    sideBarTtleMostRead: {
        background: 'linear-gradient(60deg, #bf1c15, #bf1c18)',
        boxShadow: '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)'
    },
     post: {
         display: 'flex',
         paddingBottom: '20px',
         margin: '0 0 20px 0',
         borderBottom: '1px solid #E4E4E4',
         '&:last-child': {
             borderBottom: 'none',
         }
    },
    postThumbnail: {
        marginRight: '20px',
        width: '90px',
        float: 'left',
        verticalAlign: 'middle',
        position: 'relative',
        cursor: 'pointer',
        '& img': {
            maxWidth: '100%',
            height: 'auto'
        }
    },
    postData: {
        '& p': {
            margin: 0,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            cursor: 'pointer'
        },
        '& span': {
            marginTop: '5px',
            fontWeight: 400,
            fontSize: '11px',
            color: '#B1B1B1',
            fontStyle: 'italic'
        }
    },
    menuSocialContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    link: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline !important',
            color: '#0056b3 !important'
        }
    },
    boxProducts: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '80px auto 30px'
    }
})

export default Style;

