import React from 'react';
import Styles from './style';
import { makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import NutritionalDescription from '../NutritionalDescription';



const useStyles = makeStyles(Styles);

const ProductInfoAcordion = ({
    product
}) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <div>
            <Accordion  className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h4>{t('desciption')}</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.descrition} dangerouslySetInnerHTML={{ __html: product[`bigDescription_${i18n.language}`] }} />
                </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h4>{t('nutrition declaration')}</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <NutritionalDescription
                        product={product}
                        nutrition100={product.nutrition100}
                        nutritionDay={product.nutritionDay}
                        nutritionDose={product.nutritionDose}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion  className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <h4>{t('use mode')}</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.useMode} dangerouslySetInnerHTML={{ __html: product[`useMode_${i18n.language}`] }} />
                </AccordionDetails>
            </Accordion>


            <Accordion  className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                >
                    <h4>{t('more info')}</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.descrition} dangerouslySetInnerHTML={{ __html: product[`moreInfo_${i18n.language}`] }} />
                </AccordionDetails>
            </Accordion>


            <Accordion  className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <h4>{t('faq')}</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div dangerouslySetInnerHTML={{ __html: product[`faq_${i18n.language}`] }} />
                </AccordionDetails>
            </Accordion>

        </div>
    )
}




export default ProductInfoAcordion

