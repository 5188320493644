const Styles = theme => ({
    root: {
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderRadius: '6px',
        marginTop: '50px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '248px',
        height: '350px',
        marginRight: '16px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('md')]: {
            width: '148px',
            height: '246px',
            marginRight: '4px',
            marginLeft: '4px'
        }
    },
    boxImage: {
        height: '40%',
        padding: '15px',
        backgroundColor: 'transparent',
        overflow: 'hidden',
        "& .lazy-load-image-background": {
            width: "100%",
            height: "100%",
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                borderRadius: '6px'
            },
        }
    },
    infoWaraper: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '0 20px'
    },
    titleBox: {
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        '& h4': {
            textAlign: 'center',
            wordBreak: 'break-word',
            display: 'box',
            lineClamp: 3,
            boxOrient: 'vertical',
            overflow: 'hidden',
        },
        [theme.breakpoints.down('md')]: {
            '& h4': {
               fontSize: '13px'
            },
        }
    },
    priceBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 'auto',
        '& h3': {
            color: '#178896',
            fontWeight: 'bold'
        },
        '& h4': {
            textDecoration: 'line-through',
            fontSize: '16px',
            color: '#9a9a9a',
            marginRight: '10px'
        },
        [theme.breakpoints.down('md')]: {
            '& h3': {
                fontSize: '14px'
            },
            '& h4': {
                fontSize: '12px'
            },
        }
    },
    priceDiscountContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    shoppingCartIcon: {
        color: theme.palette.secondary.main
    },
    boxPromo: {
        display: 'flex',
        backgroundColor: '#9c27b0 ',
        borderColor: '#9c27b0 ',
        boxShadow: '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)',
        color: 'white',
        border: '1px solid #eee',
        borderRadius: '3px',
        minHeight: '33px',
        lineHeight: '19px',
        fontSize: '12px',
        paddingLeft: '15px',
        paddingRight: '15px',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            textTransform: 'uppercase'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '10px',
            minHeight: '23px',
        }
    },
})

export default Styles;



