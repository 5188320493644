import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import {
    makeStyles,
    Container,
    Grid,
    TextField,
    FormControl,
    InputAdornment
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import EmailIcon from '@material-ui/icons/Email';
import SubmitButton from './../../components/SubmitButton'
import * as ApiService from '../../service/api';

const useStyles = makeStyles(Styles);



const NewsLetter = () => {

    const classes = useStyles();
    const { t } = useTranslation();


    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(t('invalid email'))
        }),
        onSubmit: async (values) => {
            try {
                const postData = { email: values.email }
                await ApiService.addNewsLetter(postData)
                formik.resetForm()
            } catch (error) {
                formik.setErrors({ newsLetter: t('newsletter submit error') });
            }
        },
    });


  return (
      <section className={classes.root}>
          <Container maxWidth="md">
              <Grid container>
                  <Grid item xs={12}>
                      <form onSubmit={formik.handleSubmit} className={classes.form}>
                          <h2 style={{textTransform: 'uppercase'}}>{t('newsletter')}</h2>
                          <p>{t('newsletter description')}</p>
                          <Grid contaner spacing={3} direction="column">
                              <Grid item>
                                  {!!formik.errors.newsLetter && (
                                      <Alert severity="error">{formik.errors.newsLetter}</Alert>
                                  )}
                                  <div className={classes.boxInputForm}>
                                      <FormControl fullWidth className={classes.formControl}>
                                          <TextField
                                              variant='outlined'
                                              className={classes.inputEmail}
                                              id="email"
                                              placeholder={`${t('email')} *`}
                                              margin="dense"
                                              fullWidth
                                              value={formik.values.email}
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              required
                                              error={formik.errors.email && formik.touched.email}
                                              helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                              InputProps={{
                                                  startAdornment: (
                                                      <InputAdornment position="start">
                                                          <EmailIcon />
                                                      </InputAdornment>
                                                  ),
                                              }}
                                          />
                                      </FormControl>
                                      <SubmitButton
                                          styles={classes.buttonNewsletter}
                                          size="large"
                                          type="submit"
                                          variant="contained"
                                          color="primary"
                                          isSubmiting={formik.isSubmiting}>
                                          {t('subscribe')}
                                      </SubmitButton>

                                  </div>
                                 
                              </Grid>
                          </Grid>
                        </form>
                  </Grid>
             </Grid>
          </Container>
     </section>
  )
}


export default NewsLetter;
