
const Styles = theme => ({
    drawer: {
        zIndex: 2001,
        '& .MuiDrawer-paper': {
            borderRadius: '0px !important',
            minWidth: '65%'
        }
    },
    menuList: {
        cursor: 'pointer',
        minWidth: "170px",
        width: '100%',
        '& li': {
            '&:hover': {
                backgroundColor: '#9c27b0',
                '& span': {
                    color: '#FFF !important',
                },
                '& svg': {
                    color: '#FFF !important',
                }
            }
        }
    }
})

export default Styles;