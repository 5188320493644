const Styles = theme => ({
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0.97)',
        color: 'white',
        '& .MuiGrid-root': {
            padding: '30px 64px'
        }
    },
    gridFooterRow2: {
        backgroundColor: theme.palette.secondary.main
    },
    gridItem: {
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 15px'
    },
    columnList: {
        display: 'flex',
        flexDirection: 'column',
        '& a': {
            textDecoration: 'none',
            cursor: 'pointer',
            marginBottom: '10px',
            color: 'white',
            fontSize: '14px',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        marginTop: '10px'
    },
    title: {
        color: '#FFF !important',
        '& .MuiBox-root': {
            fontSize: '1.5rem'
        }
    }
});

export default Styles;