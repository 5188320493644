import React from 'react';
import Styles from './style';
import { makeStyles, Grid, FormControl, TextField} from '@material-ui/core';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Cards from 'react-credit-cards';
import { useFormik } from 'formik';
import 'react-credit-cards/es/styles-compiled.css';

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from './utils';




const useStyles = makeStyles(Styles);

const CreditCard = ({ onChange }) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();



    const handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            formik.setFieldValue('issuer', issuer )
        }
    };

    const handleInputChange = ({ target }) => {
        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value);
        }

        //this.setState({ [target.name]: target.value });
        formik.setFieldValue(target.name, target.value)
        onChange({
            ...formik.values,
            [target.name]: target.value,
            isValid: formik.isValid
        })
    };



    const formik = useFormik({
        initialValues: {
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
            issuer: ''
        },
        validationSchema: Yup.object().shape({
            number: Yup.string()
                .required(t('required field')),
            name: Yup.string()
                .required(t('required field')),
            expiry: Yup.string()
                .required(t('required field')),
            cvc: Yup.string()
                .required(t('required field'))
        }),
    })

    



    return (
        <div className={classes.root}>
            <Cards
                className={classes.creditCard}
                cvc={formik.values.cvc}
                expiry={formik.values.expiry}
                focused={formik.values.focus}
                name={formik.values.name}
                number={formik.values.number}
                callback={handleCallback}
            />
            <form className={classes.formBox}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                placeholder={t('card number')}
                                pattern="[\d| ]{16,22}"
                                name="number"
                                type="tel"
                                required
                                fullWidth
                                onFocus={ev => formik.setFieldValue('number', ev.target.name)}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                placeholder={t('name')}
                                name="name"
                                type="text"
                                required
                                fullWidth
                                onFocus={ev => formik.setFieldValue('focus', ev.target.name)}
                                value={formik.values.name}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <TextField
                                placeholder={'MM/AA'}
                                pattern="\d\d/\d\d"
                                name="expiry"
                                type="tel"
                                required
                                fullWidth
                                onFocus={ev => formik.setFieldValue('focus', ev.target.name)}
                                value={formik.values.expiry}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                placeholder={'CVC'}
                                name="cvc"
                                type="tel"
                                required
                                fullWidth
                                onFocus={ev => formik.setFieldValue('focus', ev.target.name)}
                                value={formik.values.cvc}
                                onChange={handleInputChange}
                                pattern="\d{3,4}"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}




export default CreditCard

