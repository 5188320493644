import palette from '../palette';


const MuiToolbar = {
    root: {
        backgroundColor: palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        boxShadow: palette.shadow,
        padding: '0 !important'
    }
};

export default MuiToolbar;