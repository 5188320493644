const Styles = theme => ({
    root: {
        padding: '40px 0',
        position: 'relative'
    },
    card: {
        padding: '80px 40px 40px',
        boxshadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14) !important',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            padding: '80px 10px 40px',
        }
    },
    header: {
        backgroundColor: 'red',
        color: 'white',
        fontWeight: 'bold',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-20px',
        borderRadius: '4px',
        margin: '0 15px',
        width: '72px',
        height: '72px',
        background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0,.4)',
        '& svg': {
            width: '36px',
            height: '36px'
        }
    },
    titleBox: {
        position: 'absolute',
        right: 0,
        color: '#999',
        marginRight: '56px',
        paddingTop: '12px',
        marginBottom: '20px',
        borderBottom: '1px solid #eee'
    },
    buttonSubmit: {
        color: 'white',
        boxShadow: '0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12)',
        backgroundColor: '#e91e63',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)',
           backgroundColor: '#e91e63'
       }
    },
    paperBillingForm: {
        padding: '20px',
        '& h5': {
            fontWeight: '700'
        }
    },
    paperAddressForm: {
        padding: '20px',
        '& h5': {
            fontWeight: '700'
        }
    },
    cardAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '30px'
    },
    genderField: {
        '& label': {
            marginBottom: 0
        }
    }
})

export default Styles;