import React, { useState, useEffect } from 'react';
import Styles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';




const useStyles = makeStyles(Styles);


const Categories = ({
    categoriesBlog,
    categoryBlogSelected,
    onCategoryChange
}) => {

    const classes = useStyles();

    const { t, i18n } = useTranslation();

    const [seleted, setSeleted] = useState({_id: -1})

    const onClickCategorieHandler = (item) => {
        setSeleted(item)
        onCategoryChange(item)
    }

    useEffect(() => {
        if (categoryBlogSelected) {
            setSeleted(categoryBlogSelected)
        }
    }, [categoryBlogSelected])



    return (
        <div className={classes.root}>
            <div className={classes.boxContent}>
                <div className={clsx(classes.boxCategoryItem, { [classes.boxCategoryItemSeleted]: seleted._id === -1 })}
                    onClick={() => {
                        onClickCategorieHandler({ _id: -1 })
                        onCategoryChange(null)
                        }
                    }>
                    <h5>{t('all')}</h5>
                </div>
                {categoriesBlog.map(item => (
                    <div className={clsx(classes.boxCategoryItem, { [classes.boxCategoryItemSeleted]: seleted._id === item._id})}
                          onClick={() => onClickCategorieHandler(item)}>
                        <h5> {item[`title_${i18n.language}`]}</h5>
                    </div>
                ))}
            </div>
        </div>
    )
}


export default Categories;
