import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Card, CardContent, Button } from '@material-ui/core';
import HighlightsProductsCard from '../HighlightsProductsCard';


const API_URL = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles(Styles);


const HighlightsProducts = ({
    newsProducts = [],
    highlightsProducts = [],
    promoProducts = []
}) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();





    const clickSeeMoreHandler = () => {
        navigate('/produtos')
    }

 


  

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    {newsProducts?.length > 0 && (
                        <div className={classes.newsProductsContainer}>
                            <h2>{t('news')}</h2>
                            <div className={classes.productsWrapper}>
                                {newsProducts.map(item =>
                                    <HighlightsProductsCard key={item?._id} product={item} />
                                )}
                            </div>
                        </div>
                    )}

                   


                    <div className={classes.newsHighlightsProducts}>
                        <h2>{t('highlights')}</h2>
                        <div className={classes.productsWrapper}>
                            {highlightsProducts.map(item =>
                                <HighlightsProductsCard key={item?._id} product={item} />
                            )}
                        </div>
                    </div>


                    <div className={classes.newsPromoProducts}>
                        <h2>{t('promo')}</h2>
                        <div className={classes.productsWrapper}>
                            {promoProducts.map(item =>
                                <HighlightsProductsCard key={item?._id} product={item} />
                            )}
                        </div>
                    </div>

                    <div className={classes.buttonBox}>
                        <Button
                            className={classes.buttonSeMore}
                            size="large"
                            color="secondary"
                            variant="contained"
                            onClick={clickSeeMoreHandler}
                        >
                            {t('see more')}
                        </Button>
                    </div>

                   

                </CardContent>
            </Card>
        </Container>
        
    );
}

export default HighlightsProducts;
