import React from 'react';
import clsx from 'clsx';
import Styles from './styles';
import { makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import ItemChildren from './ItemChildren';

const useStyles = makeStyles(Styles);


const ListChildren = ({
    variants,
    parent,
    depth,
    isBlog
}) => {



    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
        defaultMatches: true
    });
    


    return (
        <div className={clsx({
            [classes.list]: true,
            [classes.isBlog]: isBlog,
            [classes.listChildren]: depth > 0
        })}>
            {variants ? variants.reduce((items, variant) => reduceChildPages({ items, variant, depth, classes, isMobile, parent, isBlog}), []) : null }
        </div>
    )
}




const reduceChildPages = props => {
     return getItemsDesktop(props)
};

const getItemsDesktop = (props) => {
    const { items, variant, depth, classes, parent, isBlog } = props;
    

    if (variant.children && variant.children.length > 0) {
        items.push(
            <ItemChildren
                isBlog={isBlog}
                parent={parent}
                item={variant}
                depth={depth}
            >
                <ListChildren
                    isBlog={isBlog}
                    parent={variant}
                    classes={classes}
                    variants={variant.children}
                    depth={depth + 1}
                />
            </ItemChildren>
        );
    } else {
        items.push(
            <ItemChildren
                isBlog={isBlog}
                parent={parent}
                depth={depth}
                item={variant}
            />
        );
    }
    return items;
}



export default ListChildren;

