import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pt';
import Styles from './styles';
import { makeStyles, Container, Card } from '@material-ui/core';
import { Helmet } from "react-helmet";
import Loading from '../../components/Loading';
import * as ApiService from '../../service/api';
import backgroundImage from './../../assets/images/orderHistory-background.jpg';


const useStyles = makeStyles(Styles);




const OrderHistory = () => {
    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])

 

    useEffect(() => {
        const getOrdersClient = async (id) => {
            try {
                setLoading(true)
                const resp = await ApiService.getOrdersClient();
                const ords = resp.data.content;
                setOrders(ords)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }

        getOrdersClient();
    }, [])

 

    if (loading) return <Loading />
        


    return (
        <>
            <Helmet>
                <title>All4Health</title>
            </Helmet>

            <Container className={classes.root} maxWidth="xl" >
            <div className={classes.header}>
                <img src={backgroundImage} alt="thumb" />
                <h1>{t('order history')}</h1>
                <div className={classes.backgroundBoxOverlay} />
            </div>
            <Card className={classes.card}>
                {orders.map(it => (
                    <div className={classes.cardOrderItem}>
                        <div className={classes.cardOrderItemRow}>
                            <div className={classes.rowCol}>
                                <p>{t('order reference')}:&nbsp;</p>
                                <h4>{it._id}</h4>
                            </div>
                            <div className={classes.rowCol}>
                                <p>{t('order value')}:&nbsp;</p>
                                <h4>{it.total}&euro;</h4>
                            </div>
                        </div>
                        <div className={classes.cardOrderItemRow}>
                                 <div className={classes.rowCol}>
                                    <p>{t('order date')}:&nbsp;</p>
                                    <h4>{moment(new Date(it.createdAt)).format("LL")}</h4>
                                </div>
                                <div className={classes.rowCol}>
                                        <p>{t('order status')}:&nbsp;</p>
                                        <h4>{t(it.status)}</h4>
                                </div>
                        </div>
                    </div>
                ))}
                
                
              </Card>
        </Container>
        
        </>
        
    )
}

export default OrderHistory;


