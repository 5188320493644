const Styles = theme => ({
    root: {
      backgroundColor: '#000000',
      width: '100%',
      padding: '1.5em',
      zIndex: 30000,
      position: 'fixed'
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        color: 'white',
        padding: '0 40px'
    },
    buttonAccept: {
        width: '50px',
        backgroundColor: 'transparent',
        color: 'white',
        border: '1px solid rgba(255, 255, 255, 1)',
        '&:hover': {
            color: 'black',
            backgroundColor: 'white',
        },
        margin: 'auto 20px'
    },
    typography: {
        whiteSpace: 'pre-line',
        '& span': {
            fontSize: '0.8rem',
            fontFamily: 'Roboto',
            fontWeight: 400,
            lineHeight: 1.5,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem'
        },
    }
});

export default Styles;