const Styles = theme => ({
    root: {
        padding: '20px 10px',
        display: 'flex',
        margin: '10px 0'
       
    },
    imageBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100px',
        height: '100px',
        minWidth: '100px',
        minHeight: '100px',
        overflow: 'hidden',
        '& img': {
            height: '100%',
            objectFit: 'contain',
        },
    },
    infoBox: {
        marginLeft: '15px',
        '& h5': {
            margin: 0,
            lineHeight: 1
        },
        '& h6': {
            margin: 0
        }
    },
    buttonBox: {
        marginTop: '5px',
        "&.MuiButtonBase-root:hover": {
            backgroundColor: "unset !important"
        }
        
    }
})

export default Styles;