import React, { useState, useCallback, useEffect  } from 'react';
import Slider from "react-slick";
import { useNavigate } from 'react-router';
import Styles from './style';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";


const API_URL = process.env.REACT_APP_DOMAIN;




const useStyles = makeStyles(Styles);


const Banner = ({
    banners = []
}) => {

    const navigate = useNavigate()
    const classes = useStyles();
    const { i18n } = useTranslation();

    const [activeSlideIndex, setActiveSlideIndex] = useState(0)


    const [currentBanner, setCurrentBanner] = useState(null)

    useEffect(() => {
        const _banner = banners[activeSlideIndex]
        setCurrentBanner(_banner)
    }, [activeSlideIndex, banners])



    const NextArrow = (props) => {
        const { style } = props;
        return (
            <div className={classes.arrowBox} onClick={ev => {
                ev.stopPropagation();
                nextHandle()
             }}
                style={{ ...style, right: '20px' }}
                >
                <ArrowForwardIosIcon />
            </div>
        );
    }

    const PrevArrow = (props) => {
        const { style } = props;
        return (
            <div className={classes.arrowBox} onClick={ev => {
                ev.stopPropagation();
                previousHandle()
            }}
                style={{ ...style, transform: 'rotateY(180deg)', left: '20px'}}
               >
                <ArrowForwardIosIcon />
            </div>
        );
    }

    /* Handler click next slider */
    const nextHandle = useCallback(() => {
        setActiveSlideIndex(previous => {
            if (previous >= banners.length - 1) {
                return 0;
            } else {
                return previous + 1;
            }
        });
    }, [banners.length])


    /* Handler click previous slider */
    const previousHandle = () => {
        setActiveSlideIndex(previous => {
            if (previous === 0) {
                return banners.length - 1;
            } else {
                return previous - 1;
            }
        });
    }



    const SLIDER_SETTINGS = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 5000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: current => setActiveSlideIndex(current),
        beforeChange: (current, next) => console.log(),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };


    const onClickBannerHandler = (item) => {
        if (item.type === 'products') {
            navigate(`produtos/${item.reference}`)
        } else if (item.type === 'blog') {
            navigate(`blog/post/${item.reference}`);
        } else if (item.type === 'variants') { // variants products
            navigate('/produtos', { replace: true, state: { reference:  item?.reference } })
        } else if (item.type === 'categories') { //blog categories
            navigate('/blog', { replace: true, state: { reference:  item?.reference } })
        }
    }


    return (
        <div className={classes.root}>
            <div className={classes.bannerContainer}>
                <Slider {...SLIDER_SETTINGS}>
                    {banners.map(it => (
                       <div key={it?._id}></div>
                    ))}
                </Slider>
                <div className={classes.backgroundBox}>
                    <LazyLoadImage src={`${API_URL}/warehouse/${currentBanner?.image['filename']}`} alt="Imagem"  effect="blur" />
                </div>
                <div key={currentBanner?._id} className={classes.banner} onClick={() => onClickBannerHandler(currentBanner)}>
                    <div className={classes.bannerInner}>
                        <div className={classes.subtitle}>
                            <h3>{currentBanner?.title_pt}</h3>
                        </div>
                        <div className={classes.title}>
                            <h1> {currentBanner?.subtitle_pt}</h1>
                        </div>
                        <div className={classes.slidesPage}>
                            <span>{activeSlideIndex + 1}</span>
                            <span>/{banners?.length}</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}




export default Banner

