const MuiDrawer = {
    paper: {
        padding: '10px',
        alignItems: 'flex-start',
        borderRadius: '0px 10px 10px 0px',
        boxShadow: '1px 1px 10px #EEEEEE',
        overflowY: 'visible'
    }
};

export default MuiDrawer;
