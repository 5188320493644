import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const gray = '#dee2e6';
const verylightgray = '#eeeeee';
const darkgray = '#999999';



const palette =  {
    black,
    white,
    gray,
    verylightgray,
    darkgray,
    primary: {
        contrastText: white,
        dark: '#105f69',
        main: '#178896',
        light: '#73b7c0'
    },
    secondary: {
        contrastText: white,
        dark: '#ff0022',
        main: '#e91e63',
        light: '#f06595'
    },
    error: {
        contrastText: white,
        dark: '#b10018',
        main: '#ff0022',
        light: '#ff4e66'
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600]
    },
    link: '#28386b',
    icon: colors.blueGrey[600],
    background: {
        default: '#F4F6F8',
        paper: white
    },
    divider: colors.grey[200],
    shadow: '0px 2px 4px -1px rgb(0 0 0 /20%), 0px 4px 5px 0px rgb(0 0 0 /14%), 0px 1px 10px 0px rgb(0 0 0 /12%)'
};

export default palette;