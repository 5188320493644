const Styles = theme => ({
    submitBox: {
        padding: '10px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            width: 'auto',
            margin: '0 10px'
        }

    }
})

export default Styles;