import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { makeStyles, Button } from '@material-ui/core';
import * as ApiService from './../../service/api';
import Styles from './styles';
import { useApp } from "../../context/app";


const useStyles = makeStyles(Styles);


const VerifyEmail = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [verified, setVerified] = useState(false)
  const { setAppLoading } = useApp()


  useEffect(() => {

    const verifyEmail = async (token) => {
      setAppLoading(true)
      const dataPost = {
        token: token
      }
      try {
        await ApiService.verifyEmail(dataPost)
        setVerified(true)
        setAppLoading(false)

      } catch (error) {
        setVerified(false)
        setAppLoading(false)
      }
    }

    let params = queryString.parse(location.search)
    let token = params?.token

    if (token) {
      verifyEmail(token)
    } else {
      navigate('/')
    }

  }, [])
  

  const clickCompleteRegisterHandler = () => {
    navigate('/')
  }

  return (
      <div className={classes.root}>
      {verified ? (
        <>
          <div className={classes.content}>
              <h1>{t('verify email title')}</h1>
              <p>{t('verify email info')}</p>
              <div>
                  <Button
                    className={classes.button}
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={clickCompleteRegisterHandler}
                  >
                    {t('verify email button')}
                  </Button>
              </div>
          </div>
          <div className={classes.overlay} />
        </>
        ) : null }
      </div>
  )
}

export default VerifyEmail;
