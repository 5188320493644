const Styles = theme => ({
    accordion: {
        boxShadow: 'none',
        borderRadius: '0px !important',
        border: 'none !important',
        borderBottom: '1px solid #e0e0e0',
        margin: '10px 0',
        '& .Mui-expanded': {
            color: '#e91e63',
            fontWeight: 500
        },
        '& .MuiAccordionSummary-root': {
            '&:hover': {
                color: '#e91e63',
                fontWeight: 500,
                '& .MuiButtonBase-root': {
                    color: '#e91e63',
                    fontWeight: 500
                }
            }
        },
        '& h4': {
            margin: '0 0'
        }
    }
})

export default Styles;