import React, { useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import {
    makeStyles,
    Typography,
    Grid,
    List,
    Box
} from '@material-ui/core';
import Styles from './styles';
import * as ApiService from './../../service/api';

const useStyles = makeStyles(Styles);


const Footer = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [social, setSocial] = useState(null);

    useEffect(() => {
        const getSocialData = async () => {
            try {
                const resp = await ApiService.getSocial();
                setSocial(resp.data.content);
            } catch (error) {}
        }
        getSocialData();
    }, [])



    return (
         <Box className={classes.root} maxWidth="xl" fullWidth>
             <Grid container spacing={0}>
                <Grid item className={classes.gridItem} xs={12} sm={12} md={3} lg={3} >
                    <Typography className={classes.title}>
                       <Box fontWeight="fontWeightBold" m={0}>
                          {t('help')}
                      </Box>
                    </Typography>
                    <List className={classes.columnList} column={2}>
                        <Link to={{ pathname: '/aconselhamento-gratuito', search: `?type=aconselhamento-gratuito` }}>{t('free advice')}</Link >
                        <Link to={{ pathname: '/comprar-na-all4Health', search: `?type=comprar` }}>{t('buy in all4health')}</Link >
                        <Link to={{ pathname: '/cartao-presente', search: `?type=cartaooferta` }}>{t('gift card')}</Link >
                        <Link  to={{ pathname: '/pagamento', search: `?type=pagamentos`}}>{t('payment')}</Link >
                        <Link  to={{ pathname: '/envio', search: `?type=envio`}}>{t('shipment')}</Link >
                        <Link to={{ pathname: '/troca-e-devolucao', search: `?type=trocas-devolucoes` }}>{t('exchanges and returns')}</Link >
                    </List>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={12} md={3} lg={3} >
                    <Typography className={classes.title}>
                        <Box fontWeight="fontWeightBold" m={0}>
                            {t('follow-us')}
                        </Box>
                    </Typography>
                    {social ? (
                        <List className={classes.columnList} column={2}>
                            {social.map(item => (
                                <a href={item.url} target="_blank">{t(item.type)}</a >
                            ))}
                        </List>
                    ) : null}
                   
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={12} md={3} lg={3} >
                    <Typography className={classes.title}>
                      <Box fontWeight="fontWeightBold" m={0}>
                            {t('company')}
                      </Box>
                      </Typography>
                    <List className={classes.columnList} column={2}>
                        <Link  to={{ pathname: '/sobre-nos', search: `?type=quem-somos` }}>{t('about us')}</Link >
                        <Link to={{ pathname: '/escritorios', search: `?type=escritorios` }}>{t('offices')}</Link >
                        <Link to={{ pathname: '/contact-us' }}>{t('contact us')}</Link >
                    </List>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={12} md={3} lg={3} >
                    <Typography className={classes.title}>
                        <Box fontWeight="fontWeightBold" m={0}>
                            {t('policies')}
                        </Box>
                    </Typography>
                    <List className={classes.columnList} column={2}>
                        <Link  to={{ pathname: '/politica-de-privacidade', search: `?type=privacidade` }}>{t('privacy policy')}</Link >
                        <Link  to={{ pathname: '/condicoes-de-compra', search: `?type=condicoescompra` }}>{t('purchase conditions')}</Link >
                        <a href={'https://www.livroreclamacoes.pt/'} target="_blank" rel="noopener noreferrer" >{t('complaint book')}</a >
                    </List>
                </Grid>
             </Grid>
        </Box>
    );
}



export default Footer;