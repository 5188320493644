
const Styles = theme => ({
    root: {
        padding: '0',
        marginTop: '60px',
        backgroundColor: '#F7F9FC',
        width: '100%',
        maxWidth: '100%'
    },

    card: {
        padding: '30px 10px',
        margin: '30px 20px',
        borderRadius: '6px',
        backgroundColor: 'white',
        boxShadow: '0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)',
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    header: {
        color: 'white',
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        fontSize: '12px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        minHeight: '80px',
        minWidth: '80px',
        alignItems: 'center',
        borderRadius: '4px',
        flexDirection: 'column',
        textTransform: 'uppercase',
        justifyContent: 'center',
        backgroundColor: '#e91e63',
        marginTop: '-65px',
        '& svg': {
            width: '1.8em',
            height: '1.8em'
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '60px',
            minWidth: '60px',
            '& svg': {
                width: '1.4em',
                height: '1.4em'
            },
        },
    },
    container: {
        padding: '50px 0px 10px'
    },
    infoTotalWrapper: {
        padding: '10px 60px',
        backgroundColor: '#fafafa',
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
        '& h3': {

        },
        '& h6': {

        },
        '& h2': {

        }
    },
    infoTotalWrapperInner: {
        width: '250px'
    },
    subTotalBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        '& h3': {
            margin: 0
        }
    },
    shipBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    promoCodeBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    totalBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        '& h2': {
            margin: 0
        }
    },
    actionsWrapper: {
        padding: '30px 40px',
        display: 'flex',
        flexDirection: 'row',
        '& button': {
            width: '180px'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            '& button': {
                margin: '10px 0px'
            },
        }
    },
    buttonClearCart: {
        marginLeft: 'auto',
        margin: '0 5px',
        color: 'white',
        backgroundColor: '#f44336',
        paddingTop: '11px',
        paddingBottom: '11px',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)',
            backgroundColor: '#f44336',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            marginLeft: '0px',
        }
    },
    buttonBackStore: {
        color: 'white',
        backgroundColor: '#e91e63',
        margin: '0 5px',
        paddingTop: '11px',
        paddingBottom: '11px',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)',
            backgroundColor: '#e91e63',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        }
    },
    buttonBuy: {
        margin: '0 5px',
        color: 'white',
        backgroundColor: '#4caf50',
        paddingTop: '11px',
        paddingBottom: '11px',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)',
            backgroundColor: '#4caf50',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        }
    },
    boxEmptyCart: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .icon-shopping-cart': {
            fontSize: '200px',
            color: '#178896',
            marginBottom: '40px'
        },
        '& h3': {
            marginBottom: '50px'
        }
    },
    paymentMethods: {
        margin: '10px 20px 5px',
        padding: '0px 30px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderTop: '2px solid rgba(0,0,0,0.1)',
        '& h4': {
            textAlign: 'center',
            textTransform: 'uppercase'
        }
    },
    areaPromoCode: {
        margin: '20px 40px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'flex-end',
        '& button': {
            width: 'auto',
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            margin: '20px 5px',
            justifyContent: 'center',
            '& button': {
                width: 'auto',
                marginLeft: '5px'
            },
        }
    },

})

export default Styles;