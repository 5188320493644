const Styles = theme => ({
    paperTableInfo: {
        borderRadius: '5px'
    },
    paperTableInfoBorder: {
        border: '1px solid #ddd',
    },
    nutriTableInfo: {
        padding: '20px'
    },
    infoLine: {
        '& span:first-child': {
            textAlign: 'left',
            color: '#5f5f5f',
            fontSize: '13px',
            marginBottom: '12px',
            fontWeight: 'bold'
        },
        '& span:nth-child(2)': {
            textAlign: 'left',
            color: '#333',
            fontSize: '13px'
        }
    },
    paperNutriValues: {
        fontSize: '11px',
        color: '#333'
    },
    nutriTabHeader: {
        lineHeight: '32px',
        padding: '10px 20px',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: '#00acc1',
        color: 'white'
    },
    tabPanel: {
        padding: 0,
        '& .MuiBox-root': {
            padding: 0
        }
    }
});

export default Styles;