import React, { useEffect }  from 'react'
import Styles from './styles';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { makeStyles, Grid, Dialog, DialogContent, DialogTitle, FormControl, TextField, Button, Paper, Container} from '@material-ui/core';
import { useApp } from "../../context/app";
import { useAuth } from "../../context/auth";
import SubmitButton from '../../components/SubmitButton';



const FE_URL = process.env.REACT_APP_FE_DOMAIN;

const useStyles = makeStyles(Styles);



const PopupForgotPassword = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { setOpenLogin, showNotifications, setOpenRecoverPassword, openRecoverPassword} = useApp()
    const { forgotPassword } = useAuth()



    useEffect(() => {
       setOpenLogin(false)
    }, [])


    const formik = useFormik({
            initialValues: {
                email: '',
            },
            validationSchema: Yup.object().shape({
                email: Yup.string()
                    .email(t('invalid email'))
            }),
        onSubmit: async (values, { setErrors }) => {
                    try {
                        const postData = {
                            email: values.email,
                            redirectUrl: `${FE_URL}/conta/alterar-password`
                        }
                        await forgotPassword(postData);
                        const notf = { type: 'success', title: t('recover password'), message: t('success recover password request') }
                        showNotifications(notf)
                        setOpenRecoverPassword(false)
                    } catch (error) {
                        const msg = error
                        const notf = { type: 'error', title: t('recover password'), message: msg }
                        showNotifications(notf)
                        
                    }
                }
            }
    )



    return (

        <Dialog
            fullWidth
            maxWidth="sm"
            disableBackdropClick={false}
            onClose={() => setOpenRecoverPassword(false)}
            open={openRecoverPassword}
            aria-labelledby="popup-info-title"
            aria-describedby="popup-info-description"
        >
            <DialogTitle id="popup-info-title" className={classes.title}>
                {t('recover password')}
            </DialogTitle>
            <DialogContent>
                <Container className={classes.root} maxWidth="sm">
                <form onSubmit={formik.handleSubmit}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="email"
                                placeholder={`${t('email')} *`}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.email && formik.touched.email}
                                helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                required
                            />
                        </FormControl>
                    </Grid>
                   
                    <Grid item xs={12}>
                        <Paper className={classes.submitPaper} elevation={0}>
                                <SubmitButton className={classes.buttonSubmit}
                                    isSubmitting={formik.isSubmitting}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary">
                                    {t('recover password')}
                                </SubmitButton>
                        </Paper>
                    </Grid>
                </form >
                </Container>
            </DialogContent>
        </Dialog>
       
    )
}


export default PopupForgotPassword;
 