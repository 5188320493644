const Styles = theme => ({
   detail: {
       padding: '0 20px',
        '& div:last-child': {
            borderBottom: 0
        }
   },
   line: {
        borderBottom: '1px solid',
        borderColor: theme.palette.secondary.main,
        padding: '7px 0',
        display: 'flex',
        fontSize: '12px',
            '& :first-child': {
                marginRight: 'auto'
            }
   },
   rules: {
    'borderTop': '4px solid',
    borderColor: theme.palette.secondary.main,
    padding: '15px 20px',
    lineHeight: '13px',
    fontSize: '11px',
   }
});

export default Styles;