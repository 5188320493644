import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
import Banner from '../../components/Banner';
import CarrouselBlog from '../../components/CarrouselBlog';
import Separator from '../../components/Separator';
import HighlightsProducts from '../../components/HighlightsProducts';
import Loading from '../../components/Loading';
import * as ApiService from '../../service/api';
import * as UtilService from '../../service/utils';


const Home = () => {


    const [loading, setLoading] = useState(true)
    const [banners, setBanners] = useState([])
    const [posts, setPosts] = useState([])
    const [separator, setSeparator] = useState(null)
    const [newsProducts, setNewsProducts] = useState([]);
    const [highlightsProducts, setHighlightsProducts] = useState([]);
    const [promoProducts, setPromoProducts] = useState([]);



    useEffect(() => {
        const fetchBanners = async () => {
            const params = { placement: 'carousel' }
            return  await ApiService.getBanners(params);
        }

        const getPosts = async () => {
            const params = {sort: 'createdAt',  order: 'desc', limit: 10  }
             return await ApiService.getBlogPosts(params);
        }

      
        const fetchSeparator = async () => {
            const params = { placement: 'separator' }
            return await ApiService.getBanners(params);
        }

        const fetchNewsProducts = async () => {
            const params = { perPage: 10, page: 0, news: true };
            return ApiService.getAllProducts(params);
        }
        const fetchPromotedProducts = async () => {
            const params = { perPage: 10, page: 0, promoted: true };
            return ApiService.getAllProducts(params);
        }
        const fetchFeaturedProducts = async () => {
            const params = { perPage: 10, page: 0, featured: true };
            return ApiService.getAllProducts(params);
        }






        const fetchAllData = () => {
            setLoading(true)
            axios.all([
                fetchBanners(),
                getPosts(),
                fetchSeparator(),
                fetchNewsProducts(),
                fetchPromotedProducts(),
                fetchFeaturedProducts(),

            ]).then(axios.spread((...responses) => {
                const responseBanners = responses[0]
                const responseBlogPosts = responses[1]
                const responseSeparator = responses[2]
                const responseNewsProducts = responses[3]
                const responseHighlightsProducts = responses[4]
                const responsePromoProducts = responses[5]
                const _banners = responseBanners.data.content;
                const _posts = responseBlogPosts.data.content.results;
                const _separator = responseSeparator.data.content[0];
                let _newsProducts = responseNewsProducts.data.content.results;
                _newsProducts = UtilService.processProducts(_newsProducts)

                let _highlightsProducts = responseHighlightsProducts.data.content.results;
                _highlightsProducts = UtilService.processProducts(_highlightsProducts)

                let _promoProducts = responsePromoProducts.data.content.results;
                _promoProducts = UtilService.processProducts(_promoProducts)

                setBanners(_banners)
                setPosts(_posts)
                setSeparator(_separator)
                setNewsProducts(_newsProducts)
                setHighlightsProducts(_highlightsProducts)
                setPromoProducts(_promoProducts)
                setLoading(false)

            })).catch(errors => {
                setLoading(false)
            })
        }
        fetchAllData()
    }, [])

    if(loading) return <Loading />

    return (
        <>
             <Helmet>
                <title>All4Health</title>
            </Helmet>
            <Banner banners={banners} />
            <CarrouselBlog posts={posts} />
            <Separator separator={separator} />
            <HighlightsProducts
                newsProducts={newsProducts}
                highlightsProducts={highlightsProducts}
                promoProducts={promoProducts}
            />
        </>
    )
}




export default Home

