import React, { useState } from 'react';
import Styles from './styles';
import Slider from "react-slick";
import { makeStyles } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowBackIos';

const API_URL = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles(Styles);


const SlideShow = ({
    images = []
}) => {

    const classes = useStyles();
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)



    const NextArrow = (props) => {
        const { style, onClick } = props;
        return (
            <div className={classes.arrowBox}
                style={{ ...style, right: '20px' }}
                onClick={onClick} >
                <ArrowIcon />
            </div>
        );
    }

    const PrevArrow = (props) => {
        const { style, onClick } = props;
        return (
            <div className={classes.arrowBox}
                style={{ ...style, transform: 'rotateY(180deg)', left: '20px' }}
                onClick={onClick} >
                <ArrowIcon />
            </div>
        );
    }


    const SLIDER_SETTINGS = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 5000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: current => setActiveSlideIndex(current),
        beforeChange: (current, next) => console.log(),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };


  return (
      <div className={classes.root}>
          <div className={classes.bannerContainer}>
              <Slider {...SLIDER_SETTINGS}>
                  {images.map(it => (
                      <div key={it?._id}></div>
                  ))}
              </Slider>
              <div className={classes.backgroundBox}>
                  <img src={`${API_URL}/warehouse/${images[activeSlideIndex]?.filename}`} alt="thumb" />
              </div>
          </div>
      </div>
  )
}

export default SlideShow;
