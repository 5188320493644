import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Dashboard from '../screens/Home';
import FooterPage from '../screens/FooterPage';
import Bolog from '../screens/Blog';
import BologDetail from '../screens/BlogDetail';
import Products from '../screens/Products';
import ProductDetail from '../screens/ProductDetail';
import Cart from '../screens/Cart';
import Checkout from '../screens/Checkout';
import CheckoutResume from '../screens/CheckoutResume';
import VerifyEmail from '../screens/VerifyEmail';
import ResetPassword from '../screens/ResetPassword';
import Help from '../screens/Help';
import OrderHistory from '../screens/OrderHistory';



const routesFooter = [
    '/politica-de-privacidade',
    '/condicoes-de-compra',
    '/condicoes-de-cartao-oferta',
    '/sobre-nos',
    '/escritorios',
    '/lojas',
    '/envio',
    '/troca-e-devolucao',
    '/pagamento',
    '/comprar-na-all4Health',
    '/cartao-presente',
    '/aconselhamento-gratuito'
]




const AuthRoutes = () => {


    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/produtos" element={<Products />} />
            <Route path="/produtos/:id" element={<ProductDetail />} />
            <Route path="/blog" exact element={<Bolog />} />
            <Route path="/blog/post/:id" element={<BologDetail />} />
            <Route path="/carinho" exact element={<Cart />} />
            <Route path="/checkout" exact element={<Checkout />} />
            <Route path="/checkout/resumo" exact element={<CheckoutResume />} />
            <Route path="/conta/verificar-email" exact element={<VerifyEmail />} />
            <Route path="/conta/alterar-password" exact element={<ResetPassword />} />
            <Route path="/conta/encomendas/historico" exact element={<OrderHistory />} />
            <Route path="/ajuda" exact element={<Help />} />


            {routesFooter.map(item =>
                <Route key={item} path={item} element={<FooterPage />} />
            )}
            <Route path='*' element={<Navigate to="/" />} />
        </Routes>
    )
}

export default AuthRoutes;



