
export const processProducts = (products) => {
    try {
        const _products = products.map(product => {
            const discount = Number(product.discount)
            const iva = Number(product.iva);
            const price = Number(product.price?.replace(',', '.'));
            const percentageIva = 1 + (iva / 100);

            let finalPrice = 0
            let oldPrice = 0
            let hasDiscount = false
            let hasStock = product.stock > 0

            if (discount > 0) {
                hasDiscount = true
                const priceDiscount = (discount / 100) * price;
                const priceWithDiscount = parseFloat(price - priceDiscount).toFixed(2);
                finalPrice = parseFloat((priceWithDiscount * percentageIva)).toFixed(2);
                oldPrice = parseFloat((price * percentageIva)).toFixed(2)
            } else {
                finalPrice = parseFloat((price * percentageIva)).toFixed(2)
                oldPrice = parseFloat((price * percentageIva)).toFixed(2)
            }
            return {
                ...product,
                finalPrice: finalPrice,
                oldPrice: oldPrice,
                hasDiscount: hasDiscount,
                hasStock: hasStock
            }
        })
        return _products;
    } catch (error) {
        return []
    }
}