export default theme => ({
    root: {
        margin: '30px 0',
        padding: '25px',
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h3': {
            margin: '0px 0px 0px',
            fontSize: '13px',
            textTransform: 'uppercase'
        },
        '& h5': {
            fontSize: '12px',
            lineHeight: '18px',
            margin: '20px 0px 10px'
        }
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    buttonSubmit: {
        margin: '20px auto 0',
        boxShadow: '0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px - 2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12)',
        backgroundColor: '#f44336'
    }

})