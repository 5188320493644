import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx'
import { makeStyles, IconButton } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import { useApp } from "../../context/app";


const API_URL = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles(Styles);


const HighlightsProductsCard = ({
    product
}) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const { addToCart } = useApp()



    const addToChartHandler = () => {
        addToCart(product, 1)
    }

    const handleClickProduct = () => {
        navigate(`/produtos/${product.slug}`)
    }

    if (!product) return null

    return (
            <div className={classes.root}>
            <div className={clsx(classes.infoWaraper)}>
                    <div className={classes.titleBox} onClick={handleClickProduct}>
                        <h4> {product[`title_${i18n.language}`]}</h4>
                    </div>
                    <div className={classes.priceBox}>
                    {product?.hasDiscount ? (
                        <div className={classes.priceDiscountContainer}>
                            <h4>{product?.oldPrice}&euro;</h4>
                            <h3>{product?.finalPrice}&euro;</h3>
                        </div>
                    ) : <h3>{product?.finalPrice}&euro;</h3>
                    }
                        <IconButton onClick={addToChartHandler} className={classes.shoppingCartIcon}>
                            <ShoppingCartIcon />
                        </IconButton>
                    </div>   
                </div>

                <div className={clsx(classes.boxImage)} onClick={handleClickProduct}>
                    <img src={`${API_URL}/warehouse/${product.thumbnail['filename']}`} alt="thumb" />
                </div>
            </div>
  )
}



export default HighlightsProductsCard
