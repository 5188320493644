import React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './style';
import { makeStyles, Grid } from '@material-ui/core';
import spinner from './../../assets/icons/spinner.gif';



const useStyles = makeStyles(Styles);


const Loading = () => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid
            className={classes.root}
            container
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center', flexGrow: 1, height: '100%'}}>
            <Grid item>
                <img src={spinner} alt="" />
            </Grid>
        </Grid>
    )
}

export default Loading;