
const Styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        width: '100%',
        maxHeight: '350px',
        height: '350px',
        position: 'relative',
        textAlign: 'center',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        '& h1': {
            position: 'absolute',
            top: '30%',
            left: 0,
            right: 0,
            zIndex: 3,
            color: 'white',
            textShadow: '2px 2px 4px #000000',
            fontSize: '3.3rem',
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: '200px',
            height: '200px',
            '& h1': {
                fontSize: '2rem',
            },
        },
    },
    containerBox: {
        maxWidth: '1500px',
        paddingLeft: '100px',
        paddingRight: '100px',
        paddingTop: '40px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
            '& h1': {
                fontSize: '1.8rem',
            },
        },
    },
    container: {
        paddingBottom: '40px',
        '& h1': {

        }
    },
    dividerBox: {
        width: '100%',
        maxHeight: '500px',
        position: 'relative',
        textAlign: 'center',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    backgroundBoxOverlay: {
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        top: 0
    }
})

export default Styles;