import React from 'react';
import PublicLayout from './layout.public';
import { useAuth } from '../context/auth';
import { useApp } from '../context/app';

import Notification from '../components/Notification';
import Loading from '../components/Loading';


const Layout = () => {

    const { loading } = useAuth();
    const { appLoading } = useApp();


    if (loading) {
        <div>Loading . . .</div>
    }


    return (
        <React.Fragment>
            <PublicLayout />
            <Notification />
            {appLoading && <Loading />}
        </React.Fragment>
    )
}

export default Layout;