import React, { useState, useEffect } from 'react';
import Styles from './style';
import { makeStyles} from '@material-ui/core';
import MaterialTable  from 'material-table';
import { useTranslation } from 'react-i18next';


const API_URL = process.env.REACT_APP_DOMAIN;


const useStyles = makeStyles(Styles);

const Table = ({ cart }) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [dataTable, setDataTable] = useState([])





    useEffect(() => {
        const data = cart.map(item => {
            return {
                product: item.product,
                imageUrl: `${API_URL}/warehouse/${item?.product?.thumbnail?.filename}`,
                description: item?.product[`title_${i18n.language}`],
                price: `${item?.product?.finalPrice}€`,
                iva: `${item?.product?.iva}%`,
                totalPrice: `${Number(item?.product?.finalPrice * item.quantity).toFixed(2)}€`,
                quantity: item.quantity
            }
        })
        setDataTable(data)
            
       
    }, [cart, i18n])

    return (
        <div className={classes.root}>
            <MaterialTable
                columns={[
                    { title: '', field: 'product', hidden: true},
                    {
                        title: t('item'), field: 'imageUrl', render: rowData => (
                            <div className={classes.imageBox}>
                                <img src={rowData.imageUrl} alt="thumb" />
                            </div>
                    ) },
                    {
                        title: t('description'), field: 'description', render: rowData => (
                            <h4 className={classes.description}>{rowData.description}</h4>
                    ) },
                    {
                        title: t('price'), field: 'price', render: rowData => (
                            <h3 className={classes.price}>{rowData.price}</h3>
                    )},
                    { title: t('iva'), field: 'iva' },
                    {
                        title: t('quantity'), field: 'quantity', render: rowData => (
                            <h3 className={classes.price}>{rowData.quantity}</h3>
                    ) },
                    {
                        title: t('total price'), field: 'totalPrice', render: rowData => (
                            <h3 className={classes.finalPrice}>{rowData.totalPrice}</h3>
                    )},
                    
                ]}
                data={dataTable}
                options={{
                    actionsColumnIndex: -1,
                    paging: false,
                    search: false,
                    toolbar: false,
                    showTitle: false,
                    rowStyle: {
                        borderTop: '2px solid rgba(0,0,0,0.05)',
                    },
                    headerStyle: {
                        fontSize: '16px',
                        fontWeight: 700
                    },
                }}
                localization={{
                    header: {
                        actions: null
                    }
                }}
            />
        </div>
    )
}




export default Table

