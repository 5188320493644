import backgroundImage from './../../assets/images/gridProducts-background.jpg';

const Styles = theme => ({
    root: {
        padding: '0',
        marginTop: '0',
        backgroundColor: '#F7F9FC',
        width: '100%',
        maxWidth: '100%'
    },
    header: {
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '320px',
        background: `url(${backgroundImage}) no-repeat center center`,
        backgroundPosition: '50%',
        backgroundSize: 'cover',
        '& h1': {
            minHeight: '32px',
            fontWeight: 700,
            textShadow: '2px 2px 4px #000000'
        },
        '& h4': {
            minHeight: '32px',
            fontWeight: 700,
            textShadow: '2px 2px 4px #000000'
        },
        [theme.breakpoints.down('sm')]: {
            height: '220px',
            '& h1': {
                
            },
            '& h2': {
                
            },
        },
    },
    card: {
        marginTop: '-30px',
        margin: '0 30px',
        borderRadius: '6px',
        backgroundColor: 'white',
        boxShadow: '0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)',
    },
    containerProducts: {
        padding: '0 5px',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0px 0',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    productsHeader: {
        padding: '20px 30px',
        display: 'flex',
        justifyContent: 'flex-end',
        
    },
    orderBox: {
        display: 'flex',
        '& h5': {
            marginRight: '15px'
        }
    },
    categoriesBox: {
        height: '100%'
    },
    titleInfoBox: {
        margin: '0px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h2': {
            margin: '0px !important',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    paginationBox: {
        padding: '20px 40px',

    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '20px 40px',
        '& .MuiPaginationItem-page.Mui-selected': {
            boxShadow: '0 4px 5px 0 rgba(156, 39, 176, 0.14), 0 1px 10px 0 rgba(156, 39, 176, 0.12), 0 2px 4px - 1px rgba(156, 39, 176, 0.2)',
            borderColor: '#9c27b0',
            backgroundColor: '#9c27b0',
            color: 'white'
        }
    },
    bestSellersWrapper: {
        padding: '0 0px'
    },
    bestSellersTitle: {
        marginLeft: '30px'
    },
    containerBestSellers: {
        padding: '0 0px',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0px 0',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
})

export default Styles;