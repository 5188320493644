import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { getDriver } from 'localforage';

const API_URL = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles(Styles);


const Separator = ({
    separator = null
}) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { i18n } = useTranslation();



 


    if (!separator) {
        return null;
    }
    
    const onClickBannerHandler = () => {
        const item = separator;
        if (item.type === 'products') {
            navigate(`produtos/${item.reference}`)
        } else if (item.type === 'blog') {
            navigate(`blog/post/${item.reference}`);
        } else if (item.type === 'variants') { // variants products
            navigate('/produtos', { replace: true, state: { reference: item?.reference } })
        } else if (item.type === 'categories') { //blog categories
            navigate('/blog', { replace: true, state: { reference: item?.reference } })
        }
    }


    return(
        <div className={classes.root} onClick={onClickBannerHandler}>
                <div className={classes.gridContainer} container justify="center" >
                        <img src={`${API_URL}/warehouse/${separator?.image?.filename}`} alt="thumb" />
                        <div className={classes.gridForContent}>
                            <div className={classes.contentMain}>
                                {separator[`title_${i18n.language}`]}
                            </div>
                            <div className={classes.contentSec}>
                                <div dangerouslySetInnerHTML={{ __html: separator[`text_${i18n.language}`] }} />
                            </div>
                        </div>
                        <div className={classes.backgroundBoxOverlay} />    
                </div>
        </div>
    );
}

export default Separator;
