import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import { makeStyles, Container, Tabs, Tab, TabPanel } from '@material-ui/core';
import Loading from '../../components/Loading';
import * as ApiService from '../../service/api';
import backgroundImage from './../../assets/images/help-background.jpg';


const useStyles = makeStyles(Styles);




const Help = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    const [index, setIndex] = React.useState(0);
 

    useEffect(() => {
        const getPage = async (id) => {
            try {
                setLoading(true)
                const resp = await ApiService.getHelps();
                setData(resp.data.content);
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        getPage()
    }, [])

    const handleChange = (event, newValue) => {
        setIndex(newValue);
    };


    if (loading) return <Loading />
        


    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <img src={backgroundImage} alt="thumb" />
                <h1>{t('help')}</h1>
                <div className={classes.backgroundBoxOverlay} />
            </div>
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.containerTabs}>
                    <Tabs value={index} onChange={handleChange} aria-label="tabs" scrollButtons="auto" variant="scrollable">
                        {data?.map((it, index) => (
                            <Tab label={ it[`title_${i18n.language}`] } {...a11yProps(0)} />
                        ))}
                    </Tabs>
                </div>
                <div className={classes.contentWrapper}>
                    <div dangerouslySetInnerHTML={{ __html: data[index][`text_${i18n.language}`] }} />
                </div>
            </Container>
        </div>
    )
}

export default Help;


function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}