import React, { useEffect, useState } from 'react';
import Styles from './style';
import { makeStyles, Container, Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as ApiService from '../../service/api';
import { useLocation } from 'react-router-dom';
import BlogArticleCard from './BlogArticleCard';
import BlogPostCard from './BlogPostCard';
import BlogDivider from './BlogDivider';
import Categories from './Categories';
import Loading from '../../components/Loading';





const useStyles = makeStyles(Styles);

const Blog = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const [loading, setLoading] = useState(true)
    const [lastPost, setLastPost] = useState([]);
    const [allPost, setAllPost] = useState([]);
    const [categoriesBlog, setCategoriesBlog] = useState([]);
    const [featuredProducts, setFeaturedProducts] = useState(null);
    const [categorySelected, setCategorySelected] = useState(null);
  

    let variant = null;
    try {
        variant = location.state.variant;
    } catch (e) {
        variant = null;
    }




    const onCategoryChange = async (category) => {
        const params = {
            sort: 'createdAt',
            order: 'desc',
            limit: 30,
            page: 0
        }
        if (category) {
            params['category'] = category._id
        }
        try {
            const resp = await ApiService.getBlogPosts(params);
            setAllPost(resp.data.content.results)
        } catch (error) {
            setAllPost([])
        }
    }





    useEffect(() => {
        const getLastPosts = () => {
            const params = {
                sort: 'createdAt',
                order: 'desc',
                limit: 2
            }
            return ApiService.getBlogPosts(params);
        }
        const getAllPosts = () => {
            const params = {
                sort: 'createdAt',
                order: 'desc',
                limit: 30,
                page: 0
            }
            if (variant) {
                params['category'] = variant?._id
            }
            return ApiService.getBlogPosts(params);
        }

        const fetchFeaturedProducts = async () => {
            let params = { perPage: 2, page: 0, featured: true };
            return ApiService.getAllProducts(params);
        }

        const getCategoriesBlogs = async () => {
            return ApiService.getAllCategoriesBlog();
        }

        const getData = async () => {
            try {
                setLoading(true)
                const [res1, res2, res3, res4] = await Promise.all([getLastPosts(), getAllPosts(), fetchFeaturedProducts(), getCategoriesBlogs()]);
                setLastPost(res1.data.content.results)
                setAllPost(res2.data.content.results)
                setFeaturedProducts(res3.data.content.results)
                setCategoriesBlog(res4.data.content)
                if (variant) {
                    const category = res4.data.content.filter(it => it._id === variant?._id)[0]
                    setCategorySelected(category)
                } else {
                    setCategorySelected(null)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }

        getData()
     
    }, [variant])


   
    if(loading) return <Loading />
   

    return (
        <Container className={classes.root} maxWidth="xl" >
            <div className={classes.header}>
                <div className={classes.boxTitle}>
                    <h1>{t('all4Health articles')}</h1>
                </div>
            </div>
            <Card className={classes.card}>

                <div className={classes.cardHeader}>
                    <Categories categoriesBlog={categoriesBlog} onCategoryChange={onCategoryChange} categoryBlogSelected={categorySelected} />
                </div>

                {lastPost && lastPost?.length > 0 && (
                    <div className={classes.lastPostWrapper}>
                        <h2>{t('last posts')}</h2>
                        <div className={classes.lastPostContainer}>
                            {lastPost.map(item => (
                                <BlogPostCard data={item} />
                            ))}
                        </div>
                    </div>
                )}

                {allPost && allPost?.length > 0 && (
                    <div className={classes.allPostWrapper}>
                        <h2>{t('all articles')}</h2>
                        <div className={classes.allPostContainer}>
                            {allPost.map(item => (
                                <BlogArticleCard data={item} />
                            ))}
                        </div>
                    </div>
                )}

                {featuredProducts && featuredProducts?.length > 0 && (
                    <div className={classes.featuredProductWrapper}>
                        <div className={classes.featuredProductContainer}>
                            <BlogDivider data={featuredProducts} />
                        </div>
                    </div>
                )}

            </Card>
        </Container>
    )
}

export default Blog;



