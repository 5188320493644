import backgroundImage from './../../assets/images/verify-email-background.jpg';

const Styles =  theme => ({
    root: {
        background: `url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        position: 'relative'
    },
    content: {
        padding: '30px',
        paddingLeft: '60px',
        maxWidth: '600px',
        zIndex: 1,
        '& h1': {
            color: '#FFF',
            marginTop: '30px',
            minHeight: '32px',
            fontWeight: 700,
            marginBottom: '25px',
            fontSize: '2.6rem! important'
        },
        '& P': {
            color: '#FFF',
            fontSize: '1.125rem',
            lineHeight: '1.5em',
            marginTop: '10px',
            marginBottom: '10px'
        },
        [theme.breakpoints.down('sm')]: {
            '& h1': {
                fontSize: '1.6rem! important'
            },
            '& p': {
                fontSize: '1rem',
                lineHeight: '1.1em',
            }
        },
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    button: {
        color: 'white',
        backgroundColor: '#e91e63',
        margin: '10px 5px 0',
        paddingTop: '11px',
        paddingBottom: '11px',
        '&:hover': {
            boxShadow: '0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)',
            backgroundColor: '#e91e63',
        },
        '& span.MuiButton-label': {
            textTransform: 'uppercase !important',
            fontSize: '13px',
        }
    }
})

export default Styles;