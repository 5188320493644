import Images from '../assets/images'

const Styles = theme => ({
    publicRoot: {
        height: '100%',
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        maxWidth: theme.breakpoints.values.lg,
        marginTop: 63,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px !important',
        [theme.breakpoints.down('sm')]: {
            marginTop: 57,
        },
    },
})

export default Styles;