const Styles = theme => ({
    card: {
        margin: '-60px 0px 0',
        borderRadius: '6px', 
        boxShadow: 'none',
        zIndex: 10
        
    },
    cardContent: {
        padding: 0,
        paddingBottom: '0 !important'
    },
    gridContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'scroll',
        margin: '0',
        padding: '0',
        '&::-webkit-scrollbar': {
            width: '0'
        }
    },
    carouselRoot: {
        '& .rec-slider': {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
        }
    },
    buttonArrowBox: {
        '& svg': {
            display: 'none'
        }
    },
    cardPostContainner: {
        backgroundColor: '#adbbbd42'
    }
})

export default Styles;

